import { useState } from "react";

import { equals } from "@qtxr/utils";

import { request } from "../../utils";

import { CANDIDATE_TYPES, PRESIDENTIAL } from "../../data/constants";

import useGraphConfigCollection, {
	GraphConfigCollection
} from "../../hooks/use-graph-config-collection";
import useQuerySettings from "../../hooks/use-query-settings";

import Base from "./base";

import { ControlUnion, DateRangeRuntime } from "../../types/control-bar";
import { QuerySettings } from "../../types/reports";
import {
	ValidationPayload,
	WaterfallEntries
} from "../../components/waterfall";
import SpendingStandalone from "./spending-standalone";
import { downloadPdf } from "../../utils/download-pdf";
interface RaceEntry {
	race_id: number;
	race_name: string;
	year: number;
	campaign_count: number;
	race_jurisdiction: string;
}

interface GraphsProps {
	querySettings: QuerySettings;
	collection: GraphConfigCollection;
	theme: any;
}

const INITIAL_STATE = {
	raceId: -1,
	activeRaceId: -1
};

interface PdfProps {
	dateRange: number[];
	raceId: string | number;
	selections: any;
}

function validateTeamOrCampaignSelected({ selection }: ValidationPayload) {
	const teamsSelection = selection.find(s => s.name === "teams");
	const campaignsSelection = selection.find(s => s.name === "campaigns");
	return !!teamsSelection?.selection.length !==
		!!campaignsSelection?.selection.length
		? null
		: "Select at least one campaign OR at least one team";
}

const graphs = [
	{ data: "mediaSpend", key: "mediaSpend", title: "Spend By Campaign" },
	{ data: "partySpend", key: "partySpend", title: "Media Type Spend" },
	{ data: "sovSpend", key: "sovSpend", title: "Share of Voice" },
	{
		data: "cumulativeSpend",
		key: "cumulativeSpend",
		title: "Cumulative Investment"
	},
	{ data: "grpMarket", key: "grpMarket", title: "Top GRP by Market" },
	{ data: "usdMarket", key: "usdMarket", title: "Spend by Market" },
	{ data: "topSpenders", key: "topSpenders", title: "Top Spenders" }
];

const statesWaterfall = PRESIDENTIAL
	? ([
			{
				name: "states",
				title: "States",
				mode: "multi",
				shouldFetch: ({ state, searching }) => {
					return (
						state.raceId >= 0 &&
						(searching ||
							state.raceId !==
								state.activeRaceId)
					);
				},
				fetch: async ({ state }) => {
					const res = await request({
						url: `/races/${state.raceId}/states`
					});

					if (!res.success)
						return "Failed to load states";

					return (res.data as string[]).map(
						state => ({
							title: state,
							key: state,
							data: state
						})
					);
				},
				placeholder:
					"Select a race before selecting states"
			}
	  ] as WaterfallEntries)
	: ([] as WaterfallEntries);

const WATERFALL = [
	{
		name: "race",
		title: "Race",
		mode: "radio",
		fetch: async ({ query }) => {
			const res = await request({
				url: "/races/search",
				query: {
					query
				}
			});

			if (!res.success) return "Failed to find races";

			return (res.data as RaceEntry[]).map(entry => ({
				title: entry.race_name,
				details: [
					`${entry.campaign_count} ${
						entry.campaign_count === 1
							? "campaign"
							: "campaigns"
					}`,
					entry.year
				],
				key: entry.race_id,
				data: entry
			}));
		},
		onSelectionChange: (sel, { setState }) => {
			setState("raceId", sel[0].data.race_id);
			if (sel[0].data.race_jurisdiction) {
				setState(
					"state",
					sel[0].data.race_jurisdiction
				);
			}
		},
		validate: ({ entry }) =>
			entry.selection.length ? null : "Select a race",
		compact: true
	},
	...statesWaterfall,
	{
		name: "campaigns",
		title: "Campaigns",
		mode: "multi",
		shouldFetch: ({ state, searching }) => {
			return (
				state.raceId !== -1 &&
				(searching ||
					state.raceId !== state.activeRaceId)
			);
		},
		shouldTrash: ({ state }) => {
			return (
				state.raceId !== -1 &&
				state.raceId !== state.activeRaceId
			);
		},
		fetch: async ({ query, state, setState }) => {
			setState("activeRaceId", state.raceId);

			const res = await request({
				url: "/campaigns/search",
				query: {
					query,
					race_id: state.raceId
				}
			});

			if (!res.success) return "Failed to find campaigns";

			return (res.data as any[]).map(entry => ({
				title: entry.candidate.full_name,
				details: [
					entry.party,
					CANDIDATE_TYPES.find(
						c =>
							c.value ===
							entry.candidate
								.candidate_type
					)?.label || ""
				],
				key: entry.id,
				data: entry
			}));
		},
		placeholder: "Select a race before selecting campaigns",
		validate: validateTeamOrCampaignSelected,
		compact: true
	},
	{
		name: "teams",
		title: "Teams",
		mode: "multi",
		shouldFetch: ({ state, searching }) => {
			return (
				state.raceId !== -1 &&
				(searching ||
					state.raceId !== state.activeRaceId)
			);
		},
		shouldTrash: ({ state }) => {
			return (
				state.raceId !== -1 &&
				state.raceId !== state.activeRaceId
			);
		},
		fetch: async ({ state }) => {
			const res = await request({
				url: "/teams/list",
				query: {
					race_id: state.raceId
				}
			});

			if (!res.success) return "Failed to find Teams";

			return (res.data as any[]).map(entry => ({
				title: entry.full_name,
				details: [entry.keywords],
				key: entry.id,
				data: entry
			}));
		},
		placeholder: "Select a race before selecting teams",
		compact: true,
		validate: validateTeamOrCampaignSelected
	},
	{
		name: "media",
		title: "Media",
		mode: "multi",
		fetch: async ({ query }) => {
			const res = await request({
				url: "/stations/unique",
				query: {
					query
				}
			});

			if (!res.success) return "Failed to find media";

			return (res.data as string[]).map(entry => ({
				title: entry,
				details: [],
				key: entry,
				data: entry
			}));
		},
		validate: ({ entry }) =>
			entry.selection.length
				? null
				: "Select at least one medium",
		compact: true
	},
	{
		name: "markets",
		title: "Markets",
		mode: "multi",
		shouldFetch: ({ state }) => {
			return state.raceId > 0;
		},
		shouldTrash: ({ state }) => {
			return (
				state.raceId !== -1 &&
				state.raceId !== state.activeRaceId
			);
		},
		fetch: async ({ query, state }) => {
			const res = await request({
				url: "/races/markets",
				query: {
					query,
					race_id: state.raceId
				}
			});

			if (!res.success) return "Failed to find markets";

			return (res.data as any[]).map(entry => ({
				title: entry.market_name,
				details: [state.state],
				key: entry.market_id,
				data: entry
			}));
		},
		placeholder: "Select a race before selecting markets",
		validate: ({ state, selection }) => {
			const selectedStates = selection.find(
				s => s.name === "states"
			)?.selection;
			return !!(state.state || selectedStates)
				? null
				: "Select a race before selecting markets";
		},
		compact: true
	},
	{
		name: "omittedGraphs",
		title: "Omitted Graphs",
		mode: "multi",
		shouldFetch: true,
		shouldTrash: false,
		fetch: async () => {
			return graphs;
		},
		validate: () => true,
		compact: true
	}
] as WaterfallEntries;

const Spending = () => {
	const { querySettings, setSelectionPayload, setDateRange, refresh } =
		useQuerySettings();

	const [pendingDateRange, setPendingDateRange] = useState(
		querySettings.dateRange
	);
	const [raceName, setRaceName] = useState<string>();

	const collection = useGraphConfigCollection();

	const rangeEquals = equals(querySettings.dateRange, pendingDateRange);

	const selections = querySettings?.selectionPayload?.selections ?? {};

	const raceId = selections.race?.[0]?.key;

	const validPayload = !!querySettings?.selectionPayload?.valid;

	const controls = [
		{
			mode: "date-range",
			value: pendingDateRange,
			onChange: (rt: DateRangeRuntime) => {
				if (!collection.initialized)
					setDateRange(rt.range);

				setPendingDateRange(rt.range);
			}
		},
		{
			label: "Refresh",
			style: "subtle",
			disabled: rangeEquals,
			onClick: () => {
				if (pendingDateRange)
					setDateRange(pendingDateRange);

				refresh();
			}
		},
		{
			label: "Export",
			disabled:
				!validPayload ||
				!collection.initialized ||
				!collection.settled ||
				!rangeEquals,
			onClick: () => {
				const filename = raceName + " Spending";
				downloadPdf(filename);
			}
		}
	] as ControlUnion[];

	const campaignIds = selections.campaigns?.map(c => c.key);
	const marketIds = selections.markets?.map(m => m.key);
	const media = selections.media?.map(m => m.data);
	const teamIds = selections.teams?.map(t => t.key);
	const states = selections.states?.map(s => s.data);
	const omittedGraphs = selections.omittedGraphs?.map(s => s.data);

	return (
		<Base
			flush
			limited
			waterfall={WATERFALL}
			initialState={INITIAL_STATE}
			onSelectionChange={setSelectionPayload}
			controls={controls}
		>
			<SpendingStandalone
				campaigns={campaignIds}
				collection={collection}
				hash={querySettings.hash}
				markets={marketIds}
				media={media}
				race_id={raceId}
				teams={teamIds}
				states={states}
				start_date={
					querySettings.normalizedDateRange[0]
				}
				end_date={querySettings.normalizedDateRange[1]}
				onSetRaceName={setRaceName}
				omittedGraphs={omittedGraphs}
			/>
		</Base>
	);
};

export default Spending;
