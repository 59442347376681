import styled from "styled-components";

import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import Base from "../base";

import { ColumnCell } from "../../types/data-table";
import { request } from "../../utils";
import { useAppSelector } from "../../state/hooks";
import SplitBox, { SplitProps } from "../../components/split-box";
import { Form, InputBox, InputRow, Multi } from "../../components/inputs";
import { PRESIDENTIAL } from "../../data/constants";

const racesLabel = PRESIDENTIAL ? "Presidential Races" : "Local Races";

const COLUMNS: ColumnCell[] = [
	{
		name: "username",
		title: "Username",
		accessor: "username",
		fit: "shrink",
		editable: true
	},
	{
		name: "password",
		title: "Password",
		accessor: "password",
		fit: "shrink",
		editable: true,
		type: "password"
	},
	{
		name: "races",
		title: racesLabel,
		accessor: "race_ids",
		type: "hidden",
		config: {
			labelAccessor: "race_ids",
			valueAccessor: "id"
		}
	}
];

const ContentWrapper = styled.div`
	max-width: 600px;
`;

const DropdownBox = styled(InputBox)`
	min-width: 150px;
	width: 100%;
`;

const Content = (props: SplitProps) => {
	const races = useAppSelector(state => state.data.races);

	const raceOptions = races.map(r => ({
		label: r.race_name,
		value: r.id
	}));

	return (
		<ContentWrapper>
			<Form
				values={props.focusedRow}
				onChange={e => props.edit(e)}
			>
				<InputRow>
					<DropdownBox
						name="race_ids"
						title={racesLabel}
						input={Multi}
						options={raceOptions}
					/>
				</InputRow>
			</Form>
		</ContentWrapper>
	);
};

const Users = () => {
	const actions = useTableActions({
		addLabel: "Add User",
		add: () => ({
			username: "",
			password: "",
			race_ids: []
		}),
		createEach: row => {
			return request({
				method: "POST",
				url: `admin/users`,
				body: row
			});
		},
		modifyEach: row => {
			return request({
				method: "PUT",
				url: `admin/users/${row.id}`,
				body: row
			});
		},
		deleteEach: row => {
			return request({
				method: "DELETE",
				url: `admin/users/${row.id}`
			});
		}
	});

	const props = useRemoteActionProps({
		request: "/admin/users",
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true,
		process: user =>
			user.race_ids ? user : { ...user, race_ids: [] }
	});

	return (
		<Base limited>
			<SplitBox
				{...props}
				title="User"
				notice="No user focused"
				content={Content}
			/>
		</Base>
	);
};

export default Users;
