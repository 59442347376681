import { get } from "@qtxr/utils";

import {
	traverse,
	collectLayout
} from "../../common";
import PlotSvg from "../../plot-svg";

import {
	Group,
	Point
} from "../../apply-categorization";

const PieConfig = {
	name: "pie",
	collector: () => collectLayout((p: any) => {
		p.data.totals = p.data.nodes.map((node: Point | Group, idx: number) => {
			return traverse.points.reduce(node, (acc, point) => {
				return acc + get(p.data.points[idx], point.accessor).value;
			}, 0);
		});

		p.data.total = p.data.totals.reduce((acc: number, tot: number) => acc + tot, 0);

		return p.data;
	}),
	plotter: () => (p: any) => {
		const plot = new PlotSvg(p);

		const ds = p.dataset,
			w = ds.cWidth,
			h = ds.cHeight,
			total = Math.max(ds.data.total, 1),
			r = Math.min(w, h) / 2,
			innerR = r * 0.1,
			cx = w / 2,
			cy = h / 2;
		let totalAngle = 0;

		plot.forEach(() => {
			const largestPlotGroupSize = plot.largestPlotGroupSize();
			plot.forEachPoint(pt => {
				const fraction = pt.value / total,
					angle = Math.min(fraction, 0.99999) * Math.PI * 2,
					startCX = cx + Math.sin(totalAngle) * r,
					startCY = cy - Math.cos(totalAngle) * r,
					endCX = cx + Math.sin(totalAngle) * innerR,
					endCY = cy - Math.cos(totalAngle) * innerR,
					boundaryType = plot.getBoundaryType("nested");
				plot.path()
					.M(startCX, startCY)
					.A(r, r, 0, angle > Math.PI, true, cx + Math.sin(totalAngle + angle) * r, cy - Math.cos(totalAngle + angle) * r)
					.L(cx + Math.sin(totalAngle + angle) * innerR, cy - Math.cos(totalAngle + angle) * innerR)
					.A(innerR, innerR, 0, angle > Math.PI, false, endCX, endCY)
					.fill(pt.color);

				if (fraction > 0.05) {
					const centerOfAngle = totalAngle + angle/2
					const textX = cx + (r / 2) * Math.sin(centerOfAngle)
					const textY = cy - (r / 2) * Math.cos(centerOfAngle)
					plot.text(`${Math.round(fraction*100)}%`).x(textX).y(textY).size(10).weight(600).anchor("middle").baseline("middle").fill(largestPlotGroupSize > 3 ? "#000000" : "#FFFFFF").inert();
				}

				if (fraction !== 1) {
					plot.line()
						.x1(startCX)
						.y1(startCY)
						.x2(endCX)
						.y2(endCY)
						.stroke(largestPlotGroupSize > 3 ? "#000000" : pt.style.fullTheme.componentBackground)
						.strokeWidth(boundaryType?.includes("start") || boundaryType?.includes("junction") ? 1.5 : 0.5)
						.stack(1)
						.inert();
				}

				totalAngle += angle;
			});
		});

		plot.render();
	},
	dataset: {
		id: "pie",
		mode: "own",
		type: "pie",
		canvasType: "svg",
		renderInfoBox: false,
		autoHeight: true,
		data: {}
	}
};

export default PieConfig;
