import { useState } from "react";

import {
	then,
	isObject,
	isThenable
} from "@qtxr/utils";

import useCachedConfig from "./use-cached-config";

import store from "../state/store";
import { pushToast } from "../state/features/ui";

import {
	Actions,
	ActionBase,
	ActionInput,
	ActionRuntime,
	ActionHandler,
	RowActionHandler,
	RowsActionHandler,
	ActionResponse,
	AddAction,
	RowSaveAction,
	RowDeleteAction,
	RowsSaveAction,
	RowsSyncCppAction,
	RowsDeleteAction, RowsUpdateDispatcher, RowsActionInput
} from "../components/action-table";
import { Row, Runtime } from "../types/data-table";
import { Response } from "../types/utils";
import { ButtonRuntime } from "../types/control-bar";

interface ActionConfig {
	// Add
	add?: AddAction;
	// Save all
	save?: RowsSaveAction;
	saveEach?: RowSaveAction;
	// Create
	create?: RowsSaveAction;
	createEach?: RowSaveAction;
	// Modify
	modify?: RowsSaveAction;
	modifyEach?: RowSaveAction;
	// Delete
	delete?: RowsDeleteAction;
	deleteEach?: RowDeleteAction;
	// Labels
	addLabel?: string;
	saveLabel?: string;
	createLabel?: string;
	modifyLabel?: string;
	deleteLabel?: string;
	// Runtime
	bail?: boolean;
	parallel?: boolean;
	update?: RowsUpdateDispatcher;
	export?: RowsActionInput<ActionResponse>;
	exportLabel?: string;
	exportAll?: RowsActionInput<ActionResponse>;
	exportAllLabel?: string;
	syncCpp?: RowsSyncCppAction;
	syncCppLabel?: string;
}

interface AugmentedActionConfig extends ActionConfig {
	bail: boolean;
	parallel: boolean;
	busy: BusyMap;
}

type ActionType = "add" | "save" | "create" | "modify" | "delete" | "export" | "exportAll" | "syncCpp";
type ApplicationMode = "all" | "each";
type ApplicatorHandler<R> = ActionHandler<R | Promise<R>>;
type RowApplicatorHandler<R> = RowActionHandler<R | Promise<R>>;
type MultiApplicatorHandler<R> = RowActionHandler<R | Promise<R>> | RowsActionHandler<R | Promise<R>>;
type ResolvedSaveAction = Omit<ActionBase<ActionResponse>, "mode">;
type ActionResult = Promise<boolean> | boolean;
type BusyMap = Record<ActionType, boolean>;

type Applicator<R> = (
	handler: ApplicatorHandler<R>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig,
	mode: ApplicationMode
) => ActionResult;
type RowApplicator<R> = (
	handler: MultiApplicatorHandler<R>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig,
	mode: ApplicationMode
) => ActionResult;

const UPDATE_ROWS_SYM = Symbol("update rows")

const useTableActions = (config: ActionConfig): Actions => {
	const actions = {} as Actions;

	const [busy, setBusy] = useState({} as BusyMap);
	const conf = resolveActionConfig(config, busy);

	const add = resolveAction(conf, "add", handleAdd, setBusy),
		save = resolveAction(conf, "save", handleSave, setBusy),
		create = resolveAction(conf, "create", handleCreate, setBusy),
		modify = resolveAction(conf, "modify", handleModify, setBusy),
		del = resolveAction(conf, "delete", handleDelete, setBusy),
		syncCpp = resolveAction(conf, "syncCpp", handleSyncCpp, setBusy),
		exportToEmail = resolveAction(conf, "export", handleExport, setBusy),
		exportAllToEmail = resolveAction(conf, "exportAll", handleExportAll, setBusy);

	if (add)
		actions.add = add;

	if (save || create || modify) {
		actions.save = composeSave(
			conf,
			save,
			create,
			modify
		);
	}

	if (del)
		actions.delete = del;

	if (exportToEmail)
		actions.export = exportToEmail;

	if (exportAllToEmail)
		actions.exportAll = exportAllToEmail;

	if (syncCpp)
		actions.syncCpp = syncCpp;

	if (typeof config.update == "function")
		actions[UPDATE_ROWS_SYM] = config.update;

	return useCachedConfig(actions);
};

const resolveActionConfig = (
	config: ActionConfig,
	busy: BusyMap
): AugmentedActionConfig => {
	return {
		...config,
		bail: typeof config.bail === "boolean" ?
			config.bail :
			true,
		parallel: Boolean(config.parallel),
		busy
	};
};

// Addition action
// Runs the handler and prepends the returned data as a row to the table
const handleAdd = (
	handler: ApplicatorHandler<Row | void>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig
): ActionResult => {
	const update = mkUpdater(config, runtime);

	return then(handler(runtime), (row: Row | void) => {
		if (!row)
			return false;

		update(() => ([
			row,
			...runtime.rows
		]));

		return true;
	});
};

// Save action
// Runs the handler on additions and edits to the table, either elementwise
// or with the full set, and updates the table according to the success
// of the operation
const handleSave = (
	handler: MultiApplicatorHandler<ActionResponse>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig,
	mode: ApplicationMode
): ActionResult => {
	const additions = runtime.additionsList,
		edits = runtime.editsList,
		rows = [
			...additions,
			...edits
		];

	if (!rows.length)
		return true;

	if (mode === "all") {
		return then(handler(rows, runtime), (res: ActionResponse) => {
			if (!validateDispatch(res, "Failed to save"))
				return false;

			runtime.clearAdditions();
			runtime.clearEdits();

			for (const addition of additions)
				runtime.clearEdit(addition);

			return true;
		});
	}

	const run = mkRunner(
		runtime,
		config,
		rows,
		handler as RowActionHandler<ActionResponse>
	);

	return run((res: ActionResponse, row: Row) => {
		if (!validateDispatch(res, "Failed to save"))
			return false;

		runtime.clearAddition(row);
		runtime.clearEdit(row);
		return true;
	});
};

// Create action
// Runs the handler on additions to the table, either elementwise
// or with the full set, and updates the table according to the success
// of the operation
const handleCreate = (
	handler: MultiApplicatorHandler<ActionResponse>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig,
	mode: ApplicationMode
): ActionResult => {
	const rows = runtime.additionsList;

	if (!rows.length)
		return true;

	if (mode === "all") {
		return then(handler(rows, runtime), (res: ActionResponse) => {
			if (!validateDispatch(res, "Failed to create"))
				return false;

			runtime.clearAdditions();
			for (const row of rows)
				runtime.clearEdit(row);

			return true;
		});
	}

	const run = mkRunner(
		runtime,
		config,
		rows,
		handler as RowActionHandler<ActionResponse>
	);

	return run((res: ActionResponse, row: Row) => {
		if (!validateDispatch(res, "Failed to create"))
			return false;

		runtime.clearAddition(row);
		runtime.clearEdit(row);
		return true;
	});
};

// Modify action
// Runs the handler on edits to the table, either elementwise
// or with the full set, and updates the table according to the success
// of the operation
const handleModify = (
	handler: MultiApplicatorHandler<ActionResponse>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig,
	mode: ApplicationMode
): ActionResult => {
	const rows = runtime.editsList;

	if (!rows.length)
		return true;

	if (mode === "all") {
		return then(handler(rows, runtime), (res: ActionResponse) => {
			if (!validateDispatch(res, "Failed to modify"))
				return false;

			runtime.clearEdits();
			return true;
		});
	}

	const run = mkRunner(
		runtime,
		config,
		rows,
		handler as RowActionHandler<ActionResponse>
	);

	return run((res: ActionResponse, row: Row) => {
		if (!validateDispatch(res, "Failed to modify"))
			return false;

		runtime.clearEdit(row);
		return true;
	});
};

// Delete action
// Runs the handler on deletions from the table, either elementwise
// or with the full set, and updates the table according to the success
// of the operation. Additions that haven't been synced to a server
// are removed automatically
const handleDelete = (
	handler: MultiApplicatorHandler<ActionResponse>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig,
	mode: ApplicationMode
): ActionResult => {
	const rows = runtime.selectionList.filter(row => !runtime.additions.has(row)),
		addedRows = runtime.selectionList.filter(row => runtime.additions.has(row)),
		update = mkUpdater(config, runtime);

	if (!rows.length && !addedRows.length)
		return true;

	if (mode === "all") {
		if (!rows.length) {
			update(() => runtime.deleteSelected());
			return true;
		}

		return then(handler(rows, runtime), (res: ActionResponse) => {
			if (!validateDispatch(res, "Failed to delete"))
				return false;

			update(() => runtime.deleteSelected());
			return true;
		});
	}

	const run = mkRunner(
		runtime,
		config,
		rows,
		handler as RowActionHandler<ActionResponse>
	);

	if (addedRows.length) {
		const rowSet = new Set(addedRows);

		update(rs => {
			return rs.filter(r => !rowSet.has(r))
		});
	}

	return run((res: ActionResponse, row: Row) => {
		if (!validateDispatch(res, "Failed to delete"))
			return false;

		update(rs => {
			return rs.filter(r => r !== row)
		});
		return true;
	});
};

// Sync CPP action
// Runs the handler on selections from the table, either elementwise
// or with the full set, and updates the table according to the success
// of the operation
const handleSyncCpp = (
	handler: MultiApplicatorHandler<ActionResponse>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig,
	mode: ApplicationMode
): ActionResult => {
	const rows = runtime.selectionList;

	if (!rows.length)
		return true;

	if (mode === "all") {
		const resp = then(handler(rows, runtime), (_res: ActionResponse) => {
			if (!validateDispatch(_res, "Failed to sync CPP"))
				return false;
			const res = _res as Response;
			if (!res.data) {
				return false;
			}
			const data = rows.reduce<any[]>((result, row, i) => {
				if (!res.data[i]) {
					return result;
				}
				if (row["candidate_cpp"] !== res.data[i].candidate_cpp) {
					return [...result, {column: "cpp", id: res.data[i].id, value: res.data[i].candidate_cpp}];
				}
				return result;
			}, []);
			window.postMessage(data, window.origin);
			return true;
		});
		runtime.triggerRender();
		return resp;
	}

	const run = mkRunner(
		runtime,
		config,
		rows,
		handler as RowActionHandler<ActionResponse>
	);

	const resp = run((_res: ActionResponse, row: Row) => {
		if (!validateDispatch(_res, "Failed to modify"))
			return false;

		const res = _res as Response;

		const data = {column: "candidate_cpp", rowIndex: rows.findIndex(r => r === row), value: res.data.candidate_cpp};
		window.postMessage(data, window.origin);
		return true;
	});
	return resp;
};

// Export action
// Runs the handler on exports from the table elementwise,
// and updates the table according to the success of the operation
const handleExport = (
	handler: MultiApplicatorHandler<ActionResponse>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig
): ActionResult => {
	const rows = runtime.selectionList,
		update = mkUpdater(config, runtime);

	if (!rows.length)
		return true;

	const run = mkRunner(
		runtime,
		config,
		rows,
		handler as RowActionHandler<ActionResponse>
	);

	return run((res: ActionResponse, row: Row) => {
		if (!validateDispatch(res, "Failed to export"))
			return false;

		update(rs => {
			return rs.filter(r => r !== row)
		});
		return true;
	});
};

// Export all action
// Runs the handler on exports from the table elementwise,
// and updates the table according to the success of the operation
const handleExportAll = (
	handler: MultiApplicatorHandler<ActionResponse>,
	runtime: ActionRuntime,
	config: AugmentedActionConfig
): ActionResult => {
	const rows = runtime.selectionList,
		update = mkUpdater(config, runtime);

	if (!rows.length)
		return true;

	const run = mkRunner(
		runtime,
		config,
		rows,
		handler as RowActionHandler<ActionResponse>
	);

	return run((res: ActionResponse, row: Row) => {
		if (!validateDispatch(res, "Failed to export"))
			return false;

		update(rs => {
			return rs.filter(r => r !== row)
		});
		return true;
	});
};

function resolveAction<R>(
	config: AugmentedActionConfig,
	type: ActionType,
	applicator: Applicator<R> | RowApplicator<R>,
	busyUpdater: (updater: (busy: BusyMap) => BusyMap) => void
): Omit<ActionBase<R>, "mode"> | null {
	let source = null as ActionInput<any> | null,
		mode = "all" as ApplicationMode;

	if (config.hasOwnProperty(`${type}Each`)) {
		source = (config as any)[`${type}Each`];
		mode = "each";
	} else if (config.hasOwnProperty(type))
		source = (config as any)[type];

	if (!source)
		return null;

	const action: Omit<ActionBase<any>, "mode"> = typeof source == "function" ?
		{ handle: source } :
		{ ...source };

	if (config.hasOwnProperty(`${type}Label`))
		action.label = config[`${type}Label`];

	const handler = action.handle;
	action.handle = (runtime: ActionRuntime): ActionResult => {
		const applied = applicator(handler as any, runtime, config, mode);
		if (!isThenable(applied))
			return applied;

		busyUpdater(busy => ({
			...busy,
			[type]: true
		}));

		return (applied as Promise<boolean>)
			.then(success => {
				busyUpdater(busy => ({
					...busy,
					[type]: false
				}));

			return success;
		});
	};

	const disabled = action.disabled;
	action.disabled = (runtime: ButtonRuntime & ActionRuntime) => {
		if (config.busy.hasOwnProperty(type) && config.busy[type])
			return true;

		if (typeof disabled == "function")
			return disabled(runtime);

		return Boolean(disabled);
	};

	return action;
}

const composeSave = (
	config: AugmentedActionConfig,
	save: ResolvedSaveAction | null,
	create: ResolvedSaveAction | null,
	modify: ResolvedSaveAction | null
): ResolvedSaveAction => {
	const actions = [
		create,
		modify,
		save
	].filter(a => a !== null) as ActionBase<any>[];

	if (actions.length === 1)
		return actions[0];

	const action = {
		...actions[0]
	} as ResolvedSaveAction;

	if (config.saveLabel)
		action.label = config.saveLabel;

	action.handle = (runtime: ActionRuntime) => {
		return runEntries(
			actions,
			action => action.handle(runtime),
			config.bail,
			config.parallel
		);
	};

	return action;
};

const mkRunner = (
	runtime: ActionRuntime,
	config: AugmentedActionConfig,
	rows: Row[],
	handler: RowApplicatorHandler<ActionResponse>
): (runner: (res: ActionResponse, row: Row) => ActionResult) => ActionResult => {
	return (runner: (res: ActionResponse, row: Row) => ActionResult) => {
		return applyRun(runtime, config, rows, handler, runner);
	};
};

const applyRun = (
	runtime: ActionRuntime,
	config: AugmentedActionConfig,
	rows: Row[],
	handler: RowApplicatorHandler<ActionResponse>,
	runner: (res: ActionResponse, row: Row) => ActionResult
): ActionResult => {
	return runEntries(
		rows,
		row => {
			return then(handler(row, runtime), (res: ActionResponse) => {
				return runner(res, row);
			});
		},
		config.bail,
		config.parallel
	);
};

function runEntries<T>(
	entries: T[],
	runner: (entry: T) => ActionResult,
	bail: boolean,
	parallel: boolean
): ActionResult {
	if (parallel) {
		const pending = entries.map(runner),
			isAsync = pending.some(isThenable);

		if (isAsync) {
			return Promise.all(pending)
				.then(results => results.every(Boolean));
		}

		return pending.every(Boolean);
	}

	const step = (currentStep: number): ActionResult => {
		if (currentStep === entries.length)
			return true;

		const entry = entries[currentStep];

		return then(runner(entry), (result: boolean) => {
			if (bail && !result)
				return false;

			return step(currentStep + 1);
		})
	};

	return step(0);
}

const mkUpdater = (
	config: AugmentedActionConfig,
	runtime: ActionRuntime
) => {
	if (typeof config.update == "function")
		return config.update;

	return runtime.updateRows;
};

const validateDispatch = (
	response: ActionResponse,
	defaultErrorMessage: string
) => {
	if (response === true)
		return true;

	if (response === false) {
		dispatchError(defaultErrorMessage);
		return false;
	}

	if (isObject(response) && (response as Response).isResponse) {
		if (!(response as Response).success) {
			dispatchError((response as Response).errorMessage || defaultErrorMessage);
			return false;
		}

		return true;
	}

	if (response !== undefined) {
		dispatchError(defaultErrorMessage);
		return false;
	}

	return true;
};

let toastId = 1e6;

const dispatchError = (message: string) => {
	store.dispatch(
		pushToast({
			id: toastId++,
			type: "error",
			message,
			timeout: 3000
		})
	);
};

export default useTableActions;
export {
	UPDATE_ROWS_SYM
};
