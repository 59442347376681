import { useRef, useMemo, useState, useEffect } from "react";
import { transparentize } from "polished";
import styled, { withTheme } from "styled-components";

import { then, equals, splitPath } from "@qtxr/utils";

import useStateCapsule from "../hooks/use-state-capsule";

import DataTable, {
	resolveColumns,
	wrapBackground,
	wrapEditEventMaker
} from "./data-table";
import { Checkbox } from "./inputs";
import Icon from "./icon";
import ControlBar from "./control-bar";
import Pagination from "./pagination";

import {
	Row,
	Runtime,
	RowPacket,
	EditEvent,
	ColumnCell,
	FilterEvent,
	ContentProps,
	DataTableProps,
	BackgroundSourceMap,
	ReferenceChangeEvent
} from "../types/data-table";
import {
	ControlUnion,
	ButtonControl,
	CheckboxControl,
	CheckboxRuntime
} from "../types/control-bar";
import { Response } from "../types/utils";
import { CheckMode } from "./inputs/checkbox";
import { BaseRuntime } from "../types/common";
import { UPDATE_ROWS_SYM } from "../hooks/use-table-actions";

export interface ActionTableProps extends DataTableProps {
	pageSize?: number;
	controls?: ControlUnion<ActionRuntime>[];
	actions?: Actions;
	onBulkEdit?: (evt: BulkEditEvent) => void;
	checkPlacement?: number;
	checkProps?: Partial<ColumnCell>;
	validate?: (wrapper: RowWrapper) => boolean;
	spreadEdit?: boolean;
	initialized?: boolean;
	sourceCacheKey?: any;
	className?: string;
	duplicateIndexes?: number[];
	updatedIndexes?: number[];
	listenForMessages?: boolean;
	headerInputs?: JSX.Element[];
}

interface HeaderProps {
	tableProps: ActionTableProps;
	runtime: Runtime;
	actionRuntime: ActionRuntime;
	page: number;
	setPage: (page: number) => void;
	actions: Actions;
	additions: Set<Row>;
	edits: Set<Row>;
	initialized: boolean;
	inputs?: JSX.Element[];
}

export interface Actions {
	add?: AddAction;
	save?: SaveAction;
	delete?: DeleteAction;
	export?: ActionInput<ActionResponse>;
	exportAll?: ActionInput<ActionResponse>;
	syncCpp?: SyncCppAction;
	[UPDATE_ROWS_SYM]?: RowsUpdateDispatcher;
}

export type ActionResponse = Response | boolean | void;

export type ActionHandler<T> = (runtime: ActionRuntime) => Promise<T> | T;
export type RowActionHandler<T> = (
	row: Row,
	runtime: ActionRuntime
) => Promise<T> | T;
export type RowsActionHandler<T> = (
	rows: Row[],
	runtime: ActionRuntime
) => Promise<T> | T;

export interface ActionBase<T> extends ButtonControl<ActionRuntime> {
	mode: "button";
	handle: ActionHandler<T>;
}

export interface RowActionBase<T> extends ButtonControl<ActionRuntime> {
	mode: "button";
	handle: RowActionHandler<T>;
}

export interface RowsActionBase<T> extends ButtonControl<ActionRuntime> {
	mode: "button";
	handle: RowsActionHandler<T>;
}

export type ActionInput<T> = Omit<ActionBase<T>, "mode"> | ActionHandler<T>;
export type RowActionInput<T> =
	| Omit<RowActionBase<T>, "mode">
	| RowActionHandler<T>;
export type RowsActionInput<T> =
	| Omit<RowsActionBase<T>, "mode">
	| RowsActionHandler<T>;

export type AddAction = ActionInput<boolean | object | void>;
export type SaveAction = ActionInput<ActionResponse>;
export type DeleteAction = ActionInput<ActionResponse>;
export type RowAddAction = RowActionInput<boolean | object | void>;
export type RowSaveAction = RowActionInput<ActionResponse>;
export type RowDeleteAction = RowActionInput<ActionResponse>;
export type RowsAddAction = RowsActionInput<boolean | object | void>;
export type RowsSaveAction = RowsActionInput<ActionResponse>;
export type RowsDeleteAction = RowsActionInput<ActionResponse>;
export type RowsSyncCppAction = RowsActionInput<ActionResponse>;
export type SyncCppAction = ActionInput<ActionResponse>;

interface ThemedDataTableProps extends ActionTableProps {
	theme: any;
}

export interface ActionRuntime extends BaseRuntime {
	// Row collections
	rows: Row[];
	selection: Set<Row>;
	selectionList: Row[];
	additions: Set<Row>;
	additionsList: Row[];
	edits: Set<Row>;
	editsList: Row[];
	deletions: Set<Row>;
	deletionsList: Row[];
	// Actions
	deleteSelected: () => Row[];
	// Utilities: clearing
	clearAddition: (row: Row) => boolean;
	clearAdditions: () => void;
	clearEdit: (row: Row) => boolean;
	clearEdits: () => void;
	clearDeletion: (row: Row) => boolean;
	clearDeletions: () => void;
	// Utilities: misc
	triggerRender: () => void;
	updateRows: RowsUpdateDispatcher;
	// Meta
	initialized: boolean;
}

interface ActionState {
	blockSpread: boolean;
}

interface WrapperProps {
	controlPadding: number;
}

interface ControlBarWrapperProps {
	ref: any;
	transform: string;
}

export interface RowWrapper {
	data: Row;
	valid: boolean;
	checked: boolean;
	edits: Edits;
	editCount: number;
}

interface Edits {
	[key: string]: any;
}

interface DiffResult {
	rows: Row[];
	changes: Change[];
}

export interface BulkEditEvent {
	type: BulkEditType;
	rows: Row[];
	changes: Change[];
	runtime: Runtime;
}

interface Change {
	from: Row;
	to: Row;
	fromWrapper: RowWrapper;
	toWrapper: RowWrapper;
}

type Collections = [
	Map<Row, RowWrapper>,
	Set<Row>,
	Set<Row>,
	Set<Row>,
	Set<Row>,
	Map<Row, RowWrapper>
];

export type RowsUpdateDispatcher = (updater: (rows: Row[]) => Row[]) => void;
export type BulkEditType = "check" | "uncheck";
type EntryMode = "addition" | "edit" | "deletion";

const Wrapper = styled.section<WrapperProps>`
	position: relative;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	border-radius: ${p => p.theme.borderRadius};
	padding-bottom: ${p => `${p.controlPadding}px`};
	max-height: 100%;
	overflow: hidden;
`;

const HeaderWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
`;

const HeaderLeft = styled.div`
	display: flex;
`;

const HeaderRight = styled.div`
	display: flex;
`;

const HeaderEditControlsWrapper = styled.div`
	display: flex;
`;

const HeaderEditLeftContent = styled.div``;

const HeaderEditControls = styled.div``;

const ContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
`;

const RevertButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 18px;
	height: 18px;
	padding: 0;
	border: none;
	color: ${p => transparentize(0.5, p.theme.darkBackground)};
	background: ${p => transparentize(0.9, p.theme.darkBackground)};
	border-radius: ${p => p.theme.borderRadius};
	cursor: pointer;
	margin: 0 -4px 0 4px;

	svg {
		width: 8px;
	}
`;

const ControlBarClipWrapper = styled.div`
	position: absolute;
	left: 5px;
	right: 5px;
	bottom: 0;
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
	pointer-events: none;
`;

const ControlBarWrapper = styled.div<ControlBarWrapperProps>`
	transform: ${p => p.transform};
	transition: transform 300ms;
	pointer-events: auto;
`;

const SelectionLabel = styled.div`
	font-weight: bold;
	padding: 5px 10px;
	font-size: 105%;
`;

const SelectionCount = styled.span`
	color: ${p => p.theme.lightAccentColor};
`;

const runDiffs = (
	rows: RowWrapper[],
	diffs: Map<RowWrapper, RowWrapper>,
	mutate: boolean = false
): DiffResult => {
	const newRows = [],
		changes = [] as Change[];

	for (const row of rows) {
		const newRow = diffs.get(row);

		if (newRow) {
			let toWrapper = newRow;

			if (mutate) {
				newRows.push(newRow.data);
				Object.assign(row, newRow);
			} else {
				toWrapper = {
					...newRow,
					data: {
						...newRow.data
					}
				};
				newRows.push(toWrapper.data);
			}

			changes.push({
				from: row.data,
				to: toWrapper.data,
				fromWrapper: row,
				toWrapper
			});
		} else newRows.push(row.data);
	}

	return {
		rows: newRows,
		changes
	};
};

const interceptRefs = (
	props: ActionTableProps,
	ref: Row | null,
	callback: (ref: Row, evt: BulkEditEvent | ReferenceChangeEvent) => void
): ActionTableProps => {
	if (!ref) return props;

	return {
		...props,
		onBulkEdit(evt: BulkEditEvent) {
			for (const change of evt.changes) {
				if (change.from === ref) {
					callback(change.to, evt);
					break;
				}
			}

			if (typeof props.onBulkEdit == "function")
				props.onBulkEdit(evt);
		},
		onReferenceChange(evt: ReferenceChangeEvent) {
			if (evt.from === ref) callback(evt.to, evt);

			if (typeof props.onReferenceChange == "function")
				props.onReferenceChange(evt);
		}
	};
};

const filterRows = (
	rows: RowWrapper[],
	set: Set<Row>,
	invert: boolean = false
): Row[] => {
	const filtered = [] as Row[];

	for (const row of rows) {
		if (set.has(row.data) !== invert) filtered.push(row.data);
	}

	return filtered;
};

const filterSet = (set: Set<Row>, subset: Set<Row>) => {
	subset.forEach(row => {
		if (!set.has(row)) subset.delete(row);
	});
};

function wrapAction<T>(
	source: ActionInput<T>,
	defaultData: Partial<ButtonControl<ActionRuntime>>,
	callback?: (response: T) => void
): ActionBase<T> {
	let src = source as ActionBase<T>;

	if (typeof source == "function") {
		src = {
			handle: source
		} as ActionBase<T>;
	} else src = { ...src };

	if (typeof src.onClick == "function")
		console.warn("Override: onClick handler already supplied");

	src.mode = "button";
	src.onClick = (runtime: ActionRuntime) => {
		return then(src.handle(runtime), (response: T) => {
			if (typeof callback == "function")
				then(response, callback);

			return response;
		});
	};

	return {
		...defaultData,
		...src
	};
}

const mkEvenRowBackgroundGetter = (
	theme: any
): ((props: ContentProps) => string | null) => {
	return (props: ContentProps) => {
		if (props.row.row.checked)
			return theme.evenSelectedRowBackground;
		if (!props.row.row.valid) return theme.evenInvalidRowBackground;

		const ar = getActionRuntime(props);

		if (
			props.row.row.editCount > 0 ||
			(ar && ar.additions.has(props.row.row.data))
		)
			return theme.evenPendingRowBackground;

		return null;
	};
};

const mkOddRowBackgroundGetter = (
	theme: any
): ((props: ContentProps) => string | null) => {
	return (props: ContentProps) => {
		if (props.row.row.checked)
			return theme.oddSelectedRowBackground;
		if (!props.row.row.valid) return theme.oddInvalidRowBackground;

		const ar = getActionRuntime(props);

		if (
			props.row.row.editCount > 0 ||
			(ar && ar.additions.has(props.row.row.data))
		)
			return theme.oddPendingRowBackground;

		return null;
	};
};

const validate = (
	validator: ((wrapper: RowWrapper) => boolean) | undefined,
	wrapper: RowWrapper,
	clone: boolean = false
): RowWrapper => {
	const valid =
		typeof validator == "function" ? validator(wrapper) : true;

	if (valid === wrapper.valid) return wrapper;

	if (clone) {
		return {
			...wrapper,
			valid
		};
	}

	wrapper.valid = valid;
	return wrapper;
};

const getActionRuntime = (props: ContentProps): ActionRuntime | null => {
	return props.processOptions.get().props.store.actionRuntime || null;
};

const Header = (props: HeaderProps) => {
	const tp = props.tableProps,
		rt = props.runtime,
		actions = props.actions;

	const pagination = tp.pageSize ? (
		<Pagination
			page={props.page}
			pageSize={tp.pageSize || 0}
			length={rt.filteredRows.length}
			onSelect={props.setPage}
		/>
	) : null;

	let headerContent =
		typeof tp.header == "function" ? <tp.header {...rt} /> : null;

	if (actions.add || actions.save || actions.syncCpp) {
		const acts = [] as ActionBase<any>[];

		if (actions.syncCpp && props.actionRuntime.selection.size) {
			acts.push(
				wrapAction(
					actions.syncCpp,
					{
						label: "Sync CPP",
						style: "light-accent",
						disabled: () =>
							!props.initialized
					},
					undefined
				)
			);
		}

		if (
			actions.save &&
			(props.additions.size || props.edits.size)
		) {
			acts.push(
				wrapAction(actions.save, {
					label: "Save",
					style: "accent",
					disabled: () => !props.initialized
				})
			);
		}

		if (actions.add) {
			acts.push(
				wrapAction(actions.add, {
					label: "Add",
					style: "accent",
					icon: "plus",
					disabled: () => !props.initialized
				})
			);
		}

		if (headerContent || acts.length) {
			headerContent = (
				<HeaderEditControlsWrapper>
					<HeaderEditLeftContent>
						{headerContent}
					</HeaderEditLeftContent>
					<HeaderEditControls>
						<ControlBar
							bare
							controls={acts}
							inputs={tp.headerInputs}
							runtime={
								props.actionRuntime
							}
						/>
					</HeaderEditControls>
				</HeaderEditControlsWrapper>
			);
		}
	}

	return (
		<HeaderWrapper>
			<HeaderLeft>{pagination}</HeaderLeft>
			<HeaderRight>{headerContent}</HeaderRight>
		</HeaderWrapper>
	);
};

const CoreActionTable = (props: ThemedDataTableProps) => {
	const controlBarRef = useRef();

	const [[rowMap, selection, additions, edits, deletions, replacements]] =
		useState(
			() =>
				[
					new Map(),
					new Set(),
					new Set(),
					new Set(),
					new Set(),
					new Map()
				] as Collections
		);

	const [page, setPage] = useState(0);
	const [selectionSize, setSelectionSize] = useState(0);
	const [controlBarHeight, setControlBarHeight] = useState(0);
	const [spread, setSpread] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const setTriggeredRenders = useState(0)[1];
	const [replacementsCount, setReplacementsCount] = useState(0);
	const [sourceCacheKey, setSourceCacheKey] = useState(
		props.sourceCacheKey
	);

	const currentProps = useStateCapsule(props, true);
	const actionState = useStateCapsule({
		blockSpread: false
	} as ActionState);

	const actions = props.actions || {};

	if (props.sourceCacheKey !== sourceCacheKey) {
		additions.clear();
		edits.clear();
		deletions.clear();
		setSourceCacheKey(props.sourceCacheKey);
	}

	const triggerRender = () => {
		requestAnimationFrame(() => setTriggeredRenders(tr => tr + 1));
	};

	const addEntry = (mode: EntryMode, row: Row) => {
		switch (mode) {
			case "addition":
				additions.add(row);
				break;

			case "edit":
				if (!additions.has(row)) edits.add(row);
				break;

			case "deletion":
				if (additions.has(row)) additions.delete(row);
				else {
					edits.delete(row);
					deletions.add(row);
				}
				break;
		}
	};

	const deleteEntry = (mode: EntryMode, row: Row) => {
		switch (mode) {
			case "addition":
				additions.delete(row);
				break;

			case "edit":
				edits.delete(row);
				break;

			case "deletion":
				deletions.delete(row);
				break;
		}
	};

	const updateControlBarHeight = () => {
		setControlBarHeight(
			(
				controlBarRef.current! as Element
			).getBoundingClientRect().height
		);
	};

	const updateRefs = (from: Row, to: Row, wrapper: RowWrapper) => {
		if (from !== to) {
			rowMap.delete(from);

			updateSetRef(selection, from, to);
			updateSetRef(additions, from, to);
			updateSetRef(edits, from, to);
			updateSetRef(deletions, from, to);
		}

		rowMap.set(to, validate(props.validate, wrapper));
	};

	const updateSetRef = (set: Set<Row>, from: Row, to: Row) => {
		if (set.has(from)) {
			set.delete(from);
			set.add(to);
		}
	};

	const updateSelected = () => {
		setSelectionSize(selection.size);
	};

	const columns = useMemo(() => {
		const cols = [] as ColumnCell[];
		let checkP = props.columns.length;

		if (typeof props.checkPlacement == "number") {
			if (props.checkPlacement >= 0)
				checkP = props.checkPlacement;
			else
				checkP = Math.max(
					props.columns.length +
						props.checkPlacement,
					0
				);
		}

		const backgrounds: BackgroundSourceMap = {
			even: mkEvenRowBackgroundGetter(props.theme),
			odd: mkOddRowBackgroundGetter(props.theme)
		};

		const resolvedColumns = resolveColumns(props.columns);

		for (const column of resolvedColumns) {
			const outCol = {
				...column,
				accessor: ["data"].concat(
					splitPath(column.accessor)
				),
				backgrounds
			};

			outCol.content = (p: ContentProps) => {
				const presentation =
					typeof column.content == "function" ? (
						<column.content {...p} />
					) : (
						<span>
							{p.presentationValue}
						</span>
					);
				let revert = null;

				if (
					p.row.row.edits.hasOwnProperty(
						column.name
					)
				) {
					const doRevert = (evt: any) => {
						actionState.set({
							...actionState.get(),
							blockSpread: true
						});

						p.set(
							p.row.row.edits[
								column.name
							]
						);

						actionState.set({
							...actionState.get(),
							blockSpread: false
						});

						evt.stopPropagation();
					};

					revert = (
						<RevertButton
							onClick={doRevert}
						>
							<Icon name="cross" />
						</RevertButton>
					);
				}

				return (
					<ContentWrapper>
						{presentation}
						{revert}
					</ContentWrapper>
				);
			};

			outCol.backgrounds = {
				even: wrapBackground(
					column.backgrounds,
					"even",
					backgrounds
				),
				odd: wrapBackground(
					column.backgrounds,
					"odd",
					backgrounds
				)
			};

			cols.push(outCol);
		}

		const title = (rt: Runtime) => {
			const po = rt.processOptions.get(),
				fRows = po.runtime!.filteredRows;
			let mode: CheckMode;

			const handleChange = () => {
				const diffs = new Map(),
					type: BulkEditType =
						rt.store.selectionSize > 0
							? "uncheck"
							: "check",
					p = currentProps.get(),
					mutate =
						typeof p.onBulkEdit !=
						"function";

				for (const { row } of fRows) {
					if (
						row.checked ^
						Number(type === "check")
					) {
						const newRow = {
							...row,
							checked:
								type === "check"
						};

						diffs.set(row, newRow);
					}
				}

				const result = runDiffs(
					po.props.rows as RowWrapper[],
					diffs,
					mutate
				);

				if (result.changes.length && !mutate) {
					const selectAll = type === "check";

					selection.clear();

					for (const change of result.changes) {
						updateRefs(
							change.from,
							change.to,
							change.toWrapper
						);

						if (selectAll)
							selection.add(
								change.to
							);
					}

					p.onBulkEdit!({
						type,
						runtime: rt,
						...result
					});

					if (selectAll) updateControlBarHeight();
				}

				updateSelected();
			};

			if (!rt.store.selectionSize) mode = "unchecked";
			else if (rt.store.selectionSize < fRows.length)
				mode = "clear";
			else mode = "checked";

			return <Checkbox mode={mode} onChange={handleChange} />;
		};

		const content = (p: ContentProps) => {
			const handleChange = () => {
				updateControlBarHeight();
				p.set(!p.value);
			};

			return (
				<Checkbox
					checked={p.value}
					onChange={handleChange}
				/>
			);
		};

		const checkboxCol = {
			...(props.checkProps || {}),
			name: "checkbox",
			title,
			accessor: "checked",
			content,
			fit: "shrink",
			sort: null,
			filter: null,
			editable: true,
			hasOwnEditHandler: true,
			backgrounds
		} as ColumnCell;

		if (props.checkProps?.backgrounds) {
			checkboxCol.backgrounds = {
				even: wrapBackground(
					props.checkProps.backgrounds,
					"even",
					backgrounds
				),
				odd: wrapBackground(
					props.checkProps.backgrounds,
					"odd",
					backgrounds
				)
			};
		}

		cols.splice(checkP, 0, checkboxCol);
		return cols;
	}, [props.columns]);

	const rows = useMemo(() => {
		const out = [],
			trackSelection = Boolean(selection.size),
			trackAdditions = Boolean(additions.size),
			trackEdits = Boolean(edits.size),
			currentExistingSize = rowMap.size;
		let sSize = 0,
			aSize = 0,
			eSize = 0,
			existingSize = 0;

		for (const row of props.rows) {
			const existingRow = rowMap.get(row);

			if (existingRow) {
				if (replacements.has(existingRow.data)) {
					const replacement = validate(
						props.validate,
						replacements.get(
							existingRow.data
						)!
					);

					out.push(replacement);
					rowMap.set(
						existingRow.data,
						replacement
					);
				} else out.push(existingRow);

				if (
					trackSelection &&
					selection.has(existingRow.data)
				)
					sSize++;
				if (
					trackAdditions &&
					additions.has(existingRow.data)
				)
					aSize++;
				if (trackEdits && edits.has(existingRow.data))
					eSize++;

				existingSize++;
			} else {
				const wrapper = validate(props.validate, {
					data: row,
					valid: true,
					checked: false,
					edits: {},
					editCount: 0
				});

				out.push(wrapper);
				rowMap.set(row, wrapper);

				if (initialized) {
					addEntry("addition", row);
					aSize++;
				}
			}
		}

		const calcSelection = sSize !== selection.size,
			calcAdditions = aSize !== additions.size,
			calcEdits = eSize !== edits.size,
			calcDeletions = existingSize !== currentExistingSize;

		// If there is a mismatch between recorded rows
		// and tracked row data, perform a second check to find
		// every entry that is out of date and should be discarded
		if (
			calcSelection ||
			calcAdditions ||
			calcEdits ||
			calcDeletions
		) {
			const rowSet = new Set() as Set<Row>;

			for (const row of props.rows) rowSet.add(row);

			if (calcDeletions) {
				rowMap.forEach((_, row) => {
					if (!rowSet.has(row)) {
						rowMap.delete(row);
						addEntry("deletion", row);
					}
				});
			}

			if (calcSelection) filterSet(rowSet, selection);
			if (calcAdditions) filterSet(rowSet, additions);
			if (calcEdits) filterSet(rowSet, edits);

			requestAnimationFrame(updateSelected);
		}

		replacements.clear();

		return out;
	}, [props.rows, replacementsCount, sourceCacheKey]);

	useEffect(() => {
		if (
			props.duplicateIndexes &&
			props.duplicateIndexes.length > 0
		) {
			const diffs = new Map();
			const p = currentProps.get();
			const mutate = typeof p.onBulkEdit !== "function";
			for (const index of props.duplicateIndexes) {
				diffs.set(rows[index], {
					...rows[index],
					checked: true
				});
			}
			const result = runDiffs(
				rows as RowWrapper[],
				diffs,
				mutate
			);
			if (result.changes.length && !mutate) {
				selection.clear();
				for (const change of result.changes) {
					updateRefs(
						change.from,
						change.to,
						change.toWrapper
					);
					selection.add(change.to);
				}
				p.onBulkEdit!({ ...result } as BulkEditEvent);
			}
			updateSelected();
		}
	}, [props.duplicateIndexes]);

	useEffect(() => {
		if (props.updatedIndexes && props.updatedIndexes.length > 0) {
			const diffs = new Map();
			const p = currentProps.get();
			const mutate = typeof p.onBulkEdit !== "function";
			for (const index of props.updatedIndexes) {
				diffs.set(rows[index], {
					...rows[index],
					checked: true
				});
			}
			const result = runDiffs(
				rows as RowWrapper[],
				diffs,
				mutate
			);
			if (result.changes.length && !mutate) {
				selection.clear();
				for (const change of result.changes) {
					updateRefs(
						change.from,
						change.to,
						change.toWrapper
					);
					selection.add(change.to);
				}
				p.onBulkEdit!({ ...result } as BulkEditEvent);
			}
			updateSelected();
		}
	}, [props.updatedIndexes]);

	const controls = useMemo(() => {
		const ctrls = props.controls ? props.controls.slice() : [];

		if (props.spreadEdit) {
			ctrls.unshift({
				mode: "checkbox",
				label: "Sync Rows",
				value: spread,
				onChange: (rt: CheckboxRuntime) =>
					setSpread(rt.checked)
			} as CheckboxControl<ActionRuntime>);
		}

		if (actions.delete) {
			const action = wrapAction(actions.delete, {
				label: "Delete",
				style: "alert"
			});

			ctrls.push(action);
		}

		if (actions.exportAll) {
			ctrls.push(
				wrapAction(actions.exportAll, {
					label: "Export Four Snaps to Email",
					style: "light-accent"
				})
			);
		}

		if (actions.export) {
			ctrls.push(
				wrapAction(actions.export, {
					label: "Export Ovr to Email",
					style: "accent"
				})
			);
		}

		return ctrls;
	}, [
		props.controls,
		props.spreadEdit,
		spread,
		actions.delete,
		actions.export
	]);

	const mkEditEvent = wrapEditEventMaker((evt: EditEvent) => {
		const newRow = evt.row;

		// Patch and intercept calls to set in case the set row
		// is not the same as the one returned in the edit event
		const setter = evt.set;
		evt.set = (...args) => {
			const a = evt.resolveSetArgs(...args);

			if (a.row === newRow) a.row = a.row.data;
			if (a.target === rows) a.target = props.rows;

			return setter(a.target, a.index, a.row);
		};
		evt.row = evt.row.data;

		return evt;
	});

	const filterHandler = (evt: FilterEvent) => {
		const p = evt.runtime,
			r = p.filteredRows,
			allRows = p.allRows,
			inRowsSet = new Set() as Set<Row>,
			diffs = new Map() as Map<RowWrapper, RowWrapper>;

		for (const { row } of r) {
			if (row.checked) inRowsSet.add(row);
		}

		selection.clear();

		for (const { row } of allRows) {
			if (!row.checked) continue;

			if (inRowsSet.has(row)) selection.add(row.data);
			else {
				const newRow = {
					...row,
					checked: false
				} as RowWrapper;

				diffs.set(row as RowWrapper, newRow);
			}
		}

		setPage(0);

		const diffed = runDiffs(
			rows,
			diffs,
			typeof props.onBulkEdit != "function"
		);

		for (const change of diffed.changes) {
			updateRefs(change.from, change.to, change.toWrapper);
			selection.delete(change.from);
		}

		if (
			diffed.changes.length &&
			typeof props.onBulkEdit == "function"
		) {
			props.onBulkEdit({
				type: "uncheck",
				runtime: evt.runtime,
				...diffed
			});
		}

		updateSelected();
	};

	const editHandler = (evt: EditEvent) => {
		if (!props.onEdit) return;

		props.onEdit(evt);

		const currentWrapper = rowMap.get(evt.rowPacket.row.data),
			newRow = evt.originalEvent!.row;

		if (currentWrapper) {
			const report = evt.report(),
				newData = report
					? report.newRow!
					: (newRow.data as Row);

			const newWrapper = {
				...newRow,
				data: newData
			} as RowWrapper;

			updateRefs(evt.rowPacket.row.data, newData, newWrapper);

			if (typeof props.onReferenceChange == "function") {
				props.onReferenceChange({
					from: evt.rowPacket.row.data,
					to: newData,
					runtime: evt.runtime
				} as ReferenceChangeEvent);
			}

			const preCount = newWrapper.editCount;

			if (evt.column.name !== "checkbox") {
				if (
					!newWrapper.edits.hasOwnProperty(
						evt.column.name
					)
				) {
					if (
						!equals(
							evt.value,
							evt.oldValue,
							"circular"
						)
					) {
						newWrapper.edits[
							evt.column.name
						] = evt.oldValue;
						newWrapper.editCount++;
					}
				} else if (
					equals(
						evt.value,
						newWrapper.edits[
							evt.column.name
						],
						"circular"
					)
				) {
					delete newWrapper.edits[
						evt.column.name
					];
					newWrapper.editCount--;
				}
			}

			if (!preCount && newWrapper.editCount)
				addEntry("edit", newData);
			else if (!newWrapper.editCount && preCount)
				deleteEntry("edit", newData);

			if (evt.rowPacket.row.checked !== newWrapper.checked) {
				if (newWrapper.checked)
					selection.add(newWrapper.data);
				else selection.delete(newWrapper.data);

				updateSelected();
			}
		}
	};

	const stageReplacement = (row: Row, wrapper: RowWrapper) => {
		replacements.set(row, wrapper);
		setReplacementsCount(rc => rc + 1);
	};

	const clearRowEdits = (row: Row): boolean => {
		const wrapper = rowMap.get(row);
		if (!wrapper || !wrapper.editCount) return false;

		if (edits.has(row)) deleteEntry("edit", row);

		stageReplacement(row, {
			...wrapper,
			editCount: 0,
			edits: {}
		});

		return true;
	};

	const clearAddition = (row: Row): boolean => {
		const wrapper = rowMap.get(row);
		if (!wrapper) return false;

		deleteEntry("addition", row);

		stageReplacement(row, {
			...wrapper
		});

		return true;
	};

	const actionRuntime = {
		state: {},
		get rows() {
			return rows.map(row => row.data);
		},
		selection,
		get selectionList() {
			return filterRows(rows, selection);
		},
		additions,
		get additionsList() {
			return filterRows(rows, additions);
		},
		edits,
		get editsList() {
			return filterRows(rows, edits);
		},
		deletions,
		get deletionsList() {
			return filterRows(rows, deletions);
		},
		deleteSelected: () => {
			return filterRows(rows, selection, true);
		},
		clearAddition: (row: Row) => {
			return clearAddition(row);
		},
		clearAdditions: () => {
			additions.forEach(clearAddition);
		},
		clearEdit: (row: Row) => {
			return clearRowEdits(row);
		},
		clearEdits: () => {
			edits.forEach(clearRowEdits);
		},
		clearDeletion: (row: Row) => {
			if (!deletions.has(row)) return false;

			deleteEntry("deletion", row);
			triggerRender();
			return true;
		},
		clearDeletions: () => {
			deletions.clear();
			triggerRender();
		},
		triggerRender,
		updateRows: props.store.updateRows
			? props.store.updateRows
			: () => console.error("No rows updater provided"),
		initialized
	} as ActionRuntime;

	const header = (rt: Runtime): JSX.Element => {
		return (
			<Header
				tableProps={props}
				runtime={rt}
				actionRuntime={actionRuntime}
				page={page}
				setPage={setPage}
				actions={actions}
				additions={additions}
				edits={edits}
				initialized={initialized}
			/>
		);
	};

	if (
		!initialized &&
		props.initialized !== false &&
		(!props.loadingState || props.loadingState.success)
	)
		setInitialized(true);

	const controlBar = (
		<ControlBarClipWrapper className="control-bar-wrapper">
			<ControlBarWrapper
				className="control-bar"
				ref={controlBarRef}
				transform={
					selectionSize
						? "translateY(0)"
						: "translateY(100%)"
				}
				onClick={evt => evt.stopPropagation()}
			>
				<ControlBar
					controls={controls || []}
					runtime={actionRuntime}
				>
					<SelectionLabel>
						{selectionSize === rows.length
							? "All "
							: ""}
						<SelectionCount>
							{selectionSize}
						</SelectionCount>
						&nbsp;
						{selectionSize === 1
							? "Row"
							: "Rows"}{" "}
						Selected
					</SelectionLabel>
				</ControlBar>
			</ControlBarWrapper>
		</ControlBarClipWrapper>
	);

	const tProps = {
		...props,
		compact: true,
		rows,
		columns,
		header,
		filterWidget: header,
		onFilter: filterHandler,
		onEdit: editHandler,
		window: props.pageSize,
		windowOffset: page * (props.pageSize || 0),
		store: {
			...(props.store || {}),
			spread,
			selection,
			actionState,
			selectionSize,
			actionRuntime
		},
		mkEditEvent,
		getRowData:
			typeof props.getRowData == "function"
				? props.getRowData
				: (row: RowPacket) => row.row.data,
		getRowPacket: (rt: Runtime, row: Row) => {
			if (typeof props.getRowPacket == "function")
				return props.getRowPacket(rt, row);

			return (
				rt.allRows.find(r => {
					return r.row.data === row;
				}) || null
			);
		}
	} as ActionTableProps;

	return (
		<Wrapper
			className={
				props.className
					? `action-table ${props.className}`
					: "action-table"
			}
			controlPadding={selectionSize ? controlBarHeight : 0}
		>
			<DataTable {...tProps} />
			{controlBar}
		</Wrapper>
	);
};

const ActionTable = withTheme(CoreActionTable);

export default ActionTable;

export {
	interceptRefs,
	mkEvenRowBackgroundGetter,
	mkOddRowBackgroundGetter,
	validate
};
