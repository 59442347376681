import { useEffect, useMemo, useState } from "react";

import { useAppSelector } from "../../../state/hooks";
import useTableActions from "../../../hooks/use-table-actions";
import useRemoteActionProps from "../../../hooks/use-remote-action-props";

import Base from "../../base";

import ActionTable from "../../../components/action-table";
import ContentCard from "../../../components/content-card";

import { ColumnCell } from "../../../types/data-table";
import { request } from "../../../utils";

import { PRESIDENTIAL } from "../../../data/constants";
import StateSelect from "../../../components/state-select";

interface MarketsProps {
	raceId?: string;
}

const RaceMarkets = (marketsProps: MarketsProps) => {
	const { markets, races } = useAppSelector(state => state.data);

	const [selectedState, setSelectedState] = useState<string>();

	const overrideRaceId = marketsProps.raceId;

	let COLUMNS: ColumnCell[] = [
		{
			name: "market",
			type: "choice",
			title: "Market",
			accessor: "market_id",
			config: {
				options: markets,
				labelAccessor: "name",
				valueAccessor: "id"
			},
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateCpp30",
			type: "currency",
			title: "Candidate CPP :30",
			accessor: "candidate_cpp_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateCpp60",
			type: "currency",
			title: "Candidate CPP :60",
			accessor: "candidate_cpp_60",
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateDra30",
			type: "currency",
			title: "Candidate DRA :30",
			accessor: "candidate_dra_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateDra60",
			type: "currency",
			title: "Candidate DRA :60",
			accessor: "candidate_dra_60",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueCpp30",
			type: "currency",
			title: "Issue CPP :30",
			accessor: "issue_cpp_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueCpp60",
			type: "currency",
			title: "Issue CPP :60",
			accessor: "issue_cpp_60",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueDra30",
			type: "currency",
			title: "Issue DRA :30",
			accessor: "issue_dra_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueDra60",
			type: "currency",
			title: "Issue DRA :60",
			accessor: "issue_dra_60",
			fit: "shrink",
			editable: true
		}
	];

	if (!overrideRaceId) {
		COLUMNS.unshift({
			name: "race_id",
			type: "choice",
			title: "Race",
			accessor: "race_id",
			index: "lexical",
			config: {
				options: races,
				labelAccessor: "race_name",
				valueAccessor: "id"
			},
			editable: true
		});
	}

	const actions = useTableActions({
		addLabel: "Add Market",
		add: () => ({
			race_id: overrideRaceId || -1,
			market_id: -1,
			candidate_cpp_30: 0,
			candidate_cpp_60: 0,
			issue_cpp_30: 0,
			issue_cpp_60: 0,
			candidate_dra_30: 0,
			candidate_dra_60: 0,
			issue_dra_30: 0,
			issue_dra_60: 0
		}),
		createEach: row => {
			return request({
				url: "/race_markets/update",
				body: row,
				method: "PUT"
			});
		},
		modifyEach: row => {
			return request({
				url: "/race_markets/update",
				body: row,
				method: "PUT"
			});
		},
		deleteEach: row => {
			return request({
				url: "/race_markets/delete",
				body: row,
				method: "DELETE"
			});
		}
	});

	const header = PRESIDENTIAL
		? () => (
				<StateSelect
					value={selectedState}
					onChange={v =>
						setSelectedState(v as string)
					}
				/>
		  )
		: undefined;

	const requestQuery = useMemo(() => {
		const queries = [];
		if (overrideRaceId) {
			queries.push(`race_id=${overrideRaceId}`);
		}
		if (selectedState) {
			queries.push(`state=${selectedState}`);
		}
		if (queries.length > 0) {
			return `?${queries.join("&")}`;
		}
		return "";
	}, [overrideRaceId, selectedState]);

	const props = useRemoteActionProps({
		request: `/race_markets/list${requestQuery}`,
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return overrideRaceId ? (
		<ActionTable {...props} header={header} />
	) : (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} header={header} />
			</ContentCard>
		</Base>
	);
};

export default RaceMarkets;
