import Select from "react-select";
import states from "../data/states.json";

type SelectOption = {
	label: string;
	value: string;
};

type StateSelectProps = {
	isMulti?: boolean;
	onChange: (value: string[] | string | undefined) => void;
	placeholder?: string;
	value: string[] | string | undefined;
};

const options = [
	{ label: "(all)", value: "" },
	...states.map(s => ({ label: s.state, value: s.state }))
];

function StateSelect(props: StateSelectProps) {
	const { onChange, value, ...rest } = props;

	function handleChange(
		newValue: readonly SelectOption[] | SelectOption | null
	) {
		if (Array.isArray(newValue)) {
			onChange(newValue.map(v => v.value));
		} else if (newValue) {
			onChange((newValue as SelectOption).value);
		} else {
			onChange(undefined);
		}
	}

	return (
		<Select
			{...rest}
			options={options}
			onChange={handleChange}
			styles={{
				menu: base => ({ ...base, zIndex: 9999 })
			}}
			value={options.find(opt => opt.value === value)}
		/>
	);
}

export default StateSelect;
