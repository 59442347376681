// @ts-ignore
// ignoring type checking as no type defined in html2pdf.js library
// see: https://github.com/eKoopmans/html2pdf.js/issues/398#issuecomment-943868851
import html2pdf from "html2pdf.js";
import { produce } from "immer";

const DOWNLOAD_PDF_DIV_ID = "pdf-content-container";

const fileOptions = {
	image: { quality: 1, type: "jpeg" },
	html2canvas: { scale: 3 },
	jsPDF: {
		format: "a4",
		orientation: "landscape",
		unit: "in"
	},
	margin: 0.5,
	pagebreak: {
		mode: ["avoid-all"]
	}
};

function downloadPdf(filename: string, fitToWidth: boolean = false) {
	const filenameWithExtension = filename.endsWith(".pdf")
		? filename
		: filename + ".pdf";
	console.log(`Downloading PDF file "${filenameWithExtension}"`);
	const elt = document.getElementById(DOWNLOAD_PDF_DIV_ID);
	const options = produce(fileOptions, draft => {
		if (fitToWidth) {
			const width = elt?.scrollWidth || elt?.clientWidth;
			if (!!width && width > 840) {
				const pxPerInch = width / 11;
				const marginPx = pxPerInch / 2;
				const pageWidth = width + marginPx * 2;
				(draft.jsPDF.format as any) = [
					pageWidth,
					(8.5 / 11) * pageWidth // A4 ratio
				];
				draft.jsPDF.unit = "px";
				draft.margin = marginPx;
			}
		}
	});

	return html2pdf().set(options).from(elt).save(filenameWithExtension);
}

export { downloadPdf, DOWNLOAD_PDF_DIV_ID };
