import { get } from "@qtxr/utils";

import { traverse } from "../common";

import StagedComponent from "./components";
import StagedAnchor from "./components/anchor";
import StagedCircle from "./components/circle";
import StagedLine from "./components/line";
import StagedPath from "./components/path";
import StagedRect from "./components/rect";
import StagedText from "./components/text";

import {
	Presets,
	Boundary,
	NodeData,
	PresetKey,
	BoundaryType,
	BoundaryGuard,
	BoundingArray,
	ClientBounding,
	AugmentedPoint,
	AugmentedGroup,
	PointerEventType,
	BoundaryGuardType,
	BoundaryGuardFunction
} from "../../../types/plot-svg";
import {
	Group,
	Point
} from "../apply-categorization";

const COMMON_STYLE_KEYS: PresetKey[] = [
	"fill",
	"stroke",
	"stroke-width",
	"pointer-events",
	"opacity",
	"outline",
	"transform-origin",
	"transform"
];

const BOUNDARY_GUARDS: Record<BoundaryGuardType, BoundaryGuardFunction> = {
	nested: b => {
		if (b.distance > 1)
			return false;

		return !(b.distance === 1 && (b.type.includes("start") || b.type.includes("end")));
	}
};

type PointsByLabel = {
	[key: string]: number;
}

export default class PlotSvg {
	runtime: any;
	index: number;
	presetKeys: PresetKey[];
	presets: Presets;
	components: StagedComponent[];
	currentComponent: StagedComponent | null;
	currentPoint: Point | null;
	currentNode: Point | Group | null;
	boundings: ClientBounding[];

	constructor(runtime: any) {
		this.runtime = runtime;
		this.index = -1;

		this.presetKeys = COMMON_STYLE_KEYS;
		this.presets = {} as Presets;

		this.components = [];
		this.currentComponent = null;

		this.currentPoint = null;
		this.currentNode = null;

		this.boundings = [];

		for (const key of this.presetKeys)
			this.addPreset(key);
	}

	// Semi-private API: presets
	addPreset(key: string) {
		this.presets[key] = {
			key,
			value: null,
			volatile: false
		};
	}

	setPreset(key: string, value: any, volatile: boolean = false) {
		const preset = this.presets[key];
		preset.value = value;
		preset.volatile = volatile;
	}

	setVolatilePreset(key: string, value: any) {
		this.setPreset(key, value, true);
	}

	clearPreset(key: string) {
		const preset = this.presets[key];
		preset.value = null;
		preset.volatile = false;
	}

	clearVolatilePreset(key: string) {
		if (this.presets[key].volatile)
			this.clearPreset(key);
	}

	clearPresets() {
		for (const key of this.presetKeys)
			this.clearPreset(key);
	}

	clearVolatilePresets() {
		for (const key of this.presetKeys)
			this.clearVolatilePreset(key);
	}

	getPresetValue(key: string): any {
		const preset = this.presets[key],
			value = preset.value;

		if (value === null) {
			console.error(`Failed to get value for preset '${key}': value not set`);
			return null;
		}

		this.clearVolatilePreset(key);
		return value;
	}

	stageComponent<Component extends StagedComponent>(
		constr: new (owner: PlotSvg, point: Point | null) => Component
	): (...args: any) => Component {
		return (...args: any) => {
			if (this.currentComponent)
				this.currentComponent.close();

			const component = new constr(this, this.currentPoint || null);

			this.components.push(component);
			this.currentComponent = component;

			if (this.boundings.length)
				this.boundings[this.boundings.length - 1].components.push(component);

			return component.open(...args);
		};
	}

	// Public API
	next(): this {
		this.index++;

		const node = this.runtime.data.nodes[this.index];
		this.at(node || null);

		return this;
	}

	atIndex(index: number): this {
		this.index = index;

		const node = this.runtime.data.nodes[this.index];
		this.at(node || null);

		return this;
	}

	with(point: Point): this {
		this.currentPoint = point;
		return this;
	}

	// Preset setters
	setFill(color: string): this {
		this.setPreset("fill", color);
		return this;
	}

	setFillOnce(color: string): this {
		this.setVolatilePreset("fill", color);
		return this;
	}

	setStroke(color: string): this {
		this.setPreset("stroke", color);
		return this;
	}

	setStrokeOnce(color: string): this {
		this.setVolatilePreset("stroke", color);
		return this;
	}

	setStrokeWidth(color: string): this {
		this.setPreset("stroke-width", color);
		return this;
	}

	setStrokeWidthOnce(color: string): this {
		this.setVolatilePreset("stroke-width", color);
		return this;
	}

	setPointer(type: PointerEventType): this {
		this.setPreset("pointer-events", type);
		return this;
	}

	setPointerOnce(type: PointerEventType): this {
		this.setVolatilePreset("pointer-events", type);
		return this;
	}

	setOpacity(opacity: number): this {
		this.setPreset("opacity", opacity);
		return this;
	}

	setOpacityOnce(opacity: number): this {
		this.setVolatilePreset("opacity", opacity);
		return this;
	}

	setOutline(outline: number): this {
		this.setPreset("outline", outline);
		return this;
	}

	setOutlineOnce(outline: number): this {
		this.setVolatilePreset("outline", outline);
		return this;
	}

	setTransformOrigin(origin: string): this {
		this.setPreset("transform-origin", origin);
		return this;
	}

	setTransformOriginOnce(origin: string): this {
		this.setVolatilePreset("transform-origin", origin);
		return this;
	}

	setTransform(transform: string): this {
		this.setPreset("transform", transform);
		return this;
	}

	setTransformOnce(transform: string): this {
		this.setVolatilePreset("transform", transform);
		return this;
	}

	// Bounding
	pushBounding(): ClientBounding {
		const bounding = {
			x: Infinity,
			y: Infinity,
			width: 0,
			height: 0,
			components: [],
			boundings: [],
			calculated: false
		};

		if (this.boundings.length)
			this.boundings[this.boundings.length - 1].boundings.push(bounding);

		this.boundings.push(bounding);

		return bounding;
	}

	popBounding(popRecursive = false): ClientBounding {
		const pop: any = (boundings: any) => {
			if (boundings.length && boundings[boundings.length - 1].boundings.length) {
				return pop(boundings[boundings.length - 1].boundings)
			}
			return boundings.pop()
		}
		let bounding
		if (true && this.boundings.length && this.boundings[this.boundings.length - 1].boundings.length) {
			bounding = pop(this.boundings)
		} else {
			bounding = this.boundings.pop()
		}

		if (!bounding)
			throw new Error("Cannot pop bounding: bounding has not been pushed");

		if (this.currentComponent)
			this.currentComponent.close();

		return this.calculateBounding(bounding);
	}

	calculateBounding(bounding: ClientBounding): ClientBounding {
		if (bounding.calculated)
			return bounding;

		let x = Infinity,
			y = Infinity,
			x2 = -Infinity,
			y2 = -Infinity,
			width = 0,
			height = 0;

		const update = (newX: number, newY: number, newW: number, newH: number) => {
			if (newX < x)
				x = newX;
			if (newY < y)
				y = newY;

			if (newX + newW > x2)
				x2 = newX + newW;

			if (newY + newH > y2)
				y2 = newY + newH;

			width = x2 - x;
			height = y2 - y;
		};

		for (const component of bounding.components) {
			const b = component.getBounding();

			if (Array.isArray(b))
				update(...b as BoundingArray);
			else if (b) {
				update(
					b.x,
					b.y,
					b.width,
					b.height
				);
			}
		}

		for (const b of bounding.boundings) {
			this.calculateBounding(b);

			update(
				b.x,
				b.y,
				b.width,
				b.height
			);
		}

		const res = this.runtime.inst.config.canvRes;
		bounding.x = x / res;
		bounding.y = y / res;
		bounding.width = width / res;
		bounding.height = height / res;
		bounding.calculated = true;
		return bounding;
	}

	largestPlotGroupSize() {
		const pointsByLabel: PointsByLabel = {}
		this.runtime.data?.nodes?.forEach((node: any) => {
			const points = (node?.level === 0 ? node?.points : (node?.level === 1 ? [node] : undefined));
			if (!points || !Array.isArray(points) || !points.length)
				return;
			points.forEach((point: any) => {
				const label: string = point?.trace?.[0]?.label;
				if (!label) {
					return;
				}
				if (!pointsByLabel[label]) {
					pointsByLabel[label] = 0;
				}
				pointsByLabel[label]++;
			})
		}, {})
		return Math.max(...Object.values(pointsByLabel)) || 0;
	}

	// Iteration
	forEach(callback: (data: NodeData) => void) {
		const nodes = this.runtime.data.nodes;

		while (this.index < nodes.length - 1) {
			this.next();

			const nodeData = {
				node: nodes[this.index],
				nodes,
				index: this.index,
				style: this.runtime.inst.config.style
			} as NodeData;

			callback(nodeData);
		}

		if (this.currentComponent)
			this.currentComponent.close();
	}

	at(node: Point | Group): this {
		this.currentNode = node;
		return this;
	}

	forEachNode<CallbackNode>(
		callback: (node: CallbackNode) => void,
		onPoints?: boolean,
		onGroups?: boolean
	) {
		traverse(
			this.currentNode || this.runtime.data.nodes[this.index],
			node => {
				let augmented;

				this.at(node);

				if ((node as Point).isPoint) {
					const p = node as Point,
						d = get(this.runtime.data.points[this.index], p.accessor);

					this.with(node as Point);

					augmented = {
						...p,
						accessor: p.accessor,
						value: d.value,
						label: d.label,
						index: this.index,
						trace: p.trace,
						style: this.runtime.inst.config.style,
						data: d
					} as AugmentedPoint;
				} else {
					augmented = {
						...(node as Group),
						style: this.runtime.inst.config.style
					} as AugmentedGroup;
				}

				callback(augmented as any);
			},
			onPoints,
			onGroups
		);
	}

	forEachPoint(callback: (point: AugmentedPoint) => void) {
		this.forEachNode(
			callback,
			true,
			false
		);

		if (this.currentComponent)
			this.currentComponent.close();
	}

	forEachGroup(callback: (point: AugmentedGroup) => void) {
		this.forEachNode(
			callback,
			false,
			true
		);

		if (this.currentComponent)
			this.currentComponent.close();
	}

	// Boundaries
	getBoundary(
		nodeOrGuard?: Point | Group | BoundaryGuard,
		guard?: BoundaryGuard
	): Boundary | null {
		const inputNode = nodeOrGuard && typeof nodeOrGuard != "function" && typeof nodeOrGuard != "string" ?
			nodeOrGuard :
			this.currentNode;
		let grd = typeof nodeOrGuard == "function" || typeof nodeOrGuard == "string" ?
			nodeOrGuard :
			guard || null;

		if (!inputNode)
			return null;

		const trace = inputNode.trace;

		if (typeof grd == "string")
			grd = BOUNDARY_GUARDS[grd];

		// Boundary types:
		// start:		the given node is the first node in its parent's nodes
		// end:			the given node is the last node in its parent's nodes
		// junction:	the given node is neither first nor last, but whose
		//				first child has received boundary focus
		const getB = (
			parentNodes: (Point | Group)[],
			n: Point | Group,
			distance: number,
			cBoundary: Boundary | null
		): [Boundary | null, Boundary | null] => {
			let boundary = null as Boundary | null;

			if (n.index === 0 && parentNodes.length === 1) {
				boundary = {
					type: "start",
					node: n,
					distance
				};
			} else if (n.index === 0) {
				boundary = {
					type: "start",
					node: n,
					distance
				};
			} else if (n.index === parentNodes.length - 1) {
				boundary = {
					type: "end",
					node: n,
					distance
				};
			}

			if (n.index !== 0 && cBoundary && cBoundary.type.includes("start")) {
				boundary = {
					type: n.index === parentNodes.length - 1 ? "junction|end" : "junction",
					node: n,
					distance
				};
			}

			if (boundary && typeof grd == "function" && !grd(boundary))
				return [null, boundary];

			return [boundary, boundary];
		};

		const boundaries = [];
		let childBoundary = null;

		for (let i = trace.length - 1; i >= 0; i--) {
			const n = trace[i],
				distance = trace.length - (i + 1);
			let boundary = null as Boundary | null;

			if (i === 0)
				[boundary, childBoundary] = getB(this.runtime.data.nodes, n, distance, childBoundary);
			else {
				const source = (n as Point).isPoint ?
					(trace[i - 1] as Group).points :
					(trace[i - 1] as Group).groups;

				[boundary, childBoundary] = getB(source, n, distance, childBoundary);
			}

			if (boundary)
				boundaries.push(boundary);
		}

		for (const boundary of boundaries) {
			if (boundary.type.includes("junction"))
				return boundary;
		}

		if (boundaries.length)
			return boundaries[0];

		return null;
	}

	getBoundaryType(
		nodeOrGuard?: Point | Group | BoundaryGuard,
		guard?: BoundaryGuard
	): BoundaryType | null {
		const boundary = this.getBoundary(nodeOrGuard, guard);
		if (!boundary)
			return null;

		return boundary.type;
	}

	// Components
	anchor(text?: string, href?: string): StagedAnchor {
		return this.stageComponent(StagedAnchor)(text, href);
	}

	circle(cx?: number, cy?: number, r?: number): StagedCircle {
		return this.stageComponent(StagedCircle)(cx, cy, r);
	}

	line(x1?: number, y1?: number, x2?: number, y2?: number): StagedLine {
		return this.stageComponent(StagedLine)(x1, y1, x2, y2);
	}

	path(d?: string): StagedPath {
		return this.stageComponent(StagedPath)(d);
	}

	rect(x?: number, y?: number, width?: number, height?: number): StagedRect {
		return this.stageComponent(StagedRect)(x, y, width, height);
	}

	text(text?: any, x?: number, y?: number): StagedText {
		return this.stageComponent(StagedText)(text, x, y);
	}

	// Render
	render() {
		if (this.currentComponent)
			this.currentComponent.close();

		this.components.sort((a, b) => a.zIndex - b.zIndex);

		for (const component of this.components)
			component.render();
	}
}
