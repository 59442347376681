import Base from "../base";

import { ColumnCell } from "../../types/data-table";
import { useEffect, useMemo, useState } from "react";
import { request } from "../../utils";
import { ControlUnion, DateRangeRuntime } from "../../types/control-bar";
import useQuerySettings from "../../hooks/use-query-settings";
import ControlBar from "../../components/control-bar";
import { BaseRuntime } from "../../types/common";
import styled from "styled-components";
import useRemoteActionProps from "../../hooks/use-remote-action-props";
import ActionTable from "../../components/action-table";
import { VerticalSeparator } from "../../components/separators";
import { useAppSelector } from "../../state/hooks";
import Dropdown, { DropdownOption } from "../../components/inputs/dropdown";
import DailySnapshot from "./daily-snapshot";
import { downloadPdf } from "../../utils/download-pdf";
import useTableActions from "../../hooks/use-table-actions";
import { PRESIDENTIAL } from "../../data/constants";
import states from "../../data/states.json";
import Select from "react-select";

const stateOptions = [
	{ value: "", label: "(all)" },
	...states.map(
		s =>
			({
				value: s.state,
				label: s.state
			} as DropdownOption)
	)
];

const presidentialColumns: ColumnCell[] = PRESIDENTIAL
	? [
			{
				name: "state",
				title: "State",
				accessor: "state",
				index: "lexical",
				fit: "shrink"
			}
	  ]
	: [];

const COLUMNS: ColumnCell[] = [
	{
		name: "race",
		title: "Race",
		accessor: "race_name",
		index: "lexical"
	},
	{
		name: "recipient",
		title: "Recipient",
		accessor: "email_list_name",
		index: "lexical"
	},
	...presidentialColumns
];

const runtime = {
	state: {}
} as BaseRuntime;

const PreviewHeader = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 15px;
	background: ${p => p.theme.componentBackground};
	border-radius: ${p => p.theme.borderRadius};
	font-weight: bold;
`;

const HeaderSpacer = styled.div`
	position: relative;
	height: 100%;
	margin-right: 1rem;
`;

const SnapshotsWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	padding: 15px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
`;

interface SnapshotProps {
	raceId: string;
	startDate: number;
	endDate: number;
}

type SelectOption = {
	label: string;
	value: string;
};

const Content = () => {
	const { agencies, races } = useAppSelector(state => state.data);
	const [raceId, setRaceId] = useState<any>();
	const [selectedState, setSelectedState] = useState<
		string | undefined
	>();
	const [updatePending, setUpdatePending] = useState(false);
	const [flightDateRange, setFlightDateRange] =
		useState<[number, number]>();
	const { querySettings, setDateRange } = useQuerySettings();

	const [campaignOptions, setCampaignOptions] = useState<SelectOption[]>(
		[]
	);
	const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>(
		[]
	);

	const [selectedAgency, setSelectedAgency] = useState<string>();

	const agencyOptions = useMemo<SelectOption[]>(
		() => [
			{ label: "<none>", value: "" },
			...agencies.map(a => ({
				label: a.full_name,
				value: a.id.toString()
			}))
		],
		[agencies]
	);

	useEffect(() => {
		if (raceId) {
			setUpdatePending(true);

			request({
				query: {
					race_id: raceId
				},
				url: "flights/list"
			})
				.then(({ data }) => {
					if (
						!Array.isArray(data) ||
						!data.length
					)
						return;
					const dateRange = data.reduce(
						(dateRange, flight) => {
							try {
								const flightStartTime =
									new Date(
										flight.start_date
									).getTime();
								const flightEndTime =
									new Date(
										flight.end_date
									).getTime() +
									24 *
										3600 *
										1000; // add 1 day

								const start =
									dateRange[0]
										? Math.min(
												flightStartTime,
												dateRange[0]
										  )
										: flightStartTime;
								const end =
									dateRange[1]
										? Math.max(
												flightEndTime,
												dateRange[1]
										  )
										: flightEndTime;
								if (
									start !==
										dateRange[0] ||
									end !==
										dateRange[1]
								) {
									return [
										start,
										end
									];
								}
							} catch {} // ignore bad data
							return dateRange;
						},
						[]
					);

					if (dateRange.length) {
						setFlightDateRange(dateRange);
					}
				})
				.finally(() => setUpdatePending(false));
		}
	}, [raceId]);

	useEffect(() => {
		request({
			url: "/campaigns/list",
			query: {
				race_id: raceId
			}
		}).then(campaignsResponse => {
			if (!campaignsResponse.success) {
				setCampaignOptions([]);
				return;
			}
			const campaigns: SelectOption[] =
				campaignsResponse.data.campaigns.map(
					(c: any) => ({
						label: c.candidate.full_name,
						value: c.id
					})
				);
			campaigns.sort((a, b) =>
				a.label.localeCompare(b.label)
			);
			setCampaignOptions(campaigns);
			setSelectedCampaigns([]);
		});
	}, [raceId]);

	const controls = [
		{
			label: "Snapshot",
			disabled: !raceId || updatePending,
			onClick: () => {
				const raceName = races.find(
					r => r.id === raceId
				)?.race_name;
				const filename = raceName
					? raceName + " Snapshot"
					: "Snapshot";
				downloadPdf(filename);
			}
		},
		{
			label: "\u{1F50D}",
			disabled: !flightDateRange,
			onClick: () => {
				if (flightDateRange)
					setDateRange(flightDateRange);
			}
		},
		{
			mode: "date-range",
			value: querySettings.dateRange,
			onChange: (rt: DateRangeRuntime) => {
				setDateRange(rt.range);
			}
		}
	] as ControlUnion[];

	const raceOptions = useMemo(() => {
		function sortRaces(a: any, b: any) {
			if (a.race_name === b.race_name) return 0;
			if (a.race_name < b.race_name) return -1;
			return 1;
		}
		const sortedRaces = [...races].sort(sortRaces);
		setRaceId(sortedRaces[0]?.id);
		return sortedRaces.map(
			race =>
				({
					value: race.id.toString(),
					label: race.race_name
				} as DropdownOption)
		);
	}, [races]);

	return (
		<div style={{ overflow: "auto", width: "50%" }}>
			<PreviewHeader>
				<span>Snapshot Preview for Race</span>
				<HeaderSpacer />
				<Dropdown
					value={raceId?.toString()}
					options={raceOptions}
					name="Race"
					onChange={(evt: any) => {
						const raceId = parseInt(
							evt?.target?.value
						);
						if (
							typeof raceId ===
								"number" &&
							isFinite(raceId)
						) {
							setRaceId(raceId);
						}
					}}
				/>
				{PRESIDENTIAL && (
					<Dropdown
						value={selectedState || ""}
						options={stateOptions}
						name="State"
						onChange={(evt: any) => {
							const state =
								evt?.target
									?.value;
							setSelectedState(state);
						}}
					/>
				)}
				<Select
					isMulti={true}
					onChange={v => {
						if (Array.isArray(v)) {
							setSelectedCampaigns(
								v.map(
									v =>
										v.value
								)
							);
						}
					}}
					placeholder="Campaigns"
					options={campaignOptions}
					value={campaignOptions.filter(c =>
						selectedCampaigns.includes(
							c.value
						)
					)}
				/>
				<Select
					onChange={v => {
						setSelectedAgency(
							v?.value || ""
						);
					}}
					placeholder="Agency"
					options={agencyOptions}
				/>
			</PreviewHeader>
			<ControlBar controls={controls} runtime={runtime} />
			{raceId && !updatePending && (
				<DailySnapshot
					race_id={raceId}
					start_date={
						querySettings
							.normalizedDateRange[0]
					}
					end_date={
						querySettings
							.normalizedDateRange[1]
					}
					state={selectedState}
					campaigns={
						selectedCampaigns.length > 0
							? selectedCampaigns
							: undefined
					}
					agency_id={selectedAgency}
				/>
			)}
		</div>
	);
};

const Snapshots = () => {
	const actions = useTableActions({
		export: async (row: any) => {
			const queries = ["snapshots=full_race"];
			if (row.state) {
				queries.push(`state=${row.state}`);
			}
			if (row.agency_id) {
				queries.push(`agency_id=${row.agency_id}`);
			}
			const query =
				queries.length > 0
					? `?${queries.join("&")}`
					: "";
			await request({
				url: `/cron/generate_daily_snapshot/${row.race_id}/${row.email_list_id}${query}`,
				method: "POST"
			});
		},
		exportAll: async (row: any) => {
			const queries = ["snapshots=all"];
			if (row.state) {
				queries.push(`state=${row.state}`);
			}
			if (row.agency_id) {
				queries.push(`agency_id=${row.agency_id}`);
			}
			const query =
				queries.length > 0
					? `?${queries.join("&")}`
					: "";
			await request({
				url: `/cron/generate_daily_snapshot/${row.race_id}/${row.email_list_id}${query}`,
				method: "POST"
			});
		}
	});

	const props = useRemoteActionProps({
		request: {
			url: "/snapshots/today"
		},
		actions,
		columns: COLUMNS,
		expand: true,
		pageSize: 50
	});

	return (
		<Base limited>
			<SnapshotsWrapper>
				<ActionTable {...props} />
				<VerticalSeparator />
				<Content />
			</SnapshotsWrapper>
		</Base>
	);
};

export default Snapshots;
