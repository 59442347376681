import {
	useRef,
	useMemo,
	useState,
	useEffect
} from "react";
import { transparentize } from "polished";
import styled, {
	css,
	withTheme
} from "styled-components";

import {
	get,
	set,
	clone,
	equals,
	hasOwn,
	distance
} from "@qtxr/utils";
import {
	cleanRegex,
	hasAncestor
} from "../../utils";
import StateCapsule from "../../utils/state-capsule";

import {
	FILTERS,
	FILTER_TEMPLATES,
	FilterName
} from "./filters";
import {
	ACTIONS,
	ACTION_TEMPLATES,
	ActionName
} from "./actions";
import {
	INDEXERS,
	IndexName
} from "./indexers";
import { COLUMNS } from "./columns";

import useStateCapsule from "../../hooks/use-state-capsule";

import Icon from "../icon";
import LoadingOverlay from "../loading-overlay";

import {
	Row,
	Filter,
	Action,
	Runtime,
	SortMode,
	EditEvent,
	RowPacket,
	CellProps,
	ColumnCell,
	FilterTerm,
	ColumnsMap,
	Background,
	FilterEvent,
	ActionProps,
	DrawerState,
	ContentProps,
	SortFunction,
	TickObserver,
	RowEditResult,
	BackgroundMap,
	FilterTrigger,
	ModalElements,
	EditEventMode,
	CellEditResult,
	SpreadCallback,
	EditEventMaker,
	ProcessOptions,
	DataTableProps,
	FilterSelection,
	IndexerFunction,
	FiltererFunction,
	StagedFilterState,
	PatchedColumnCell,
	BackgroundResolver,
	StagedCellFunction,
	BackgroundSourceMap,
	PatchedDataTableProps,
	InternalRowPacketGetter,
	ExternalRowPacketGetter
} from "../../types/data-table";
import { IconName } from "../../types/icon";

// == About ==
// Contained in this file is the DataTable definition, as well as components.tsx for filtering
// and base UI/logic for field editing and modals
//
// DataTable is a generic system for displaying tabular data in multiple formats,
// defined in a declarative fashion, with support for field editing and flexible filtering
//
// == Specification ==
// DataTable is fundamentally functional and reactive, but utilizes semi-mutable state
// and caching for performance-critical applications. Below is a description how
// these systems work together to provide highly capable and scalable tables
//
// Mutable state
// When row data is fed into the system, it undergoes an initial transform. Every row
// is wrapped in a wrapper object (RowPacket) which contains the raw row data, React
// elements for the corresponding cells, as well as values resolved from the data and
// filter states and a flag denoting whether row data has been mutated. This will persist
// until new row data is provided. Provide an onEdit callback to respond to edits as they happen
// A deep clone of the row data is passed to the callback along with the absolute index of the
// row and the column object of the field
//
// Caching
// Rows are aggressively cached to improve both processing and rendering performance
// When row data is provided to the component, it is referentially compared to its previous value
// If the reference is different, a diffing algorithm will run to generate or update
// internal row data (expressed using RowPacket objects). This algorithm bases its decisions
// on referential equality between row data. This ensures that rows can be
// readily rearranged without significant loss in diffing or rendering performance, and that
// changes can be brought upon rows simply by providing a cloned value of any row data that
// should be updated
// Forced updates can be enabled by specifying a cache key. Changing this key will trigger
// a complete re-process of the provided row data, and will reset the cache. Note that even
// if this key remains unchanged, the diffing algorithm will still run if the row data
// reference changes

// ====== Types and interfaces ======
// Component interfaces
interface ThemedDataTableProps extends DataTableProps {
	theme: any;
}

interface CompactProps {
	compact?: boolean;
}

interface RefProps {
	ref: any;
}

interface ScrollProps {
	obscureScrollBar?: boolean;
}

interface FilterBoxProps {
	column: PatchedColumnCell;
	terms: FilterTerm[];
	widget?: (props: Runtime) => JSX.Element;
	runtime: Runtime;
	onChange: (terms: FilterTerm[]) => void;
	compact?: boolean;
}

interface CellWrapperProps {
	editing: boolean;
}

interface FilterButtonProps {
	active: boolean;
	filtering: boolean;
	onClick: (evt?: any) => void;
}

interface EditBoxProps {
	onSet: (value: any) => void;
}

interface ExitButtonProps {
	onExit: () => void;
}

interface AbstractInputProps {
	value: any;
	onChange?: (evt: any) => void;
	onKeyDown?: (evt: any) => void;
	className?: string;
}

interface IconSvgProps {
	active?: boolean;
}

interface BoundModalProps {
	content?: (props: Runtime) => JSX.Element;
	runtime: Runtime;
}

interface ModalStateProps {
	isOpen: boolean;
}

interface DefaultFilterInputProps {
	value: string;
	filter: Filter | null;
	filters: Filter[];
	onBlur: (evt: any) => void;
	onClick: (evt: any) => void;
	onFocus: (evt: any) => void;
	onChange: (evt: any) => void;
	onKeyDown: (evt: any) => void;
	onFilterSelect: (filter: Filter) => void;
}

interface FilterOptionProps {
	active: boolean;
}

interface FilterTermEditorProps {
	term: FilterTerm;
	onChange: (evt: any) => void;
	onDelete: (term: FilterTerm) => void;
}

interface FilterTermEditorWrapperProps {
	valid: boolean;
}

interface DrawerProps {
	drawerState: DrawerState;
	compact?: boolean;
}

interface DrawerComponentProps {
	drawerProps: Runtime;
	drawerState: DrawerState;
	content?: (props: Runtime) => JSX.Element;
	compact?: boolean;
}

interface CellBackgroundProps {
	background?: string;
	children?: any;
}

interface EditEventConfig {
	row?: RowPacket;
	column?: PatchedColumnCell;
	newRow?: any;
}

type TableWrapperProps = Pick<PatchedDataTableProps, "columns" | "filteredColumns" | "rowBackgrounds">;
type ProcessOptionSetter = (po: Partial<ProcessOptions>) => void;

// ====== Constants (partial) ======
const PROCESS_OPTIONS_HASH_KEYS = [
	"sortIndex",
	"sortModeIndex",
	"filterKey",
	"filters"
];

const DEFAULT_SORT_MODES: SortMode[] = ["ascending", "descending", "none"];

// ====== Unique cell ID ======
let cellId = 0;

// ====== Components ======
// General
const Wrapper = styled.article<{ expand?: boolean }>`
	display: flex;
	flex-grow: ${p => p.expand ? 1 : "initial"};
	flex-direction: column;
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
`;

const Header = styled.div<CompactProps>`
	position: relative;
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	align-items: center;
	padding: ${p => p.compact ? "0 15px 8px" : "15px"};
	border-bottom: ${p => p.theme.tableBorder};
	min-height: ${p => p.compact ? "60px" : "65px"};

	&:before {
		content: "";
		position: absolute;
		bottom: 3px;
		left: 0;
		width: 100%;
		height: 5px;
		background: ${p => p.theme.cardBackground};
	}
`;

// Filter box
const FilterBoxWrapper = styled.div<RefProps & CompactProps>`
	display: flex;
	position: absolute;
	${p => p.compact ? {
		top: 0,
		bottom: "7px",
		left: 0,
		right: 0
	} : {
		top: "8px",
		bottom: "7px",
		left: "8px",
		right: "8px"
	}};
	padding: 6px;
	background: ${p => p.theme.background};
	border: 1px solid ${p => p.theme.backgroundAlt};
	border-radius: ${p => p.theme.borderRadius};
`;

const FilterTitle = styled.div`
	align-self: center;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.03em;
	margin: 0 16px 0 10px;
	color: ${p => p.theme.huedColor};
`;

const FilterBoxContent = styled.div`
	display: flex;
	flex-grow: 1;
	background: ${p => p.theme.cardBackground};
	border: 1px solid ${p => p.theme.backgroundAlt};
	border-radius: ${p => p.theme.borderRadius};
`;

const DefaultFilterInputWrapper = styled.div`
	position: relative;
	flex-grow: 1;
	height: 100%;
`;

const DefaultFilterInputInput = styled.input`
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	font: inherit;
	color: inherit;
	background: transparent;
	padding: 0 10px;
`;

const DefaultFilterTip = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	pointer-events: none;
	color: ${p => p.theme.huedColor};
	opacity: 0.9;

	code {
		margin: 0 0.4em;
		padding: 3px 6px;
		background: ${p => p.theme.background};
		border: 1px solid ${p => p.theme.backgroundAlt};
		border-bottom-width: 3px;
		border-radius: ${p => p.theme.borderRadius};
	}
`;

const FilterSelectionWrapper = styled.div`
	position: absolute;
	top: 100%;
	left: -1px;
	padding: 5px 10px 10px;
	background: ${p => p.theme.cardBackground};
	border-radius: 0 0 3px 3px;
	border: 1px solid ${p => p.theme.backgroundAlt};
	z-index: 100;
`;

const FilterOption = styled.button<FilterOptionProps>`
	display: block;
	margin-top: 5px;
	font: inherit;
	font-size: 85%;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	text-shadow: ${p => p.active ? "0 2px 2px rgba(0, 0, 0, 0.2)" : "none"};
	background: ${p => p.active ? p.theme.popColor : p.theme.background};
	color: ${p => p.active ? p.theme.cardBackground : p.theme.huedColor};
	border: ${p => `1px solid ${p.active ? "rgba(0, 0, 0, 0.1)" : p.theme.backgroundAlt}`};
	border-radius: ${p => p.theme.borderRadius};
	outline: none;
	padding: 4px 8px;
	cursor: pointer;
`;

const DefaultFilterInput = (props: DefaultFilterInputProps) => {
	const tip = props.value === "" ?
		(
			<DefaultFilterTip>
				type to filter, <code>:</code> to refine
			</DefaultFilterTip>
		) :
		null;
	let selection = null;

	if (props.filters.length) {
		const filters = props.filters.map((filter, i) => (
			<FilterOption
				key={i}
				active={filter === props.filter}
				onMouseDown={() => props.onFilterSelect(filter)}
				onTouchStart={() => props.onFilterSelect(filter)}
			>
				{filter.title}
			</FilterOption>
		));

		selection = (
			<FilterSelectionWrapper>
				{filters}
			</FilterSelectionWrapper>
		);
	}

	return (
		<DefaultFilterInputWrapper>
			<DefaultFilterInputInput
				className="filter-term default"
				value={props.value}
				onBlur={props.onBlur}
				onClick={props.onClick}
				onFocus={props.onFocus}
				onChange={props.onChange}
				onKeyDown={props.onKeyDown}
			/>
			{tip}
			{selection}
		</DefaultFilterInputWrapper>
	);
};

const FilterTermEditorWrapper = styled.div<FilterTermEditorWrapperProps>`
	display: flex;
	padding: 2px 2px 2px 12px;
	color: ${p => p.theme.cardBackground};
	background: ${p => p.valid ? p.theme.popColor : p.theme.popColorAlert};
	border-radius: ${p => p.theme.borderRadius};
	margin: 3px 0 3px 3px;
`;

const FilterTermTitle = styled.div`
	align-self: center;
	font-size: 90%;
	text-transform: uppercase;
`;

const FilterTermInput = styled.input`
	margin-left: 12px;
	padding: 0 6px;
	border: none;
	outline: none;
	border-radius: 2px;
	font: inherit;
	color: ${p => p.theme.huedColor};
`;

const FilterTermEditor = (props: FilterTermEditorProps) => {
	const handleKeyDown = (evt: any): void => {
		if (evt.key !== "Backspace")
			return;

		if (!evt.target.value)
			props.onDelete(props.term);
	};

	const Input = useMemo(
		() => getFilterInput(props.term.filter),
		[props.term.filter.name]
	);

	return (
		<FilterTermEditorWrapper
			valid={props.term.valid}
			title={props.term.errorMessage}
		>
			<FilterTermTitle>
				{props.term.filter.title}
			</FilterTermTitle>
			<Input
				className="filter-term pilled"
				value={props.term.value}
				onChange={props.onChange}
				onKeyDown={handleKeyDown}
			/>
		</FilterTermEditorWrapper>
	);
};

const FilterWidgetWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 0 5px 0 15px;
`;

const FilterBox = (props: FilterBoxProps) => {
	const ref = useRef();
	const [focusedFilter, setFocusedFilter] = useState(null as Filter | null);
	const [filterSelection, setFilterSelection] = useState(null as FilterSelection | null);
	const [{
		terms: stagedTerms,
		timeout
	}, setStagedFilterState] = useState({
		terms: props.terms,
		timeout: -1
	} as StagedFilterState);

	useEffect(
		() => selectLastInput(".filter-term.default"),
		[]
	);

	useEffect(
		() => {
			clearTimeout(timeout);
			setStagedFilterState({
				terms: props.terms,
				timeout: -1
			});
		},
		[props.terms]
	);

	const filterTerms = stagedTerms.slice(),
		defaultTerm = filterTerms.pop()!;

	const handleChange = (term: FilterTerm, evt: any): void => {
		setValue(term, evt.target.value);
	};

	const handleDefaultChange = (term: FilterTerm, evt: any): void => {
		handleChange(term, evt);
		updateFilterSelection(evt);
	};

	const setValue = (term: FilterTerm, value: string): void => {
		const idx = stagedTerms.indexOf(term);
		if (idx === -1)
			return;

		const terms = stagedTerms.slice();
		terms[idx] = {
			...term,
			value,
			cleanValue: value
		};

		clearTimeout(timeout);
		const newTimeout = window.setTimeout(() => {
			dispatchOnChange(terms.slice(), term);
		}, 200);

		setStagedFilterState({
			terms,
			timeout: newTimeout
		});
	};

	const dispatchOnChange = async (terms: FilterTerm[], term?: FilterTerm): Promise<void> => {
		const activeElement = document.activeElement;

		const dispatch = (ts: FilterTerm[]) => {
			if (term && typeof term.filter.validate === "function") {
				const outTerms = ts.map(t => {
					if (t.filter.name !== term.filter.name)
						return t;

					const validation = validateFilter(t.filter, t.cleanValue);
					return {
						...t,
						valid: validation === null,
						errorMessage: validation || ""
					};
				});

				props.onChange(outTerms);
				return;
			}

			props.onChange(ts);
		};

		if (!activeElement || !activeElement.classList.contains("default")) {
			dispatch(terms);
			return;
		}

		const selection = await getFilterSelection(
			stagedTerms,
			props.column,
			activeElement as HTMLInputElement
		);
		const hasFilters = Boolean(selection.filters.length);

		if (!hasFilters) {
			dispatch(terms);
			return;
		}

		const outTerms = terms.slice(),
			dTerm = outTerms[outTerms.length - 1],
			value = dTerm.value,
			cleanValue = cleanFilterSelectorString(selection, value);

		outTerms[outTerms.length - 1] = {
			...dTerm,
			value,
			cleanValue
		};

		dispatch(outTerms);
	};

	const deleteTerm = (term: FilterTerm): void => {
		const idx = stagedTerms.indexOf(term);
		if (idx === -1)
			return;

		const terms = stagedTerms.slice();
		terms.splice(idx, 1);

		clearTimeout(timeout);
		dispatchOnChange(terms);
		selectLastInput(".filter-term.default");
	};

	const getFilter = (direction: string): Filter | null => {
		if (!filterSelection)
			return null;

		const filters = filterSelection.filters,
			len = filters.length,
			idx = focusedFilter ?
				filters.indexOf(focusedFilter) :
				-1;

		if (direction === "up") {
			return idx === -1 ?
				filters[len - 1] :
				filters[(len + idx - 1) % len];
		}

		return idx === -1 ?
			filters[0] :
			filters[(idx + 1) % len];
	};

	const handleKeyDown = (evt: any): void => {
		const key = evt.key;

		switch (key) {
			case "Backspace": {
				if (evt.target.selectionStart || evt.target.selectionEnd)
					break;

				dispatchOnChange([
					...filterTerms.slice(0, filterTerms.length - 1),
					defaultTerm
				]);

				break;
			}
		}

		if (!filterSelection || !filterSelection.filters.length) {
			updateFilterSelection(evt);
			return;
		}

		switch (key) {
			case "ArrowUp":
				setFocusedFilter(getFilter("up"));
				evt.preventDefault();
				break;

			case "ArrowDown":
				setFocusedFilter(getFilter("down"));
				evt.preventDefault();
				break;

			case "Enter":
				if (focusedFilter)
					handleFilterSelect(focusedFilter);

				evt.preventDefault();
				break;

			default:
				updateFilterSelection(evt);
				break;
		}
	};

	const updateFilterSelection = async (evt: any): Promise<void> => {
		const selection = await getFilterSelection(
			stagedTerms,
			props.column,
			evt.target
		);

		const hasFilters = Boolean(selection.filters.length),
			hasOldFilters = Boolean(filterSelection?.filters.length);

		if (hasFilters)
			setFocusedFilter(selection.filters[0]);

		if (hasFilters || hasFilters !== hasOldFilters)
			setFilterSelection(selection);
	};

	const handleFilterSelect = (filter: Filter): void => {
		const newContent = cleanFilterSelectorString(
				filterSelection!,
				defaultTerm.value
			),
			validation = validateFilter(filter, "");

		setFilterSelection(null);

		dispatchOnChange([
			...filterTerms,
			{
				filter,
				value: "",
				cleanValue: "",
				valid: validation === null,
				errorMessage: validation || ""
			},
			{
				...defaultTerm,
				value: newContent,
				cleanValue: newContent
			}
		]);

		selectLastInput(".filter-term.pilled");
	};

	const selectLastInput = (selector: string): void => {
		setTimeout(() => {
			const wrapper = ref.current,
				inputs = (wrapper && (wrapper as Element).querySelectorAll(selector)) || [];

			if (inputs.length)
				(inputs[inputs.length - 1] as HTMLInputElement).focus();
		}, 30);
	};

	const terms = filterTerms.map((term, i) => (
		<FilterTermEditor
			key={i}
			term={term}
			onChange={(evt: any) => handleChange(term, evt)}
			onDelete={deleteTerm}
		/>
	));

	const widget = props.widget ?
		(
			<FilterWidgetWrapper>
				<props.widget {...props.runtime} />
			</FilterWidgetWrapper>
		) :
		null;

	return (
		<FilterBoxWrapper
			ref={ref}
			compact={props.compact}
		>
			<FilterTitle>{props.column.rawTitle || props.column.title}</FilterTitle>
			<FilterBoxContent>
				{terms}
				<DefaultFilterInput
					value={defaultTerm.value}
					filter={focusedFilter}
					filters={filterSelection?.filters || []}
					onBlur={() => setFilterSelection(null)}
					onClick={updateFilterSelection}
					onFocus={updateFilterSelection}
					onChange={(evt: any) => handleDefaultChange(defaultTerm, evt)}
					onKeyDown={handleKeyDown}
					onFilterSelect={handleFilterSelect}
				/>
			</FilterBoxContent>
			{widget}
		</FilterBoxWrapper>
	);
};

// Table
const TableContainer = styled.div<RefProps>`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	position: relative;
	margin-top: -8px;
	overflow: hidden;
`;

const TableContent = styled.div<DrawerProps>`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	overflow: hidden;
	transform-origin: 50% 100%;
	transform: ${p => p.drawerState.isActive || !p.drawerState.isOpen ?
		"none" :
		`scaleY(${p.drawerState.scale})`
	};
	transition: ${p => p.drawerState.isAnimating ?
		`transform ${p.drawerState.duration}ms` :
		"none"
	};
`;

const TableWrapper = styled.div<DrawerProps & ScrollProps>`
	flex-grow: 1;
	position: relative;
	overflow: auto;
	min-height: 200px;
	transform-origin: 50% 0;
	transform: ${p => p.drawerState.isActive || !p.drawerState.isOpen ?
		"none" :
		`scaleY(${1 / p.drawerState.scale})`
	};
	transition: ${p => p.drawerState.isAnimating ?
		`transform ${p.drawerState.duration}ms` :
		"none"
	};
	padding: ${p => p.compact ? "0 5px" : "0"};
	// Used to obscure scroll bar. Remove if UX/A11Y is affected
	margin-right: ${p => p.obscureScrollBar ? "-30px" : null};
`;

const EVEN_ROW_STYLE = css<TableWrapperProps>`
	${p => getEvenRowSelector(p.filteredColumns.length)} {
		background: ${p =>
			typeof p.rowBackgrounds.even == "string" ?
				p.rowBackgrounds.even :
				null
		};

		.cell-background.odd {
			display: none;
		}
	}
`;

const ODD_ROW_STYLE = css<TableWrapperProps>`
	${p => getOddRowSelector(p.filteredColumns.length)} {
		background: ${p =>
			typeof p.rowBackgrounds.odd == "string" ?
				p.rowBackgrounds.odd :
				null
		};

		.cell-background.even {
			display: none;
		}
	}
`;

const Table = styled.div<TableWrapperProps & ScrollProps>`
	display: grid;
	grid-template-columns: ${p => getColumnSizes(p.columns)};
	width: 100%;
	color: ${p => p.theme.tableColor};
	// Used to obscure scroll bar. Remove if UX/A11Y is affected
	padding-right: ${p => p.obscureScrollBar ? "30px" : null};

	${EVEN_ROW_STYLE};
	${ODD_ROW_STYLE};

	${p => `.cell:nth-child(${p.filteredColumns.length}n + 1)`} {
		border-top-left-radius: ${p => p.theme.borderRadius};
		border-bottom-left-radius: ${p => p.theme.borderRadius};
	}

	${p => `.cell:nth-child(${p.filteredColumns.length}n)`} {
		border-top-right-radius: ${p => p.theme.borderRadius};
		border-bottom-right-radius: ${p => p.theme.borderRadius};
	}

	${p => `.cell:not(:nth-child(${p.filteredColumns.length}n + 1))`} {
		border-left: ${p => p.theme.tableBorder};
	}

	.cell-background + *:not(.cell-background) {
		z-index: 1;
	}
`;

// Table headings
const HeadingCell = styled.div<ProcessOptions & CompactProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	padding: 8px 7px 8px 10px;
	background: ${p => p.theme.cardBackground};
	font-weight: bold;
	cursor: pointer;
	user-select: none;
	z-index: 10;

	&:not(:first-child) {
		border-left: ${p => p.theme.tableBorder};
	}

	&:before {
		content: "";
		position: absolute;
		${p => p.compact ? {
			top: "-1px",
			left: 0,
			right: 0
		} : {
			top: "-9px",
			left: "-1px",
			right: "-1px"
		}};
		height: 9px;
		background: ${p => p.theme.cardBackground};
		box-shadow: ${p => p.filterKey ? `inset 0 2px ${p.theme.backgroundAlt}` : "none"};
		border-bottom: ${p => p.theme.tableBorder};
		border-radius: inherit;
		pointer-events: none;
		z-index: -1;
	}
`;

const HeadingTitle = styled.div`
	margin-right: 0.6em;
`;

const HeadingControls = styled.div`
	display: flex;
	align-items: center;

	> svg {
		height: 1.2em;
		margin-right: 0.6em;
		opacity: 0.7;
		vertical-align: middle;
	}
`;

const FilterButtonWrapper = styled.button<FilterButtonProps>`
	position: relative;
	padding: 5px;
	margin: -5px;
	background: transparent;
	color: ${p => p.filtering ? p.theme.highlight : "inherit"};
	border: none;
	outline: none;
	border-radius: ${p => p.theme.borderRadius};
	cursor: pointer;

	&:before {
		content: "";
		display: ${p => p.active ? "block" : "none"};
		position: absolute;
		top: -30px;
		bottom: 0;
		left: 0;
		right: 0;
		background: ${p => p.theme.background};
		border: 1px solid ${p => p.theme.backgroundAlt};
		border-top: none;
		border-radius: inherit;
		box-shadow: ${p => p.theme.smallShadow};
	}
`;

const FilterSvg = styled(Icon)`
	height: 1.2em;
	opacity: 0.7;
`;

const FilterButton = (props: FilterButtonProps) => (
	<FilterButtonWrapper
		active={props.active}
		filtering={props.filtering}
		onClick={(evt: any) => {
			props.onClick();
			evt.stopPropagation();
		}}
	>
		<FilterSvg name={props.active || props.filtering ? "funnel" : "funnel-outline"} />
	</FilterButtonWrapper>
);

// Cells
const CellWrapper = styled.div<CellProps & CellWrapperProps>`
	position: relative;
	display: flex;
	align-items: ${p => p.editing ? "normal" : "center"};
	padding: ${p => p.editing ? "0" : "5px 10px"};
	user-select: none;
	cursor: ${p => p.column.action ? "pointer" : "default"};
	line-height: 1.2;

	&:hover:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-shadow: ${
			p => p.column.editable && !p.column.hasOwnEditHandler && !p.editing ?
				`inset 0 0 0 1px ${p.theme.popColor}, inset 0 0 0 3px ${transparentize(0.6, p.theme.popColor)}` :
				"none"
		};
		pointer-events: none;
		z-index: 8;
	}
`;

const CELL_BACKGROUND_STYLE = css<CellBackgroundProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: inherit;
	background: ${p => p.background};
`;

const CellBackgroundWrapper = styled.div`
	${CELL_BACKGROUND_STYLE};
`;

const CellBackground = (props: CellBackgroundProps) => (
	<CellBackgroundWrapper
		className="cell-background"
		background={props.background}
	>
		{props.children}
	</CellBackgroundWrapper>
);

const EvenCellBackgroundWrapper = styled.div`
	${CELL_BACKGROUND_STYLE};
`;

const EvenCellBackground = (props: CellBackgroundProps) => (
	<EvenCellBackgroundWrapper
		className="cell-background even"
		background={props.background}
	>
		{props.children}
	</EvenCellBackgroundWrapper>
);

const OddCellBackgroundWrapper = styled.div`
	${CELL_BACKGROUND_STYLE};
`;

const OddCellBackground = (props: CellBackgroundProps) => (
	<OddCellBackgroundWrapper
		className="cell-background odd"
		background={props.background}
	>
		{props.children}
	</OddCellBackgroundWrapper>
);

// Cell cont.: Edit cell UI
const EditBoxWrapper = styled.div<{ ref: any }>`
	display: flex;
	flex-grow: 1;
	align-content: center;
	border-radius: 1px;
	background: ${p => p.theme.cardBackground};
	box-shadow: 0 0 0 1px ${p => p.theme.popColor}, 0 0 0 3px ${p => transparentize(0.6, p.theme.popColor)};
	z-index: 9;

	input,
	select {
		flex-grow: 1;
		padding: 5px 8px;
		border: none;
		outline: none;
		font: inherit;
		color: inherit;
		background: transparent;
	}

	select {
		padding: 5px 8px 5px 6px;
		margin: 0 10px 0 0;
	}
`;

const EditActions = styled.div`
	display: flex;
	align-items: center;
	padding: 0 4px;
`;

const ActionWrapper = styled.div`
	padding: 2px;
`;

const EditBox = (props: CellProps & EditBoxProps & ExitButtonProps) => {
	const initialValue = useMemo(
		() => {
			const rawValue = props.row.values[props.column.name];
			return normalizeValue(props.column, rawValue);
		},
		[]
	);

	const ref = useRef(null as HTMLDivElement | null);
	const [value, setValue] = useState(initialValue);

	const Input = useMemo(
		() => getInput(props.column),
		[]
	);

	const actions = useMemo(
		() => getAssociatedActions(props.column),
		[props.column]
	);

	useEffect(() => {
		if (!ref.current)
			return;

		const input = ref.current.querySelector("input");
		let block = false;

		const handleGlobalEventStart = (evt: any) => {
			block = Boolean(ref.current) && hasAncestor(evt.target, ref.current as Node);
		};

		const handleGlobalEventEnd = () => {
			requestAnimationFrame(() => block = false);
		};

		const handleGlobalClick = () => {
			if (!block)
				props.onExit();
		};

		document.body.addEventListener("mousedown", handleGlobalEventStart);
		document.body.addEventListener("touchstart", handleGlobalEventStart);
		document.body.addEventListener("mouseup", handleGlobalEventEnd);
		document.body.addEventListener("touchend", handleGlobalEventEnd);
		document.body.addEventListener("click", handleGlobalClick);

		if (input)
			input.focus();

		return () => {
			document.body.removeEventListener("mousedown", handleGlobalEventStart);
			document.body.removeEventListener("touchstart", handleGlobalEventStart);
			document.body.removeEventListener("mouseup", handleGlobalEventEnd);
			document.body.removeEventListener("touchend", handleGlobalEventEnd);
			document.body.removeEventListener("click", handleGlobalClick);
		};
	}, []);

	const actionProps = {
		value: value,
		update: (value: any) => setValue(value),
		set: (value: any) => props.onSet(value),
		exit: () => props.onExit && props.onExit(),
		execute: () => {}
	} as ActionProps;

	const handleKeys = (evt: any): void => {
		const key = evt.key;

		for (const action of actions) {
			if (!action.hotkey)
				continue;

			// Extend this if more advanced hotkeys are needed
			if (action.hotkey === key && typeof action.executor == "function") {
				const shadowedProps = {
					...actionProps,
					execute: () => action.executor!(shadowedProps)
				} as ActionProps;

				shadowedProps.execute();
			}
		}
	};

	const handleChange = (evt: any) => {
		const rawValue = evt.target.value,
			val = typeof props.column.convertValue == "function" ?
				props.column.convertValue(rawValue, props.column) :
				rawValue;

		setValue(val);
	};

	const wrappedActions = actions.map((action, i) => {
		const shadowedProps: ActionProps = action.executor ?
			{
				...actionProps,
				execute: () => action.executor!(shadowedProps)
			} :
			actionProps;

		return (
			<ActionWrapper key={i}>
				<action.content {...shadowedProps} />
			</ActionWrapper>
		);
	});

	return (
		<EditBoxWrapper
			ref={ref}
			onKeyDown={handleKeys}
		>
			<Input
				value={value}
				onChange={handleChange}
			/>
			<EditActions>
				{wrappedActions}
			</EditActions>
		</EditBoxWrapper>
	);
};

const Cell = (props: CellProps) => {
	const [editing, setEditing] = useState(false);
	const [updates, setUpdates] = useState(0);
	let cProps: ContentProps | null = null;

	const handleClick = (evt: any) => {
		if (typeof props.column.action == "function")
			props.column.action(props);
		else
			initEdit(evt);
	};

	const initEdit = (evt: any): void => {
		if (!props.column.editable || props.column.hasOwnEditHandler)
			return;

		if (editing) {
			evt.stopPropagation();
			return;
		}

		setEditing(true);
	};

	const exitEdit = () => {
		setEditing(false);
	};

	const setValue = (value: any): void => {
		setCellValue(props, value, props.row.values[props.column.name]);
		setUpdates(updates + 1);
	};

	useEffect(() => {
		function applyMessageData(data: any): void {
			if (!data) return;
			if (Array.isArray(data)) {
				data.forEach(applyMessageData);
				return;
			}
			if (typeof data !== "object") return;
			if (props.column.name === data.column && props.row?.row?.data?.id === data.id) {
				setValue(data.value);
			}
		}

		if (props.listenForChangeMessages) {
			const listener = (e: MessageEvent<any>) => {
				if (e.origin !== window.origin) {
					console.log(`Unexpected message from ${e.origin}, ignoring`);
					return;
				}
				applyMessageData(e.data);
			}
			window.addEventListener("message", listener, false);

			return () => {
				window.removeEventListener("message", listener);
			}
		}
	}, [props.listenForChangeMessages, setValue])

	const constructBackground = (
		Component: (props: CellBackgroundProps) => JSX.Element,
		background: Background
	): JSX.Element | null => {
		if (typeof background == "string")
			return <Component background={background} />

		if (!cProps) {
			cProps = getContentProps(
				props.row,
				props.column,
				props.row.values[props.column.name],
				setValue,
				props.processOptions
			);
		}

		const content = (background as (props: ContentProps) => JSX.Element | string | null)(cProps);

		if (!content)
			return null;

		if (typeof content == "string")
			return <Component background={content} />

		return (
			<Component>
				{content}
			</Component>
		);
	};

	const backgrounds = props.column.backgrounds;
	let back = null,
		content;

	if (backgrounds.even && backgrounds.odd) {
		if (backgrounds.even === backgrounds.odd)
			back = constructBackground(CellBackground, backgrounds.even);
		else {
			back = (
				<>
					{constructBackground(EvenCellBackground, backgrounds.even)}
					{constructBackground(OddCellBackground, backgrounds.odd)}
				</>
			);
		}
	} else if (backgrounds.even)
		back = constructBackground(EvenCellBackground, backgrounds.even);
	else if (backgrounds.odd)
		back = constructBackground(OddCellBackground, backgrounds.odd);

	if (editing) {
		content = (
			<EditBox
				{...props}
				onSet={setValue}
				onExit={exitEdit}
			/>
		);
	} else {
		const value = props.row.values[props.column.name];
		content = getCellContent(
			props.row,
			props.column,
			value,
			setValue,
			props.processOptions
		);
	}

	return (
		<CellWrapper
			{...props}
			editing={editing}
			onClick={handleClick}
		>
			{back}
			{content}
		</CellWrapper>
	);
};

const SortIconSvg = styled(Icon)<IconSvgProps>`
	fill: ${p => p.active ? p.theme.highlight : "currentColor"}
`;

const setCellValue = (
	props: CellProps,
	value: any,
	oldValue: any,
	eventMode: EditEventMode = "manual"
): CellEditResult => {
	const column = props.column,
		row = props.row,
		val = typeof column.convertValue == "function" ?
			column.convertValue(value, column) :
			value,
		result = {
			row,
			column,
			newRow: null,
			value: val,
			oldValue
		} as CellEditResult;

	/*if (!column.editable)
		return result;*/

	const newRow = clone(row.row, "circular");

	set(newRow, column.accessor, val);
	result.newRow = newRow;

	row.values[column.name] = val;
	row.presentationValues[column.name] = getPresentationValue(
		column,
		row.values[column.name]
	);
	row.mutated = true;

	const pProps = props.processOptions.get().patchedProps;
	if (!pProps.onEdit)
		return result;

	const evt = pProps.mkEditEvent(props, val, oldValue, {
		row,
		column,
		newRow
	});
	evt.mode = eventMode;

	pProps.onEdit(evt);

	const report = evt.report();
	if (report)
		result.newRow = report.newRow;

	return result;
};

// Modals
const mkAbstractModalBlock = (
	elements: ModalElements
): ((props: BoundModalProps & DrawerProps) => JSX.Element | null) => {
	return (props: BoundModalProps & DrawerProps) => {
		const [open, setOpen] = useState(false);
		const [active, setActive] = useState(false);
		const [prevProps, setPrevProps] = useState(null as Runtime | null);

		const mp = props.runtime;

		if (open !== mp.isOpen) {
			if (mp.isOpen) {
				setOpen(true);
				setTimeout(() => setActive(true), 20);
			} else {
				setTimeout(() => setActive(false), 20);
				setTimeout(() => setOpen(false), 300);
			}
		}

		if (!open)
			return null;

		if (mp.row && prevProps !== mp)
			setPrevProps(mp);

		const latestProps = mp.isOpen ?
			mp :
			prevProps;

		const content = props.content ?
			(
				<props.content
					{...latestProps!}
					isOpen={open}
					isActive={active}
				/>
			) :
			null;

		return (
			<elements.wrapper>
				<elements.backdrop
					isOpen={active}
					onClick={mp.close}
				/>
				<elements.content
					isOpen={active}
					drawerState={props.drawerState}
				>
					{content}
				</elements.content>
			</elements.wrapper>
		);
	};
};

const TableModalWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 8px;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
`;

const TableModalBackdrop = styled.div<ModalStateProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${p => p.theme.modalBackground};
	backdrop-filter: blur(6px);
	opacity: ${p => p.isOpen ? 1 : 0};
	transition: opacity 300ms;
`;

const TableModalContentWrapper = styled.div<DrawerProps>`
	display: flex;
	flex-direction: column;
	padding: 25px;
	width: 100%;
	height: 100%;
	transform: ${p => p.drawerState.isActive || !p.drawerState.isOpen ?
		"none" :
		`scaleY(${1 / p.drawerState.scale})`
	};
	transition: ${p => p.drawerState.isAnimating ?
		`transform ${p.drawerState.duration}ms` :
		"none"
	};
	overflow: hidden;
`;

const TableModalInnerContent = styled.article<ModalStateProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: 100%;
	opacity: ${p => p.isOpen ? 1 : 0};
	transform: ${p => p.isOpen ? "none" : "translateY(30px)"};
	transition: opacity 300ms, transform 300ms;
	pointer-events: none;
	overflow: hidden;

	> * {
		pointer-events: auto;
	}
`;

const TableModalContent = (props: ModalStateProps & DrawerProps) => (
	<TableModalContentWrapper
		compact={props.compact}
		drawerState={props.drawerState}
	>
		<TableModalInnerContent {...props} />
	</TableModalContentWrapper>
);

const TableModal = mkAbstractModalBlock({
	wrapper: TableModalWrapper,
	backdrop: TableModalBackdrop,
	content: TableModalContent
});

const ModalHeaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 100;
`;

const ModalHeaderBackdrop = styled.div<ModalStateProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${p => p.theme.cardBackground};
	opacity: ${p => p.isOpen ? 1 : 0};
	transition: opacity 300ms;
`;

const ModalHeaderContent = styled.article<ModalStateProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: ${p => p.isOpen ? 1 : 0};
	transform: ${p => p.isOpen ? "none" : "translateY(100%)"};
	transition: opacity 300ms, transform 300ms;
`;

const ModalHeader = mkAbstractModalBlock({
	wrapper: ModalHeaderWrapper,
	backdrop: ModalHeaderBackdrop,
	content: ModalHeaderContent
});

// Drawers
const DrawerWrapper = styled.div<DrawerProps & RefProps>`
	position: relative;
	flex-shrink: 0;
	height: ${p => p.drawerState.height && !p.drawerState.isActive ?
		`${p.drawerState.height}px` :
		"auto"
	};
	margin-bottom: ${p => p.drawerState.height && !p.drawerState.isActive ?
		`-${p.drawerState.height}px` :
		"0"
	};
	overflow: hidden;
	z-index: 100;
`;

const DrawerContainer = styled.div<DrawerProps>`
	position: ${p => p.drawerState.isActive ?
		"relative" :
		"absolute"
	};
	width: 100%;
	transform: ${p => (p.drawerState.isActive === p.drawerState.isAnimating) === p.drawerState.isClosing ?
		"none" :
		"translateY(-100%)"
	};
	transition: ${p => p.drawerState.isAnimating ?
		`transform ${p.drawerState.duration}ms` :
		"none"
	};
`;

const DrawerBackground = styled.div`
	position: absolute;
	top: 0;
	bottom: 7px;
	left: 0;
	right: 0;
	background: ${p => p.theme.cardBackground};

	&:before {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 7px;
		background: linear-gradient(
			to bottom,
			${p => p.theme.cardBackground},
			${p => transparentize(1, p.theme.cardBackground)}
		);
	}
`;

const DrawerContent = styled.div`
	position: relative;
	z-index: 10;
`;

const Drawer = (props: DrawerComponentProps) => {
	const content = props.content && props.drawerState.isOpen ?
		<props.content {...props.drawerProps} /> :
		null;

	return (
		<DrawerContainer
			className="drawer-container"
			compact={props.compact}
			drawerState={props.drawerState}
		>
			<DrawerBackground />
			<DrawerContent>
				{content}
			</DrawerContent>
		</DrawerContainer>
	);
};

// ====== Table/row creation ======
const buildTable = (
	props: PatchedDataTableProps,
	headings: any[],
	rows: RowPacket[]
): JSX.Element => {
	const cells: any[] = [];

	for (const heading of headings)
		cells.push(heading);

	for (const row of rows) {
		const cs = row.cells;
		let resolvedCells = cs;

		if (typeof cs == "function") {
			resolvedCells = cs();
			row.cells = resolvedCells;
		}

		for (const c of resolvedCells as JSX.Element[])
			cells.push(c);
	}

	return (
		<Table
			className="cells"
			columns={props.columns}
			filteredColumns={props.filteredColumns}
			rowBackgrounds={props.rowBackgrounds}
			obscureScrollBar={props.obscureScrollBar}
		>
			{cells}
		</Table>
	);
};

const getColumnSizes = (columns: PatchedColumnCell[]): string => {
	const filteredColumns = columns.filter(col => col.type !== "hidden");
	const fits: [string, number][] = [];

	for (const column of filteredColumns) {
		let fitString = "auto";

		switch (column.fit) {
			case "shrink":
				fitString = "max-content";
				break;
		}

		const lastFit = fits[fits.length - 1];

		if (!lastFit || lastFit[0] !== fitString)
			fits.push([fitString, 1]);
		else
			lastFit[1]++;
	}

	return fits
		.map(([fit, count]) => {
			return count === 1 ?
				fit :
				`repeat(${count}, ${fit})`;
		})
		.join(" ");
};

const mkHeadings = (
	props: PatchedDataTableProps,
	runtime: Runtime,
	processOptions: ProcessOptions,
	set: ProcessOptionSetter
): JSX.Element[] => {
	const updateSort = (idx: number): void => {
		if (idx === processOptions.sortIndex) {
			set({
				sortModeIndex: (processOptions.sortModeIndex + 1) % processOptions.sortModes.length
			});
		} else {
			set({
				sortIndex: idx,
				sortModes: props.filteredColumns[idx].sortModes,
				sortModeIndex: 0
			});
		}
	};

	return props.filteredColumns.map((col, i) => {
		let sortIcon = null,
			filterButton = null;

		if (col.sort !== null) {
			let sortName = "sort-none";

			if (processOptions.sortIndex === i)
				sortName = `sort-${processOptions.sortModes[processOptions.sortModeIndex]}`;

			sortIcon = (
				<SortIconSvg
					name={sortName as IconName}
					active={getSortMode(processOptions, i) !== "none"}
				/>
			);
		}

		if (col.filter !== null) {
			const toggleFilter = (): void => {
				if (processOptions.filterKey === col.name) {
					set({
						filterKey: null
					});
				} else {
					set({
						filters: {
							...processOptions.filters,
							[col.name]: processOptions.filters[col.name] || [getDefaultFilterTerm()]
						},
						filterKey: col.name
					});
				}
			};

			filterButton = (
				<FilterButton
					active={processOptions.filterKey === col.name}
					filtering={isFiltering(processOptions.filters[col.name])}
					onClick={toggleFilter}
				/>
			);
		}

		const title = typeof col.title == "function" ?
			<col.title {...runtime} /> :
			col.title;

		return (
			<HeadingCell
				{...processOptions}
				key={i}
				className="heading"
				compact={props.compact}
				onClick={() => updateSort(i)}
			>
				<HeadingTitle>{title}</HeadingTitle>
				<HeadingControls>
					{sortIcon}
					{filterButton}
				</HeadingControls>
			</HeadingCell>
		);
	});
};

const mkRows = (
	props: PatchedDataTableProps,
	processOptions: StateCapsule<ProcessOptions>
): RowPacket[] => {
	return props.rows.map((row, i) => {
		const rowPacket = mkRow(props, processOptions, row);
		rowPacket.index = i;
		return rowPacket;
	});
};

const diffRows = (
	newRows: any[],
	oldRows: RowPacket[],
	props: PatchedDataTableProps,
	processOptions: StateCapsule<ProcessOptions>
): RowPacket[] => {
	const oldMap = new Map(),
		out = [],
		queued = [];

	for (const row of oldRows)
		oldMap.set(row.row, row);

	for (let i = 0, l = newRows.length; i < l; i++) {
		const row = newRows[i],
			oldRow: RowPacket = oldMap.get(row);
		let newRow: RowPacket = oldRow,
			diff = !oldRow;

		if (!diff && typeof props.diff == "function") {
			diff = props.diff({
				newRow: row,
				oldRow: oldRow || null
			});
		}

		if (diff) {
			newRow = mkRow(
				props,
				processOptions,
				row
			);

			if (oldRow && typeof props.onReferenceChange == "function") {
				props.onReferenceChange({
					from: oldRow,
					to: newRow,
					runtime: processOptions.get().runtime!
				});
			}

			queued.push(newRow);
		}

		newRow.index = i;
		out.push(newRow);
	}

	if (queued.length)
		applyFiltersToAll(queued, props.columns, processOptions.get());

	return out;
};

const mkRow = (
	props: PatchedDataTableProps,
	processOptions: StateCapsule<ProcessOptions>,
	row: any
): RowPacket => {
	const columns = props.columns;

	const set = (r?: any) => {
		const cProps = {
			row: rowPacket,
			column: columns[0],
			processOptions
		} as CellProps;

		const p = processOptions.get().patchedProps;

		const evt = p.mkEditEvent(
			cProps,
			rowPacket.values[columns[0].name],
			rowPacket.values[columns[0].name]
		);

		if (typeof p.onEdit == "function") {
			p.onEdit(evt);
			return evt.report();
		}

		if (r)
			return evt.set(rowPacket.index, r);

		return evt.set();
	};

	const edit = (row: Row) => {
		for (const column of columns) {
			const value = get(row, column.accessor);
			if (equals(value, rowPacket.values[column.name], "circular"))
				continue;

			const cProps = {
				row: rowPacket,
				column,
				processOptions
			} as CellProps;

			setCellValue(
				cProps,
				value,
				rowPacket.values[column.name]
			);
		}
	};

	const rowPacket = {
		row,
		values: {},
		presentationValues: {},
		indexedValues: {},
		filterStates: {},
		mutated: false,
		cells: [] as JSX.Element[],
		index: -1,
		refresh: () => set(),
		set: (r: any) => set(r),
		edit,
		processOptions,
		isRowPacket: true
	} as RowPacket;

	rowPacket.cells = mkStagedCells(
		props.filteredColumns,
		rowPacket,
		processOptions,
		props.listenForChangeMessages
	);

	cacheRow(rowPacket, props);

	if (typeof props.onNewRow == "function") {
		props.onNewRow({
			row: props.getRowData(rowPacket),
			rowPacket
		});
	}

	return rowPacket;
};

const mkStagedCells = (
	filteredColumns: PatchedColumnCell[],
	row: RowPacket,
	processOptions: StateCapsule<ProcessOptions>,
	listenForChangeMessages?: boolean
): StagedCellFunction => {
	return () => {
		return filteredColumns.map(col => (
			<Cell
				className="cell"
				key={`cell-${cellId++}`}
				row={row}
				column={col}
				processOptions={processOptions}
				listenForChangeMessages={listenForChangeMessages}
			/>
		));
	}
};

const cacheRow = (row: RowPacket, props: PatchedDataTableProps): void => {
	const columns = props.columns;

	for (const column of columns) {
		row.values[column.name] = get(row.row, column.accessor);
		row.presentationValues[column.name] = getPresentationValue(
			column,
			row.values[column.name]
		);
		row.filterStates[column.name] = true;
		row.indexedValues[column.name] = (column.index as IndexerFunction)(
			row.presentationValues[column.name],
			row
		);
	}
};

const getContentProps = (
	row: RowPacket,
	column: PatchedColumnCell,
	value: any,
	setValue: (value: any) => void,
	processOptions: StateCapsule<ProcessOptions>
): ContentProps => {
	return {
		row,
		column,
		value,
		presentationValue: row.presentationValues[column.name],
		set: setValue,
		open: (r?: any) => {
			const arg = r === undefined ?
				resolveRow(row.row) :
				r;

			if (!processOptions)
				return;

			processOptions.get().opener(arg);
		},
		queryStore: (accessor?: string | string[]) => {
			const store = processOptions ?
				processOptions.get().props.store || {} :
				{};

			return get(store, accessor);
		},
		processOptions
	};
};

const getCellContent = (
	row: RowPacket,
	column: PatchedColumnCell,
	value: any,
	setValue: (value: any) => void,
	processOptions: StateCapsule<ProcessOptions>
): JSX.Element => {
	const props = getContentProps(
		row,
		column,
		value,
		setValue,
		processOptions
	);

	if (value === null && typeof column.nullContent == "function")
		return <column.nullContent {...props} />;

	const Content = column.content!;
	return <Content {...props} />;
};

const getEvenRowSelector = (width: number): string => {
	const components = [];

	for (let i = 0; i < width; i++)
		components.push(`.cell:nth-child(${width * 2}n + ${i + 1})`);

	return components.join(", ");
};

const getOddRowSelector = (width: number): string => {
	const components = [];

	for (let i = width - 1; i >= 0; i--) {
		if (i)
			components.push(`.cell:nth-child(${width * 2}n - ${i})`);
		else
			components.push(`.cell:nth-child(${width * 2}n)`);
	}

	return components.join(", ");
};

// ====== Inputs ======
const getInput = (column: PatchedColumnCell): ((props: AbstractInputProps) => JSX.Element) => {
	return column.input!(column);
};

const getFilterInput = (filter: Filter): ((props: AbstractInputProps) => JSX.Element) => {
	return (props: AbstractInputProps) => {
		switch (filter.name) {
			case "on":
			case "after":
			case "before":
				return <FilterTermInput type="date" {...props} />;

			default:
				return <FilterTermInput {...props} />;
		}
	};
};

const getAssociatedActions = (column: PatchedColumnCell): Action[] => {
	const outActions: Action[] = [];
	let actions: (Action | ActionName)[] = ACTION_TEMPLATES.standard;

	if (column.actions)
		actions = column.actions;
	else if (column.type && ACTION_TEMPLATES.hasOwnProperty(column.type))
		actions = ACTION_TEMPLATES[column.type];

	for (const action of actions) {
		if (typeof action == "string")
			outActions.push(ACTIONS[action] as Action);
		else
			outActions.push(action);
	}

	return outActions;
};

// ====== Filtering ======
const getFilterTrigger = (input: HTMLInputElement): FilterTrigger | null => {
	const sS = input.selectionStart || 0,
		sE = input.selectionEnd || 0,
		value = input.value;
	let start = sS,
		end = -1,
		foundStart = false;

	while (start >= 0) {
		start--;

		if (value[start] === ":") {
			foundStart = true;
			break;
		}
	}

	if (!foundStart)
		return null;

	end = start + 1;

	for (let i = start + 1, l = value.length; i < l; i++) {
		if (!/[\w-]/.test(value[i]))
			break;

		end = i + 1;
	}

	if (end < start || end < sE)
		return null;

	return {
		start,
		end,
		value: value.substring(start + 1, end)
	};
};

const getAssociatedFilters = (column: PatchedColumnCell): Filter[] => {
	const outFilters: Filter[] = [];
	let filters: (Filter | FilterName)[] = FILTER_TEMPLATES.standard;

	if (column.filters)
		filters = column.filters;
	else if (column.type && FILTER_TEMPLATES.hasOwnProperty(column.type))
		filters = FILTER_TEMPLATES[column.type];

	for (const filter of filters) {
		if (typeof filter == "string")
			outFilters.push(FILTERS[filter] as Filter);
		else
			outFilters.push(filter);
	}

	return outFilters;
};

const getApplicableFilters = (
	terms: FilterTerm[],
	search: string,
	filters: Filter[]
): Filter[] => {
	const regex = new RegExp(cleanRegex(search), "i"),
		lowerSearch = search.toLowerCase();

	const filtered = filters.filter(filter => {
		if (terms.find(term => term.filter === filter))
			return false;

		return regex.test(filter.name) || regex.test(filter.title);
	});

	if (!search.length)
		return filtered;

	const weighted = filtered.map(f => {
		const lowerTitle = f.title.toLowerCase(),
			searchIndex = lowerTitle.indexOf(lowerSearch),
			indexPenalty = searchIndex > 2 ?
				searchIndex * 3 :
				searchIndex * 2;

		return {
			distance: distance(lowerTitle, lowerSearch) + indexPenalty,
			filter: f
		};
	});

	return weighted
		.sort((w, w2) => {
			return w2.distance > w.distance ? -1 : 1;
		})
		.map(w => w.filter);
};

const getFilterSelection = (
	terms: FilterTerm[],
	column: PatchedColumnCell,
	input: HTMLInputElement
): Promise<FilterSelection> => {
	return new Promise(resolve => {
		requestAnimationFrame(() => {
			const trigger = getFilterTrigger(input);

			if (!trigger) {
				resolve({
					start: -1,
					end: -1,
					filters: []
				});
			} else {
				resolve({
					start: trigger.start,
					end: trigger.end,
					filters: getApplicableFilters(
						terms,
						trigger.value,
						getAssociatedFilters(column)
					)
				});
			}
		});
	});
};

const cleanFilterSelectorString = (selection: FilterSelection, string: string): string => {
	if (selection.start === -1)
		return string;

	const prefix = string
			.substring(0, selection.start)
			.trim(),
		postfix = string
			.substring(selection.end)
			.trim();

	return prefix && postfix ?
		`${prefix} ${postfix}` :
		prefix + postfix;
};

const applyFilterToAll = (
	filter: FilterTerm[],
	rows: RowPacket[],
	column: PatchedColumnCell
): void => {
	const filterer = getFilterer(filter);

	for (const row of rows)
		row.filterStates[column.name] = filterer(row, column);
};

const applyFiltersToAll = (
	rows: RowPacket[],
	columns: PatchedColumnCell[],
	processOptions: ProcessOptions
): void => {
	for (const column of columns) {
		const filter = processOptions.filters[column.name];

		if (filter)
			applyFilterToAll(filter, rows, column);
	}
};

const getFilterer = (filterTerms: FilterTerm[]): (
	(row: RowPacket, column: PatchedColumnCell) => boolean
	) => {
	const terms: FiltererFunction[] = [];

	for (const term of filterTerms) {
		if (!term.cleanValue)
			continue;

		terms.push(
			term.filter.factory(term.cleanValue)
		);
	}

	if (!terms.length)
		return () => true;

	return (row: RowPacket, column: PatchedColumnCell) => {
		const value = row.presentationValues[column.name];

		for (const term of terms) {
			if (!term(value, row, column))
				return false;
		}

		return true;
	};
};

const validateFilter = (filter: Filter, filterString: string) => {
	if (typeof filter.validate != "function")
		return null;

	const validation = filter.validate(filterString);

	if (validation === true || validation == null)
		return null;

	if (validation === false)
		return "Invalid filter value";

	return String(validation);
};

const isFiltering = (terms: FilterTerm[]): boolean => {
	if (!terms)
		return false;

	for (const term of terms) {
		if (term.cleanValue && term.valid)
			return true;
	}

	return false;
};

// ====== Sorting ======
const getSorter = (processOptions: ProcessOptions): SortFunction | null => {
	if (processOptions.sortIndex === -1)
		return null;

	const sortIndex = processOptions.sortIndex,
		column = processOptions.patchedProps.filteredColumns[sortIndex],
		mode = getSortMode(processOptions),
		sorter = column.sort;

	if (mode === "none" || sorter === null)
		return null;

	const sf = getSortFunction(processOptions),
		sign = mode === "ascending" ?
			1 :
			-1;

	return (d, d2) => sf(d, d2) * sign;
};

const getSortMode = (processOptions: ProcessOptions, index?: number): SortMode => {
	if (processOptions.sortIndex === -1)
		return "none";

	if (typeof index == "number" && index !== processOptions.sortIndex)
		return "none";

	return processOptions.sortModes[processOptions.sortModeIndex];
};

const getSortFunction = (processOptions: ProcessOptions): SortFunction => {
	const sortIndex = processOptions.sortIndex,
		column = processOptions.patchedProps.filteredColumns[sortIndex],
		name = column.name,
		sort = column.sort;

	if (typeof sort == "function")
		return sort;

	return (d, d2) => {
		const val = d.indexedValues[name],
			val2 = d2.indexedValues[name];

		if (val < val2)
			return -1;

		return 1;
	};
};

// ====== Value utilities ======
const normalizeValue = (column: PatchedColumnCell, value: any): string => {
	return column.normalizeValue!(value, column);
};

const getPresentationValue = (column: PatchedColumnCell, value: any): any => {
	return column.getPresentationValue!(value, column);
};

// ====== Prop hashing ======
const hashProcessOptions = (processOptions: ProcessOptions): string => {
	let hash = "";

	for (const key of PROCESS_OPTIONS_HASH_KEYS) {
		if (key === "filters")
			hash += `${key}:${hashFilters(processOptions)}`;
		else
			hash += `${key}:${(processOptions as any)[key]}`;
	}

	return hash;
};

const hashFilters = (processOptions: ProcessOptions): string => {
	const columns = processOptions.props.columns,
		filterHashes = [];

	for (const column of columns) {
		const filter = processOptions.filters[column.name];

		if (!filter) {
			filterHashes.push("none");
			continue;
		}

		filterHashes.push(
			hashFilterTerms(filter)
		);
	}

	return filterHashes.join(",");
};

const hashFilterTerms = (terms: FilterTerm[]): string => {
	return terms
		.map(term => `${term.filter.name}/${term.cleanValue}`)
		.join("-");
};

// The default filter has no validator since there is no
// way to cleanly display error messages for them
const getDefaultFilterTerm = (): FilterTerm => ({
	filter: FILTERS.default,
	value: "",
	cleanValue: "",
	valid: true,
	errorMessage: ""
});

// ====== Runtime actions ======
const filterAndSort = (processOptions: ProcessOptions, rows: RowPacket[]): RowPacket[] => {
	const sorter = getSorter(processOptions);
	let filtered;

	if (!processOptions.filterKeys.length) {
		if (!sorter)
			return rows;

		filtered = rows.slice();
	} else {
		filtered = rows.filter(row => {
			for (const key of processOptions.filterKeys) {
				if (!row.filterStates[key])
					return false;
			}

			return true;
		});
	}

	if (!sorter)
		return filtered;

	return filtered.sort(sorter);
};

const skipFrame = (): Promise<void> => {
	return new Promise(resolve => {
		requestAnimationFrame(() => resolve());
	});
};

const sleep = (duration: number): Promise<void> => {
	return new Promise(resolve => {
		setTimeout(() => resolve(), duration);
	});
};

const triggerDrawer = async (
	open: boolean,
	state: DrawerState,
	setter: (value: DrawerState) => void,
	drawerRef: any,
	containerRef: any
): Promise<void> => {
	if (state.isOpen === open)
		return;

	let localState = state;

	const getScale = () => {
		const dElem = drawerRef.current,
			cElem = containerRef.current;

		if (!dElem || !cElem)
			return 1;

		const dHeight = dElem
				.querySelector(".drawer-container")
				.getBoundingClientRect()
				.height,
			cHeight = cElem
				.getBoundingClientRect()
				.height,
			contentHeight = open ?
				cHeight - dHeight :
				cHeight,
			totalHeight = open ?
				cHeight :
				cHeight + dHeight;

		return contentHeight / totalHeight;
	};

	const getDrawerHeight = () => {
		const dElem = drawerRef.current;
		if (!dElem)
			return 0;

		return dElem
			.querySelector(".drawer-container")
			.getBoundingClientRect()
			.height;
	};

	const dispatch = (newState: any) => {
		localState = {
			...localState,
			...newState
		};

		setter(localState);
	};

	if (open) {
		dispatch({
			isOpen: true,
			isClosing: false
		});

		await skipFrame();
		dispatch({
			height: getDrawerHeight()
		});

		await skipFrame();
		dispatch({
			isAnimating: true,
			scale: getScale()
		});

		await sleep(state.duration);
		dispatch({
			isActive: true,
			isAnimating: false
		});
	} else {
		dispatch({
			scale: getScale(),
			height: getDrawerHeight()
		});

		await skipFrame();
		dispatch({
			isActive: false,
			isClosing: true
		});

		await skipFrame();
		dispatch({
			isAnimating: true,
			scale: 1
		});

		await sleep(state.duration);
		dispatch({
			isOpen: false,
			isAnimating: false,
			height: null
		});
	}
};

const resolveRow = (candidate: RowPacket | Row | null): Row | null => {
	if (!candidate)
		return null;

	if (candidate.isRowPacket && candidate.row)
		return candidate.row;

	return candidate;
};

const resolveColumns = (
	columns: ColumnCell[],
	rowBackgrounds: BackgroundMap = {}
): PatchedColumnCell[] => {
	const filteredColumns = columns.filter(col => col.type !== "hidden");
	let idx = 0;

	return columns.map(column => {
		const template = column.type ?
				COLUMNS[column.type] || COLUMNS.default :
				COLUMNS.default,
			outColumn = {
				...template,
				...column,
				patched: true
			} as PatchedColumnCell;

		outColumn.index = typeof outColumn.index == "string" ?
			INDEXERS[outColumn.index as IndexName] || INDEXERS.default :
			outColumn.index || INDEXERS.default;

		outColumn.sortModes = outColumn.sortModes || DEFAULT_SORT_MODES;

		if (column.type !== "hidden") {
			outColumn.location = {
				index: idx,
				length: filteredColumns.length,
				first: !idx,
				last: idx === filteredColumns.length - 1
			};

			idx++;
		} else {
			outColumn.location = {
				index: -1,
				length: filteredColumns.length,
				first: false,
				last: false
			};
		}

		outColumn.backgrounds = {
			even: resolveBackground(
				column.backgrounds,
				"even",
				rowBackgrounds.even,
				true
			),
			odd: resolveBackground(
				column.backgrounds,
				"odd",
				rowBackgrounds.odd,
				true
			)
		};

		return outColumn;
	});
};

const resolveBackground = (
	data: Background | undefined,
	key: "even" | "odd",
	def: any,
	ignoreDuplicate?: boolean
) => {
	if (!data) {
		return data === null ?
			null :
			def;
	}

	if (typeof data == "string") {
		return data === "transparent" || (ignoreDuplicate && data === def) ?
			null :
			data;
	}

	if (typeof data == "function")
		return data;

	if (hasOwn(data, key))
		return (data as BackgroundMap)[key];

	return def;
};

const wrapBackground = (
	source: Background | undefined,
	key: "even" | "odd",
	defaults: BackgroundSourceMap
): Background => {
	const resolved = resolveBackground(source, key, null);

	if (!defaults[key]) {
		if (!source)
			return null;
		if (typeof source == "string")
			return source;

		return (source && ((source as any)[key])) || null;
	}

	if (!resolved)
		return defaults[key] as Background;

	return p => {
		if (typeof resolved == "string")
			return resolved;

		if (typeof resolved == "function") {
			const override = resolved(p);
			if (override)
				return override;
		}

		return (defaults[key] as BackgroundResolver)(p);
	};
};

const mkEditEvent: EditEventMaker = (
	props: CellProps,
	value: any,
	oldValue: any,
	config: EditEventConfig = {}
): EditEvent => {
	const row = config.row || props.row,
		column = config.column || props.column,
		newRow = config.newRow || clone(row.row, "circular");
	let editResult: RowEditResult | null = null;

	const resolveSetArgs = (
		targetNewRowOrIndex: any[] | any | number,
		newRowOrIndex: any | number,
		newR: any
	) => {
		let target = targetNewRowOrIndex,
			idx = newRowOrIndex,
			r = newR;

		if (!Array.isArray(target)) {
			r = idx;
			idx = target;
			target = props.processOptions.get().props.rows;
		}

		if (typeof targetNewRowOrIndex == "number") {
			r = target[targetNewRowOrIndex];
			idx = targetNewRowOrIndex;
		} else if (!r) {
			r = idx;
			idx = row.index;
		}

		if (typeof idx != "number")
			idx = row.index;
		if (!r)
			r = newRow;

		return {
			target,
			index: idx,
			row: r
		};
	};

	const set = (
		targetNewRowOrIndex: any[] | any | number,
		newRowOrIndex: any | number,
		newR: any
	): RowEditResult => {
		const args = resolveSetArgs(targetNewRowOrIndex, newRowOrIndex, newR);
		let newRows;

		if (args.index < 0) {
			newRows = Array
				.from({ length: -args.index })
				.concat(args.target);
			args.index = 0;
		} else
			newRows = args.target.slice()

		newRows[args.index] = args.row;
		editResult = {
			newRows,
			newRow: args.row,
			queuedRows: [args.row]
		} as RowEditResult;

		return editResult;
	};

	const spread = (callback: SpreadCallback): RowEditResult => {
		const po = props.processOptions.get(),
			rt = po.runtime!,
			getRowData = po.patchedProps.getRowData,
			newRows = [] as any[],
			queuedRows = [] as any[];

		rt.allRows.forEach((row, idx) => {
			newRows.push(getRowData(row));

			const s = (value: any, col: ColumnCell = column) => {
				const cProps = {
					row: row,
					column: col,
					processOptions: props.processOptions
				} as CellProps;

				const result = setCellValue(
					cProps,
					value,
					evt.rowPacket === row ?
						evt.oldValue :
						row.values[column.name],
					"defer"
				);

				newRows[idx] = result.newRow;
				queuedRows.push(result.newRow);
			};

			callback(s, row, idx, rt.allRows);
		});

		editResult = {
			newRows,
			newRow: null,
			queuedRows
		} as RowEditResult;

		return editResult;
	};

	const evt = {
		mode: "synthetic",
		row: newRow,
		rowPacket: row,
		column: props.column,
		index: row.index,
		value,
		oldValue,
		set,
		push: (target, newRow) => evt.set(target, target?.length, newRow),
		unshift: (target, newRow) => evt.set(target, -1, newRow),
		spread,
		resolveSetArgs,
		report: () => editResult,
		runtime: props.processOptions.get().runtime,
		wrapped: false,
		originalEvent: null
	} as EditEvent;

	return evt;
};

const wrapEditEventMaker = (
	wrapper: (evt: EditEvent) => EditEvent
): EditEventMaker => {
	return (
		props: CellProps,
		value: any,
		oldValue: any,
		config: EditEventConfig = {}
	) => {
		const evt = mkEditEvent(props, value, oldValue, config),
			cloned = {
				...evt
			};

		const wrapped = wrapper(cloned);
		wrapped.wrapped = true;
		wrapped.originalEvent = evt;

		return wrapped;
	};
};

const mkRowPacketGetter = (
	props: Runtime,
	getter?: InternalRowPacketGetter
): ExternalRowPacketGetter => {
	if (typeof getter != "function") {
		return (row: any) => {
			return props.allRows.find(r => {
				return r.row === row;
			}) || null;
		};
	}

	return (row: any) => getter(props, row);
};

// ====== Main component ======
const CoreDataTable = (props: ThemedDataTableProps) => {
	const drawerRef = useRef();
	const containerRef = useRef();
	const [currentRow, setCurrentRow] = useState(null as any);
	const [forcedOpen, setForcedOpen] = useState(false);
	const [drawerState, setDrawerState] = useState({
		scale: 1,
		height: null,
		duration: 300,
		isOpen: false,
		isActive: false,
		isClosing: false,
		isAnimating: false
	} as DrawerState);
	const [lastState, setLastState] = useState({
		rows: props.rows,
		columns: props.columns,
		cacheKey: props.cacheKey,
		boxedRows: [] as RowPacket[]
	});
	const [processOptions, setProcessOptions] = useState({
		props,
		patchedProps: {
			...props,
			columns: [],
			filteredColumns: [],
			rowBackgrounds: {},
			mkEditEvent,
			getRowPacket: () => null,
			patched: true
		},
		sortIndex: -1,
		sortModes: [],
		sortModeIndex: 0,
		filters: {},
		filterKey: null,
		filterKeys: [],
		opener: (row?: RowPacket | any) => {
			const r = resolveRow(row);

			if (!r)
				setForcedOpen(true);
			else
				setCurrentRow(r);
		},
		closer: () => {
			setForcedOpen(false);
			setCurrentRow(null);
		},
		drawerOpener: () => {},
		drawerCloser: () => {},
		runtime: null
	} as ProcessOptions);
	const [tickObservers, setTickObservers] = useState([] as TickObserver[]);

	const hashedProcessOptions = hashProcessOptions(processOptions);

	const patchedPo = {
		...processOptions,
		props,
		drawerOpener: () => {
			triggerDrawer(
				true,
				drawerState,
				setDrawerState,
				drawerRef,
				containerRef
			);
		},
		drawerCloser: () => {
			triggerDrawer(
				false,
				drawerState,
				setDrawerState,
				drawerRef,
				containerRef
			);
		}
	} as ProcessOptions;

	const poCapsule = useStateCapsule(patchedPo, true);
	poCapsule.set(patchedPo);

	const rowBackgrounds = useMemo(
		() => {
			return {
				even: resolveBackground(
					props.rowBackgrounds,
					"even",
					props.theme.tableRowBackground
				),
				odd: resolveBackground(
					props.rowBackgrounds,
					"odd",
					null
				)
			};
		},
		[props.rowBackgrounds, props.columns]
	);

	const columns = useMemo(
		() => resolveColumns(props.columns, rowBackgrounds),
		[props.columns, rowBackgrounds]
	);

	const filteredColumns = useMemo(
		() => columns.filter(column => column.type !== "hidden"),
		[columns]
	);

	const columnsMap = useMemo(
		() => {
			const map = {} as ColumnsMap;

			for (const column of columns)
				map[column.name] = column;

			return map;
		},
		[columns]
	);

	const patchedProps = {
		...props,
		columns,
		filteredColumns,
		rowBackgrounds,
		mkEditEvent: typeof props.mkEditEvent == "function" ?
			props.mkEditEvent :
			mkEditEvent,
		getRowData: typeof props.getRowData == "function" ?
			props.getRowData :
			(row: RowPacket) => row.row,
		getRowPacket: () => null,
		patched: true
	} as PatchedDataTableProps;

	const boxedRows = useMemo(
		() => {
			if (props.cacheKey !== lastState.cacheKey || columns !== lastState.columns)
				return mkRows(patchedProps, poCapsule);

			return diffRows(
				props.rows,
				lastState.boxedRows,
				patchedProps,
				poCapsule
			);
		},
		[props.rows, columns]
	);

	const processedRows = useMemo(
		() => filterAndSort(patchedPo, boxedRows),
		[
			props.rows,
			columns,
			hashedProcessOptions
		]
	);

	const outRows = useMemo(
		() => {
			const win = props.window,
				winOffset = props.windowOffset || 0;

			if (typeof win == "number")
				return processedRows.slice(winOffset, winOffset + win);

			return processedRows.slice(winOffset);
		},
		[
			props.window,
			props.windowOffset,
			processedRows
		]
	);

	const currentRowIndex = useMemo(
		() => {
			return currentRow ?
				processedRows.findIndex(r => r.row === currentRow) :
				-1;
		},
		[currentRow, processedRows]
	);

	const runtime: Runtime = {
		row: currentRow,
		currentRow: processedRows[currentRowIndex] || null,
		previousRow: processedRows[currentRowIndex - 1] || null,
		nextRow: processedRows[currentRowIndex + 1] || null,
		rows: outRows,
		filteredRows: processedRows,
		allRows: boxedRows,
		columns,
		columnsMap,
		filteredColumns,
		index: currentRowIndex,
		isOpen: Boolean(currentRow) || forcedOpen,
		isActive: false,
		isDrawerOpen: drawerState.isOpen,
		isDrawerActive: drawerState.isOpen,
		open: patchedPo.opener,
		close: patchedPo.closer,
		openDrawer: patchedPo.drawerOpener,
		closeDrawer: patchedPo.drawerCloser,
		store: props.store || {},
		processOptions: poCapsule,
		nextTick: observer => {
			setTickObservers([
				...tickObservers,
				observer
			]);
		},
		getRowPacket: () => null
	};
	patchedPo.runtime = runtime;
	patchedPo.patchedProps = patchedProps;

	patchedProps.getRowPacket = mkRowPacketGetter(
		runtime,
		props.getRowPacket
	);
	runtime.getRowPacket = patchedProps.getRowPacket;

	const rowsDiff = props.rows !== lastState.rows,
		columnsDiff = columns !== lastState.columns,
		cacheDiff = props.cacheKey !== lastState.cacheKey,
		boxedDiff = boxedRows !== lastState.boxedRows;

	if (rowsDiff || columnsDiff || cacheDiff || boxedDiff) {
		setLastState({
			rows: props.rows,
			columns,
			cacheKey: props.cacheKey,
			boxedRows
		});
	}

	const headings = useMemo(
		() => mkHeadings(patchedProps, runtime, patchedPo, po => {
			setProcessOptions({
				...patchedPo,
				...po
			});
		}),
		[
			props.rows,
			props.store,
			columns,
			hashedProcessOptions
		]
	);

	const table = buildTable(
		patchedProps,
		headings,
		outRows
	);

	const activeTerms = patchedPo.filterKey && patchedPo.filters[patchedPo.filterKey],
		activeColumn = columns.find(c => c.name === patchedPo.filterKey);
	let filterBox = null;

	if (activeTerms && activeColumn) {
		const handleFilterChange = (terms: FilterTerm[]): void => {
			const keyIndex = patchedPo.filterKeys.indexOf(activeColumn.name);
			let filterKeys = patchedPo.filterKeys;

			applyFilterToAll(terms, boxedRows, activeColumn);

			// keyIndex should be unnecessary in this statement, but added
			// for the sake of ensuring odd splices will never occur
			if (!isFiltering(terms) && keyIndex > -1) {
				filterKeys = filterKeys.slice();
				filterKeys.splice(keyIndex);
			} else if (keyIndex === -1)
				filterKeys = filterKeys.concat(activeColumn.name);

			if (props.onFilter) {
				requestAnimationFrame(() => {
					props.onFilter!({
						column: activeColumn,
						terms,
						runtime: poCapsule.get().runtime
					} as FilterEvent);
				});
			}

			setProcessOptions({
				...patchedPo,
				filters: {
					...patchedPo.filters,
					[activeColumn.name]: terms
				},
				filterKeys
			});
		};

		filterBox = (
			<FilterBox
				column={activeColumn}
				terms={activeTerms}
				widget={props.filterWidget}
				runtime={runtime}
				onChange={handleFilterChange}
				compact={props.compact}
			/>
		);
	}

	const headerContent = props.header ?
		<props.header {...runtime} /> :
		null;

	useEffect(
		() => {
			if (!tickObservers.length)
				return;

			for (const observer of tickObservers)
				observer(runtime);

			setTickObservers([]);
		},
		[tickObservers.length]
	);

	return (
		<Wrapper expand={props.expand}>
			<Header
				className="header"
				compact={props.compact}
			>
				{headerContent}
				{filterBox}
				<ModalHeader
					content={props.modalHeader}
					runtime={runtime}
					drawerState={drawerState}
				/>
			</Header>
			<DrawerWrapper
				ref={drawerRef}
				compact={props.compact}
				drawerState={drawerState}
			>
				<Drawer
					drawerProps={runtime}
					compact={props.compact}
					drawerState={drawerState}
					content={props.drawer}
				/>
			</DrawerWrapper>
			<TableContainer ref={containerRef}>
				<TableContent
					compact={props.compact}
					drawerState={drawerState}
				>
					<TableWrapper
						compact={props.compact}
						drawerState={drawerState}
						obscureScrollBar={props.obscureScrollBar}
					>
						{table}
					</TableWrapper>
					<TableModal
						content={props.modalContent}
						runtime={runtime}
						compact={props.compact}
						drawerState={drawerState}
					/>
				</TableContent>
				<LoadingOverlay loadingState={props.loadingState} />
			</TableContainer>
		</Wrapper>
	);
};

const DataTable = withTheme(CoreDataTable);

export default DataTable;

export {
	getInput,
	getFilterInput,
	getCellContent,
	resolveColumns,
	resolveBackground,
	wrapBackground,
	mkEditEvent,
	wrapEditEventMaker,
	mkRowPacketGetter
};
