import { request, resolveOfficeKey } from "../../utils";

import useStats from "../../hooks/use-stats";
import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";
import useGlobalActionColumns from "../../hooks/use-global-action-columns";

import states from "../../data/states.json";

import Base from "./base";
import ActionTable  from "../../components/action-table";
import ContentCard from "../../components/content-card";

import {
	CellProps,
	ColumnCell
} from "../../types/data-table";
import { ViewRuntime } from "../../types/hierarchical";
import { PRESIDENTIAL } from "../../data/constants";

const modeColumns: ColumnCell[] = PRESIDENTIAL ? [] : [{
	name: "state",
	type: "choice",
	title: "State",
	accessor: "jurisdiction",
	index: "lexical",
	config: {
		options: states,
		labelAccessor: "state",
		valueAccessor: "state"
	}
}]

const COLUMNS: ColumnCell[] = [
	...modeColumns,
	{
		name: "name",
		title: "Name",
		accessor: "race_name",
		index: "lexical"
	},
	{
		name: "office",
		title: "Office",
		accessor: "office.full_name",
		index: "lexical"
	}
];

const Races = (props: ViewRuntime) => {
	const stats = useStats({
		request: {
			url: "metrics/overview",
			cacheHash: props.state.races.searchHash
		},
		entries: [
			{ accessor: "active_races", label: "Active Races" },
			{ accessor: "states", label: "States" },
			{ accessor: "active_campaigns", label: "Active Campaigns" },
			{ accessor: "radio_stations", label: "Radio Stations" },
			{ accessor: "tv_stations", label: "TV Stations" },
			{ accessor: "markets", label: "Markets" }
		]
	});

	const columns = useGlobalActionColumns(
		COLUMNS,
		(p: CellProps) => {
			const d = p.row.row.data;

			props.setState("race", {
				id: d.id,
				type: d.race_type,
				stateKey: d.jurisdiction?.toLowerCase() || "us",
				office: d.office.id,
				officeKey: resolveOfficeKey(d.office.short_name),
				district: d.district,
				name: d.race_name,
				cycle: d.year,
				partyId: d.party?.id,
				state: d.jurisdiction,
				email_lists: d.email_lists
			});

			props.navigate("race");
		}
	);

	const actions = useTableActions({
		deleteEach: row => {
			return request({
				method: "DELETE",
				url: `/races/${row.id}`
			});
		}
	});

	const p = useRemoteActionProps({
		request: {
			url: "/races/list",
			cacheHash: props.state.races.searchHash
		},
		columns: columns,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base stats={stats}>
			<ContentCard>
				<ActionTable {...p} />
			</ContentCard>
		</Base>
	);
};

export default Races;
