import { useState } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import Icon from "../icon";

import { InputProps } from "../../types/inputs";
import states from "../../data/states.json";

interface MultiProps extends InputProps {
	value: string[];
	readOnly?: boolean;
}

interface EntryProps {
	onDelete: () => void;
	readOnly?: boolean;
	children?: any;
}

interface MultiDropProps {
	dropProps: DropProps;
}

interface DropProps {
	selection: string[];
	add: (value: string) => void;
	delete: (value: string) => void;
	name: string;
}

const MultiWrapper = styled.div`
	position: relative;
`;

const MultiContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	max-height: 100px;
	background: ${p => p.theme.cardBackground};
	border: ${p => p.theme.inputBorder};
	border-radius: ${p => p.theme.borderRadius};
	overflow: auto;
	padding: 5px;
	gap: 5px;
`;

const EntryWrapper = styled.div`
	display: flex;
	align-items: center;
	max-width: 50%;
	height: 28px;
	padding: 3px 8px;
	background: ${p => p.theme.darkBackground};
	color: ${p => p.theme.subtle};
	border-radius: ${p => p.theme.borderRadius};
`;

const EntryContent = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const EntryDeleteButton = styled.button`
	flex-shrink: 0;
	padding: 0 5px;
	width: 20px;
	height: 20px;
	border: none;
	outline: none;
	margin: 0 -3px 0 5px;
	background: transparent;
	color: inherit;
	cursor: pointer;
`;

const Entry = (props: EntryProps) => {
	const deleteButton = !props.readOnly ? (
		<EntryDeleteButton type="button" onClick={props.onDelete}>
			<Icon name="thin-cross" />
		</EntryDeleteButton>
	) : null;

	return (
		<EntryWrapper>
			<EntryContent>{props.children}</EntryContent>
			{deleteButton}
		</EntryWrapper>
	);
};

const StyledInput = styled.input`
	width: ${props => props.width || "unset"};
`

const regexEmail = /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+/;

const MultiDrop = (props: MultiDropProps) => {
	const [value, setValue] = useState("");
	const isEmail = props.dropProps.name.includes("email");
	const isState = props.dropProps.name.includes("state");

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (
			value.length > 0 &&
			(e.key === "Enter" ||
				e.key === "Tab" ||
				e.key === " " ||
				e.key === ",")
		) {
			if (isEmail) {
				const matches = value.match(regexEmail);
				if (matches && matches.length > 0) {
					props.dropProps.add(matches[0]);
					setValue("");
				}
			} else if (isState) {
				if (states.some(({state}) => state === value)) {
					props.dropProps.add(value);
					setValue("");
				}
			} else {
				props.dropProps.add(value);
				setValue("");
			}
			e.preventDefault();
			e.stopPropagation();
		}
	};

	return (
		<StyledInput
			type="text"
			value={value}
			onChange={e => {
				setValue(e.target.value);
			}}
			onKeyDown={handleKeyDown}
			width={isState ? "4rem" : "unset"}
		/>
	);
};

type MultiInputFields = {
	[key: string]: string[]
}

const MultiInput = (props: MultiProps) => {
	const ctx = useFormikContext<MultiInputFields>();

	const name = props.name!,
		value = (ctx.values as any)[props.name!] as string[];

	const dispatchDelete = (index: number) => {
		const entries = value.slice();
		entries.splice(index, 1);
		ctx.setFieldValue(name, entries);
	};

	const entries = value.map((v, i) => (
		<Entry
			key={v}
			readOnly={props.readOnly}
			onDelete={() => dispatchDelete(i)}
		>
			{v}
		</Entry>
	));

	const dropProps = {
		selection: value,
		add: (v: string) => {
			const values = ctx.values[name]
			if (!values.includes(v)) {
				ctx.setFieldValue(name, [...value, v]);
			}
		},
		delete: (v: string) => {
			const idx = value.indexOf(v);
			if (idx === -1) return;

			const entries = value.slice();
			entries.splice(idx, 1);
			ctx.setFieldValue(name, entries);
		},
		name
	};

	return (
		<MultiWrapper onClick={evt => evt.stopPropagation()}>
			<MultiContent>
				{entries}
				<MultiDrop dropProps={dropProps} />
			</MultiContent>
		</MultiWrapper>
	);
};

export default MultiInput;
