import { useState } from "react";

import useToast from "../../hooks/use-toast";
import useRootEffect from "../../hooks/use-root-effect";
import useActionProps from "../../hooks/use-action-props";
import useLoadingState from "../../hooks/use-loading-state";
import useTableActions from "../../hooks/use-table-actions";

import { request } from "../../utils";

import { MEDIA_TYPES, PRESIDENTIAL } from "../../data/constants";
import states from "../../data/states.json";

import Base from "./base";

import ActionTable from "../../components/action-table";
import ContentCard from "../../components/content-card";

import { ColumnCell } from "../../types/data-table";
import { ViewRuntime} from "../../types/hierarchical";
import { Button, Checkbox, Form, InputBox, MultiInput } from "../../components/inputs";
import styled from "styled-components";
import { useAppSelector } from "../../state/hooks";


const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
`;

const CheckboxLabel = styled.div`
	cursor: pointer;
	font-size: 105%;
	font-weight: bold;
	padding: 5px 10px;
	user-select: none;
`;

const CheckboxWrapper = styled.div`
	align-items: center;
	display: flex;
	padding-right: 1rem;
	> * + * {
		padding-left: 0.5rem;
	}
`

const GrowingInputBox = styled(InputBox)`
	align-items: center;
	flex-direction: row;
	flex-grow: 1;
	gap: 1rem;
`;

const upload = (files: File[], template: string, raceId: string, isDra: string, pac: string): Promise<any> => {
	const formData = new FormData();

	files.forEach((file, idx) => {
		formData.append(`file-${idx + 1}`, file);
	});

	formData.append("template", template);
	formData.append("race_id", raceId)
	formData.append("is_dra", isDra)
	formData.append("pac", pac)

	return request({
		url: "/flights/process",
		body: formData,
	});
};


function flightsAreEqual(a: any, b: any): boolean {
	function extractFlightInfoForEquality(flight: any): any {
		return {
			amount: flight.amount,
			callLetters: flight.call_letters || flight.station?.call_letters,
			campaignId: flight.campaign?.id,
			cpp: flight.cpp || flight.candidate_cpp || 0,
			endDate: flight.end_date,
			marketId: flight.market_id || flight.station?.market_id,
			spotLength: flight.spot_length,
			startDate: flight.start_date,
			stationType: flight.station_type || flight.station?.station_type
		}
	}

	const flightA = extractFlightInfoForEquality(a)
	const flightB = extractFlightInfoForEquality(b)
	const aKeys = Object.keys(flightA)
	const bKeys = Object.keys(flightB)

	if (aKeys.length !== bKeys.length) return false
	for (const key of aKeys) {
		if (flightA[key] !== flightB[key]) return false
	}
	return true
}

function flightsAreSimilar(a: any, b: any): boolean {
	function extractFlightInfoForEquality(flight: any): any {
		return {
			callLetters: flight.call_letters || flight.station?.call_letters,
			campaignId: flight.campaign?.id,
			cpp: flight.cpp || flight.candidate_cpp || 0,
			endDate: flight.end_date,
			marketId: flight.market_id || flight.station?.market_id,
			spotLength: flight.spot_length,
			startDate: flight.start_date,
			stationType: flight.station_type || flight.station?.station_type
		}
	}

	const flightA = extractFlightInfoForEquality(a)
	const flightB = extractFlightInfoForEquality(b)
	const aKeys = Object.keys(flightA)
	const bKeys = Object.keys(flightB)

	if (aKeys.length !== bKeys.length) return false
	for (const key of aKeys) {
		if (flightA[key] !== flightB[key]) return false
	}
	return a.amount !== b.amount;
}

type StatesFormProps = {
	states: string[],
	onChange: (values: any) => void
}

const StatesForm = (props: StatesFormProps) => {
	const handleChange = ({states}: {states: string[]}) => {
		props.onChange(states);
	}

	const values: any = {states: props.states};

	return <Form initialValues={values} onSubmit={() => {}} values={values} onChange={handleChange}>
		<GrowingInputBox
			name="states"
			title="States"
			input={MultiInput}
			key="states-form-input"
		/>
	</Form>

}

const modeColumns: ColumnCell[] = PRESIDENTIAL ? [] : [
	{
		name: "state",
		type: "choice",
		title: "State",
		accessor: "race.state",
		index: "lexical",
		fit: "shrink",
		config: {
			options: states,
			labelAccessor: "state",
			valueAccessor: "state"
		}
	}
]

const Flights = (props: ViewRuntime) => {
	const toast = useToast();
	const markets = useAppSelector(state => state.data.markets);
	const COLUMNS: ColumnCell[] = [
		...modeColumns,
		{
			name: "id",
			title: "Campaign ID",
			accessor: "campaign.id"
		},
		{
			name: "name",
			title: "Campaign Name",
			accessor: "campaign.name",
			index: "lexical"
		},
		{
			name: "medium",
			type: "choice",
			title: "Medium",
			accessor: "station_type",
			editable: true,
			config: {
				options: MEDIA_TYPES,
				labelAccessor: "label",
				valueAccessor: "value"
			}
		},
		{
			name: "callLetters",
			title: "Call Letters",
			accessor: "call_letters",
			editable: true
		},
		{
			name: "market",
			type: "choice",
			title: "Market",
			accessor: "market_id",
			fit: "shrink",
			config: {
				options: markets,
				labelAccessor: "name",
				valueAccessor: "id"
			},
			editable: true
		},
		{
			name: "amount",
			type: "currency",
			title: "Amount",
			accessor: "amount",
			editable: true
		},
		{
			name: "start",
			type: "date",
			title: "Start Date",
			accessor: "start_date",
			editable: true
		},
		{
			name: "end",
			type: "date",
			title: "End Date",
			accessor: "end_date",
			editable: true
		},
		{
			name: "length",
			type: "number",
			title: "Spot Length",
			accessor: "spot_length",
			editable: true
		},
		{
			name: "cpp",
			type: "currency",
			title: "CPP",
			accessor: "cpp",
			editable: true
		}
	];

	const [checkingForDuplicates, setCheckingForDuplicates] = useState(false)
	const [checkingForUpdates, setCheckingForUpdates] = useState(false)
	const [duplicateFlightIndexes, setDuplicateFlightIndexes] = useState<any[]>()
	const [updatedFlightIndexes, setUpdatedFlightIndexes] = useState<any[]>()
	const [rows, setRows] = useState([] as any[]);
	const [loadingState, updateLoadingState] = useLoadingState(
		props.state.flights.upload ?
			"loading" :
			"success"
	);
	const [presidentialStates, setPresidentialStates] = useState<string[]>([]);

	const checkForDuplicates = () => {
		setCheckingForDuplicates(true)
		request({
			query: {
				campaign_id: props.state.campaign.id
			},
			url: "flights/list"
		}).then(({data}) => {
			if (!Array.isArray(data) || !data.length) return
			const duplicateIndexes = rows.reduce((dups, row, rowIndex) => {
				if(data.some(flight => flightsAreEqual(flight, row))) {
					return [...dups, rowIndex]
				}
				return dups
			}, [])
			setDuplicateFlightIndexes(duplicateIndexes)
		}).finally(() => {
			setCheckingForDuplicates(false)
		})
	}

	const checkForUpdates = () => {
		setCheckingForUpdates(true)
		request({
			query: {
				campaign_id: props.state.campaign.id
			},
			url: "flights/list"
		}).then(({data}) => {
			if (!Array.isArray(data) || !data.length) return
			const updatedIndexes = rows.reduce((updates, row, rowIndex) => {
				if (data.some(flight => flightsAreSimilar(flight, row))) {
					return [...updates, rowIndex]
				}
				return updates;
			}, [])
			setUpdatedFlightIndexes(updatedIndexes)
		}).finally(() => {
			setCheckingForUpdates(false)
		})
	}

	const actions = useTableActions({
		addLabel: "Add Flight",
		add: () => ({
			cpp: 0,
			call_letters: "",
			market_id: "",
			station_type: MEDIA_TYPES[0].value,
			amount: 0,
			start_date: new Date().toISOString(),
			end_date: new Date().toISOString(),
			spot_length: 30,
			race: props.state.race,
			campaign: props.state.campaign
		}),
		delete: () => true,
		update: setRows
	});

	const toggleIsDra = () => props.setState("flights.isDra", !props.state.flights.isDra)

	const p = useActionProps({
		rows,
		columns: COLUMNS,
		pageSize: 50,
		actions,
		loadingState,
		spreadEdit: true,
		expand: true,
		header: () => {
			return (
				<ButtonWrapper>
					{PRESIDENTIAL && <StatesForm states={presidentialStates} onChange={setPresidentialStates} />}
					<CheckboxWrapper>
						<Checkbox checked={!!props.state.flights.isDra} onChange={toggleIsDra} />
						<CheckboxLabel onClick={toggleIsDra}>Is DRA</CheckboxLabel>
					</CheckboxWrapper>
					<Button disabled={checkingForDuplicates} onClick={checkForDuplicates}>Check Duplicates</Button>
					<Button disabled={checkingForUpdates} onClick={checkForUpdates}>Check Updates</Button>
				</ButtonWrapper>
			);
		},
		duplicateIndexes: duplicateFlightIndexes,
		updatedIndexes: updatedFlightIndexes,
	});

	props.hook("save", async runtime => {
		const res = await request({
			url: "/flights/create",
			body: {
				campaign_id: runtime.state.campaign.id,
				rows: normalizeRows(p.rows),
				is_dra: props.state.flights.isDra,
				race_id: props.state.race.id,
				states: presidentialStates,
			}
		});

		if (res.success) {
			toast("Saved flights");
			props.setState("flights.files", []);
			props.updateState("campaign.searchHash", v => v + 1);
		} else
			toast.error(res.errorMessage!);

		return res.success;
	});

	const injectStateData = (rs: any[]): any[] => {
		return rs.map(row => ({
			...row,
			race: props.state.race,
			campaign: props.state.campaign
		}));
	};

	const normalizeRows = (rs: any[]): any[] => {
		return rs.map(row => ({
			state: row.race.state,
			campaign_id: row.campaign.id,
			campaign_name: row.campaign.name,
			station_type: row.station_type,
			call_letters: row.call_letters,
			market_id: row.market_id,
			amount: row.amount,
			start_date: row.start_date,
			end_date: row.end_date,
			spot_length: row.spot_length,
			cpp: row.cpp
		}));
	};

	useRootEffect(async () => {
		if (!props.state.flights.upload)
			return;

		const result = await upload(
			props.state.flights.files,
			props.state.flights.template,
			props.state.race.id.toString(),
			props.state.flights.isDra ? "true": "false",
			props.state.campaign.candidate_type === "pac" ? "true" : "false"
		);

		if (result.success) {
			updateLoadingState("success");
			setRows(injectStateData(result.data.flat()));
			toast("Processed flight data");
		} else {
			updateLoadingState("error", result.errorMessage!);
			toast.error(result.errorMessage!);
		}
	}, []);

	return (
		<Base>
			<ContentCard>
				<ActionTable {...p} />
			</ContentCard>
		</Base>
	);
};

export default Flights;