import { useEffect, useMemo, useState } from "react";
import { withTheme } from "styled-components";

import { equals } from "@qtxr/utils";

import { request } from "../../utils";
import useGraphConfigCollection, {
	GraphConfigCollection
} from "../../hooks/use-graph-config-collection";
import useQuerySettings from "../../hooks/use-query-settings";

import Base from "./base";

import { ControlUnion, DateRangeRuntime } from "../../types/control-bar";
import { QuerySettings } from "../../types/reports";
import {
	ValidationPayload,
	WaterfallEntries
} from "../../components/waterfall";
import { CANDIDATE_TYPES, PRESIDENTIAL } from "../../data/constants";
import CmagStandalone from "./cmag-standalone";
import { downloadPdf } from "../../utils/download-pdf";

interface CmagProps {
	theme: any;
}

interface CmagData {
	candidate: string;
	party: string;
	spots: number;
	spotCost: number;
	runs: CmagRun[];
	creatives: CmagCreative[];
	dayParts: CmagDayPart[];
}

interface CmagRun {
	spots: number;
	price: number;
}

interface CmagCreative {
	name: string;
	runs: number;
	percentage: number;
}

interface CmagDayPart {
	name: string;
	spots: number;
	percentage: number;
}

interface GraphsProps {
	querySettings: QuerySettings;
	collection: GraphConfigCollection;
	cmagData: CmagData[];
	theme: any;
}

interface RaceEntry {
	race_id: number;
	race_name: string;
	year: number;
	campaign_count: number;
	race_jurisdiction: string;
}

const graphs = [
	{ data: "digest", key: "digest", title: "CMAG Digest" },
	{ data: "dayPartShare", key: "dayPartShare", title: "Day Part Share" },
	{ data: "mediumUsdSpend", key: "mediumUsdSpend", title: "Tone" },
	{
		data: "airtime",
		key: "airtime",
		title: "Share of Voice"
	},
	{ data: "swingRatings", key: "swingRatings", title: "Swing Ratings" },
	{ data: "gopRatings", key: "gopRatings", title: "GOP Primary Ratings" },
	{ data: "rawData", key: "rawData", title: "Raw Data" }
];

function validateTeamOrCampaignSelected({ selection }: ValidationPayload) {
	const teamsSelection = selection.find(s => s.name === "teams");
	const campaignsSelection = selection.find(s => s.name === "campaigns");
	return !!teamsSelection?.selection.length !==
		!!campaignsSelection?.selection.length
		? null
		: "Select at least one campaign OR at least one team";
}

const statesWaterfall = PRESIDENTIAL
	? ([
			{
				name: "states",
				title: "States",
				mode: "multi",
				shouldFetch: ({ state, searching }) => {
					return (
						state.raceId >= 0 &&
						(searching ||
							state.raceId !==
								state.activeRaceId)
					);
				},
				fetch: async ({ state }) => {
					const res = await request({
						url: `/races/${state.raceId}/states`
					});

					if (!res.success)
						return "Failed to load states";

					return (res.data as string[]).map(
						state => ({
							title: state,
							key: state,
							data: state
						})
					);
				},
				placeholder:
					"Select a race before selecting states"
			}
	  ] as WaterfallEntries)
	: ([] as WaterfallEntries);

const WATERFALL = [
	{
		name: "race",
		title: "Race",
		mode: "radio",
		fetch: async ({ query }) => {
			const res = await request({
				url: "/races/search",
				query: {
					query
				}
			});

			if (!res.success) return "Failed to find races";

			return (res.data as RaceEntry[]).map(entry => ({
				title: entry.race_name,
				details: [
					`${entry.campaign_count} ${
						entry.campaign_count === 1
							? "campaign"
							: "campaigns"
					}`,
					entry.year
				],
				key: entry.race_id,
				data: entry
			}));
		},
		onSelectionChange: (sel, { setState }) => {
			setState("raceId", sel[0].data.race_id);
			if (sel[0].data.race_jurisdiction) {
				setState(
					"state",
					sel[0].data.race_jurisdiction
				);
			}
		},
		validate: ({ entry }) =>
			entry.selection.length ? null : "Select a race",
		compact: true
	},
	...statesWaterfall,
	{
		name: "campaigns",
		title: "Campaigns",
		mode: "multi",
		shouldFetch: ({ state, searching }) => {
			return (
				state.raceId >= 0 &&
				(searching ||
					state.raceId !== state.activeRaceId)
			);
		},
		shouldTrash: ({ state }) => {
			return (
				state.raceId >= 0 &&
				state.raceId !== state.activeRaceId
			);
		},
		fetch: async ({ query, state, setState }) => {
			setState("activeRaceId", state.raceId);

			const res = await request({
				url: "/campaigns/search",
				query: {
					query,
					race_id: state.raceId,
					admo: true
				}
			});

			if (!res.success) return "Failed to find campaigns";

			return (res.data as any[]).map(entry => ({
				title: entry.candidate.full_name,
				details: [
					entry.party,
					CANDIDATE_TYPES.find(
						c =>
							c.value ===
							entry.candidate
								.candidate_type
					)?.label || ""
				],
				key: entry.id,
				data: entry
			}));
		},
		placeholder: "Select a race before selecting campaigns",
		validate: validateTeamOrCampaignSelected,
		compact: true
	},
	{
		name: "teams",
		title: "Teams",
		mode: "multi",
		shouldFetch: ({ state, searching }) => {
			return (
				state.raceId >= 0 &&
				(searching ||
					state.raceId !== state.activeRaceId)
			);
		},
		shouldTrash: ({ state }) => {
			return (
				state.raceId >= 0 &&
				state.raceId !== state.activeRaceId
			);
		},
		fetch: async ({ state }) => {
			const res = await request({
				url: "/teams/list",
				query: {
					race_id: state.raceId
				}
			});

			if (!res.success) return "Failed to find Teams";

			return (res.data as any[]).map(entry => ({
				title: entry.full_name,
				details: [entry.keywords],
				key: entry.id,
				data: entry
			}));
		},
		placeholder: "Select a race before selecting teams",
		validate: validateTeamOrCampaignSelected,
		compact: true
	},
	{
		name: "markets",
		title: "Markets",
		mode: "multi",
		shouldFetch: ({ state, searching }) => {
			return (
				state.raceId >= 0 &&
				(searching ||
					state.raceId !== state.activeRaceId)
			);
		},
		shouldTrash: ({ state }) => {
			return (
				state.raceId !== -1 &&
				state.raceId !== state.activeRaceId
			);
		},
		fetch: async ({ query, state }) => {
			const res = await request({
				url: "/races/markets",
				query: {
					query,
					race_id: state.raceId
				}
			});

			if (!res.success) return "Failed to find markets";

			return (res.data as any[]).map(entry => ({
				title: entry.market_name,
				details: state.states || [state.state],
				key: entry.market_id,
				data: entry
			}));
		},
		placeholder: "Select a race before selecting markets",
		validate: ({ state }) =>
			state.raceId > 0
				? null
				: "Select a race before selecting markets",
		compact: true
	},
	{
		name: "omittedGraphs",
		title: "Omitted Graphs",
		mode: "multi",
		shouldFetch: true,
		shouldTrash: false,
		fetch: async () => {
			return graphs;
		},
		validate: () => true,
		compact: true
	}
] as WaterfallEntries;

interface PdfProps {
	dateRange: number[];
	raceId: string | number;
	selections: any;
}

type Key = string | number;
const CMAG = withTheme((props: CmagProps, props2: GraphsProps) => {
	const [campaignIds, setCampaignIds] = useState<Key[]>();

	const { querySettings, setSelectionPayload, setDateRange, refresh } =
		useQuerySettings();

	const selections = querySettings?.selectionPayload?.selections ?? {};

	const [pendingDateRange, setPendingDateRange] = useState(
		querySettings.dateRange
	);
	const [raceName, setRaceName] = useState<string>();

	const collection = useGraphConfigCollection();

	const rangeEquals = equals(querySettings.dateRange, pendingDateRange);

	const raceId = selections.race?.[0]?.key;

	const validPayload = !!querySettings?.selectionPayload?.valid;

	const controls = [
		{
			mode: "date-range",
			value: pendingDateRange,
			onChange: (rt: DateRangeRuntime) => {
				if (!collection.initialized)
					setDateRange(rt.range);

				setPendingDateRange(rt.range);
			}
		},
		{
			label: "Refresh",
			style: "subtle",
			disabled: rangeEquals,
			onClick: () => {
				if (pendingDateRange)
					setDateRange(pendingDateRange);

				refresh();
			}
		},
		{
			label: "Export",
			disabled:
				!collection.initialized ||
				!collection.settled ||
				!rangeEquals,
			onClick: () => {
				const filename = "CMAG Report";
				downloadPdf(filename);
			}
		}
	] as ControlUnion[];

	useEffect(() => {
		if (selections.campaigns) {
			const ids = selections.campaigns.map(c => c.key);
			if (
				ids.length !== campaignIds?.length ||
				!ids.every((id, idx) => id === campaignIds[idx])
			) {
				setCampaignIds(ids);
			}
		} else {
			setCampaignIds(undefined);
		}
	}, [selections.campaigns]);

	const teamIds = useMemo(() => {
		if (!selections.teams) return undefined;
		return selections.teams.map(t => t.key);
	}, [selections.teams])

	const marketIds = selections.markets
		? selections.markets.map(m => m.key)
		: undefined;
	const states = selections.states?.map(s => s.data);
	const omittedGraphs = selections.omittedGraphs?.map(s => s.data);

	return (
		<Base
			flush
			limited
			waterfall={WATERFALL}
			onSelectionChange={setSelectionPayload}
			controls={controls}
		>
			{typeof raceId === "number" &&
				rangeEquals &&
				validPayload && (
					<CmagStandalone
						campaigns={campaignIds}
						collection={collection}
						hash={querySettings.hash}
						markets={marketIds}
						race_id={raceId}
						teams={teamIds}
						states={states}
						start_date={
							querySettings
								.normalizedDateRange[0]
						}
						end_date={
							querySettings
								.normalizedDateRange[1]
						}
						omittedGraphs={omittedGraphs}
					/>
				)}
		</Base>
	);
});

export default CMAG;
