import { useMemo, useState } from "react";
import styled from "styled-components";

import { request } from "../../utils";

import useToast from "../../hooks/use-toast";
import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import { CANDIDATE_TYPES, PRESIDENTIAL } from "../../data/constants";

import { useAppSelector } from "../../state/hooks";

import {
	Text,
	File,
	Dropdown,
	Form,
	InputRow,
	InputBox,
	Checkbox
} from "../../components/inputs";
import { TextSeparator, VerticalSeparator } from "../../components/separators";
import Base from "./base";
import ArrowButton from "../../components/arrow-button";
import ActionTable from "../../components/action-table";

import { ColumnCell } from "../../types/data-table";
import { ViewRuntime } from "../../types/hierarchical";

const CheckboxLabel = styled.label`
	font-weight: bold;
	user-select: none;
	cursor: pointer;
`;

const Content = styled.div`
	display: flex;
	flex-grow: 1;
	padding: 15px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
`;

const FormBox = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 500px;
	border-radius: ${p => p.theme.borderRadius};
	overflow: auto;
`;

const GrowingInputBox = styled(InputBox)`
	flex-grow: 1;
`;

const Upload = styled.div`
	padding: 15px;
	margin-top: 15px;
	background: ${p => p.theme.componentBackground};
	border-radius: ${p => p.theme.borderRadius};
`;

const UploadContent = styled.div`
	display: flex;
`;

const FileForm = styled(Form)`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-right: 15px;
`;

const FileInputBox = styled(InputBox)`
	display: flex;
	flex-grow: 1;
`;
const UploadContentRight = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const UploadCategory = styled.div``;

const UploadTitle = styled.h3`
	font-size: 100%;
	line-height: 1;
	margin: 0;
`;

const Arrow = styled(ArrowButton)`
	margin-top: 3px;
`;

function numberOrNull(value: any) {
	if (typeof value === "number") return value;
	if (typeof value === "string") return Number(value);
	return null;
}

const Campaign = (props: ViewRuntime) => {
	const toast = useToast();

	const [files, setFiles] = useState([] as File[]);
	const [isDra, setIsDra] = useState(false);

	const campaignId = useMemo(() => {
		const pathParts = window.location.pathname.split("/");
		const id = parseInt(pathParts[pathParts.length - 1]);
		if (isNaN(id)) return -1;
		return id;
	}, []);

	const { teams, parties, agencies, markets, admoSponsors, admoRaces } =
		useAppSelector(state => state.data);

	function agencyPartySuffix(agency: any): string {
		if (!agency.party_id) return "";
		if (!parties) return "";
		const party = parties.find(p => p.id === agency.party_id);
		if (!party) return "";
		return ` (${party.full_name[0]})`;
	}

	const agencyOptions = useMemo(() => {
		if (!agencies || !agencies.length) {
			return [];
		}
		console.log("agencies", agencies);
		return agencies
			.map(agency => ({
				...agency,
				full_name: `${
					agency.full_name
				}${agencyPartySuffix(agency)}`
			}))
			.sort((a, b) => {
				if (a.party_id === b.party_id) {
					return a.full_name.localeCompare(
						b.full_name
					);
				}
				if (!a.party_id) return 1;
				if (!b.party_id) return -1;
				return a.party_id - b.party_id;
			});
	}, [agencies]);
	const [admoRaceOptions, admoSponsorOptions] = useMemo(() => {
		return [
			[
				{ value: null, label: "<none>" },
				...admoRaces.map(r => ({ value: r, label: r }))
			],
			[{ value: null, name: "<none>" }, ...admoSponsors]
		];
	}, [admoRaces, admoSponsors]);

	const filteredTeams = useMemo(() => {
		return teams.filter(t => t.race_id === props.state.race.id);
	}, [props.state.race.id, teams]);

	const actions = useTableActions({
		save: rows => {
			console.log("SAVE");
		},
		delete: rows => {
			console.log("DELETE");
		}
	});

	const COLUMNS: ColumnCell[] = [
		{
			name: "state",
			title: "State",
			accessor: PRESIDENTIAL
				? "state"
				: "campaign.race.jurisdiction",
			index: "lexical",
			fit: "shrink",
			sort: null
		},
		{
			name: "market",
			type: "choice",
			title: "Market",
			accessor: "station.market_id",
			fit: "shrink",
			config: {
				options: markets,
				labelAccessor: "name",
				valueAccessor: "id"
			}
		},
		{
			name: "candidateCpp30",
			type: "currency",
			title: "C. CPP :30",
			accessor: "station.candidate_cpp_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateCpp60",
			type: "currency",
			title: "C. CPP :60",
			accessor: "station.candidate_cpp_60",
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateDra30",
			type: "currency",
			title: "C. DRA :30",
			accessor: "station.candidate_dra_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "candidateDra60",
			type: "currency",
			title: "C. DRA :60",
			accessor: "station.candidate_dra_60",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueCpp30",
			type: "currency",
			title: "I. CPP :30",
			accessor: "station.issue_cpp_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueCpp60",
			type: "currency",
			title: "I. CPP :60",
			accessor: "station.issue_cpp_60",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueDra30",
			type: "currency",
			title: "I. DRA :30",
			accessor: "station.issue_dra_30",
			fit: "shrink",
			editable: true
		},
		{
			name: "issueDra60",
			type: "currency",
			title: "I. DRA :60",
			accessor: "station.issue_dra_60",
			fit: "shrink",
			editable: true
		}
	];

	const p = useRemoteActionProps({
		request: {
			url: "/flights/list",
			query: {
				campaign_id: campaignId
			},
			suspended: campaignId === -1,
			cacheHash: props.state.campaign.searchHash,
			placeholder: "No flights added"
		},
		columns: COLUMNS,
		pageSize: 50,
		spreadEdit: true,
		actions,
		expand: true
	});

	if (props.name === "new-campaign" && campaignId !== -1)
		props.redirect("campaign");

	props.hook("save", async () => {
		let id: number;

		if (campaignId === -1) id = await ensureCampaign();
		else id = await saveCampaign();

		return id !== -1;
	});

	const validate = (): string | null => {
		const c = props.state.campaign;

		const nullText = (text: any) => {
			if (typeof text != "string") return true;

			return !text.trim();
		};

		if (nullText(c.name)) return "Campaign name not provided";
		if (nullText(c.shortName)) return "Short name not provided";

		return null;
	};

	const ensureRace = async (): Promise<number> => {
		if (props.state.race.id !== -1) return props.state.race.id;

		const rData = props.state.race;

		const payload = {
			race_name: rData.name,
			race_type: rData.type,
			jurisdiction: rData.state,
			district: rData.district,
			year: String(rData.cycle),
			office_id: Number(rData.office)
		};

		if (rData.type === "primary")
			(payload as any).party_id = Number(rData.partyId);

		const response = await request({
			url: "/races/create",
			body: payload
		});

		if (!response.success) {
			toast.error(response.errorMessage!);
			return -1;
		}

		toast("Saved race");

		props.setState("race.id", response.data.id);
		props.updateState("races.searchHash", v => v + 1);
		return response.data.id;
	};

	const ensureCampaign = async (): Promise<number> => {
		const validation = validate();
		if (typeof validation == "string") {
			toast.error(validation);
			return -1;
		}

		if (campaignId !== -1) return campaignId;
		// if (campaignId !== -1)
		//	return saveCampaign();

		const raceId = await ensureRace();
		if (raceId === -1) return -1;

		const cData = getCampaignData(raceId);

		const response = await request({
			url: "/campaigns/create",
			body: cData
		});

		if (!response.success) {
			toast.error(response.errorMessage!);
			return -1;
		}

		toast("Saved campaign");

		props.setState("campaign.id", response.data.id);
		props.updateState("race.searchHash", v => v + 1);
		return response.data.id;
	};

	const saveCampaign = async (): Promise<number> => {
		const raceId = Number(props.state.race.id),
			cData = getCampaignData(raceId);

		const response = await request({
			url: `/campaigns/${campaignId}`,
			body: cData,
			method: "PUT"
		});

		props.updateState("race.searchHash", v => v + 1);

		if (!response.success) {
			toast.error(response.errorMessage!);
			return -1;
		}

		toast("Saved campaign");
		return campaignId;
	};

	const uploadFlights = async (template: string, medium: string) => {
		if (!files.length) {
			toast.error("No flights data provided");
			return;
		}

		const id = await ensureCampaign();

		if (id === -1) return;

		props.setState("flights", {
			template,
			medium,
			files,
			isDra
		});
		props.navigate("flights-review");
	};

	const mkFlightUploader = (template: string, medium: string) => {
		return () => uploadFlights(template, medium);
	};

	const addFlights = async () => {
		const id = await ensureCampaign();
		if (id === -1) return;

		props.setState("flights", { isDra });
		props.navigate("flights-add");
	};

	const getCampaignData = (raceId: number) => {
		const cData = props.state.campaign;

		return {
			race_id: raceId,
			party_id: Number(cData.party),
			agency_id: Number(cData.agency),
			team_id: numberOrNull(cData.team),
			full_name: cData.name,
			short_name: cData.shortName,
			candidate_type: cData.candidate_type,
			candidate_id: Number(cData.candidateId),
			ad_impact_sponsor_id: numberOrNull(
				cData.ad_impact_sponsor_id
			),
			ad_impact_race:
				(cData.ad_impact_race === "null"
					? null
					: cData.ad_impact_race) || null,
			ott_id: cData.ottId || null,
			google_ads_id: cData.googleAdsId || null,
			facebook_id: cData.facebookId || null
		};
	};

	const form = (
		<Form
			values={props.state.campaign}
			onChange={(values: any) =>
				props.setState("campaign", values)
			}
		>
			<InputRow>
				<GrowingInputBox
					required
					name="name"
					title="Campaign"
					autoComplete="off"
					input={Text}
				/>
				<GrowingInputBox
					required
					name="shortName"
					title="Short Name"
					autoComplete="off"
					input={Text}
				/>
				<InputBox
					required
					name="candidate_type"
					title="Type"
					input={Dropdown}
					options={CANDIDATE_TYPES}
					labelAccessor="label"
					valueAccessor="value"
				/>
			</InputRow>
			<InputRow>
				<InputBox
					required
					name="party"
					title="Party"
					input={Dropdown}
					options={parties}
					labelAccessor="full_name"
					valueAccessor="id"
				/>
				<GrowingInputBox
					name="team"
					title="Team"
					input={Dropdown}
					options={filteredTeams}
					labelAccessor="full_name"
					valueAccessor="id"
				/>
				<GrowingInputBox
					required
					name="agency"
					title="Agency"
					input={Dropdown}
					options={agencyOptions}
					labelAccessor="full_name"
					valueAccessor="id"
				/>
			</InputRow>
			<InputRow>
				<InputBox
					name="ad_impact_sponsor_id"
					title="ADMO Sponsor"
					input={Dropdown}
					options={admoSponsorOptions}
					labelAccessor="name"
					valueAccessor="id"
				/>
				<InputBox
					name="ad_impact_race"
					title="ADMO Race"
					input={Dropdown}
					options={admoRaceOptions}
					labelAccessor="label"
					valueAccessor="value"
				/>
			</InputRow>
			<InputRow>
				<GrowingInputBox
					name="ottId"
					title="OTT ID"
					autoComplete="off"
					input={Text}
				/>
				<GrowingInputBox
					name="googleAdsId"
					title="Google Ads ID"
					autoComplete="off"
					input={Text}
				/>
				<GrowingInputBox
					name="facebookId"
					title="Facebook ID"
					autoComplete="off"
					input={Text}
				/>
			</InputRow>
			<InputRow>
				<Checkbox
					checked={isDra}
					onChange={() => setIsDra(v => !v)}
				/>
				<CheckboxLabel
					onClick={() => setIsDra(v => !v)}
				>
					Is DRA
				</CheckboxLabel>
			</InputRow>
		</Form>
	);

	const upload = (
		<Upload>
			<Arrow onClick={addFlights}>Add Flights</Arrow>
			<TextSeparator>OR</TextSeparator>
			<UploadContent>
				<FileForm
					values={{ files }}
					onChange={(values: any) =>
						setFiles(values.files)
					}
				>
					<FileInputBox
						name="files"
						input={File}
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					/>
				</FileForm>
				<UploadContentRight>
					<UploadCategory>
						<UploadTitle>Cable</UploadTitle>
						<Arrow
							disabled={!files.length}
							onClick={mkFlightUploader(
								"cable",
								"Cable"
							)}
						>
							Template
						</Arrow>
					</UploadCategory>
					<UploadCategory>
						<UploadTitle>TV</UploadTitle>
						<Arrow
							disabled={!files.length}
							onClick={mkFlightUploader(
								"tv",
								"TV"
							)}
						>
							Template
						</Arrow>
					</UploadCategory>
					<UploadCategory>
						<UploadTitle>Radio</UploadTitle>
						{/*<Arrow onClick={mkFlightUploader("radio", "Radio")}>Template</Arrow>*/}
						<Arrow
							disabled={!files.length}
							onClick={mkFlightUploader(
								"iheart",
								"Radio"
							)}
						>
							iHeart
						</Arrow>
						<Arrow
							disabled={!files.length}
							onClick={mkFlightUploader(
								"katz",
								"Radio"
							)}
						>
							Katz
						</Arrow>
						<Arrow
							disabled={!files.length}
							onClick={mkFlightUploader(
								"gmp",
								"Radio"
							)}
						>
							GMP
						</Arrow>
					</UploadCategory>
				</UploadContentRight>
			</UploadContent>
		</Upload>
	);

	return (
		<Base>
			<Content>
				<FormBox>
					{form}
					{upload}
				</FormBox>
				<VerticalSeparator />
				<ActionTable {...p} />
			</Content>
		</Base>
	);
};

export default Campaign;
