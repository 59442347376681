import store from "../state/store";

const getPartyColors = () => {
	const parties = store.getState().data.parties,
		colors = {
			other: "#cdd8e7"
		} as Record<string, string>;

	for (const party of parties) colors[party.short_name] = party.color;

	return colors;
};

const lightTheme = {
	// Backgrounds
	background: "#e8ecf1",
	darkBackground: "#162f62",
	lightBackground: "#acb1bb",
	cardBackground: "white",
	activeLinkBackground: "#dde3eb",
	accent: "#3568eb",
	accentAlt: "#2256dc",
	accentLight: "#26a2ff",
	accentLightAlt: "#168ce4",
	disabledBackground: "#d7dce8",
	disabledAccent: "#4a5b86",
	disabledAccentAlt: "#37466d",
	warn: "yellow",
	alert: "#d6244f",
	alertAlt: "#bc1d43",
	subtle: "white",
	subtleAlt: "#f3f6f9",
	graphBackground: "#f7f8fc",
	componentBackground: "#f2f6fa",
	componentDarkBackground: "#dde3eb",
	evenSelectedRowBackground: "#d7e6f9",
	oddSelectedRowBackground: "#e2edfb",
	evenPendingRowBackground: "#d5ece0",
	oddPendingRowBackground: "#e1f5ea",
	evenInvalidRowBackground: "#fde6de",
	oddInvalidRowBackground: "#fff1e8",
	// evenInvalidRowBackground: "#fbdce5",
	// oddInvalidRowBackground: "#ffe8ef",
	// Colors
	color: "#323e5a",
	accentColor: "white",
	disabledAccentColor: "#d1d9ef",
	lightAccentColor: "#d4e3ff",
	accentLightColor: "white",
	alertColor: "white",
	subtleColor: "#323e5a",
	disabledColor: "#878e94",
	disabledColorAlt: "#4e5e82",
	separatorColor: "#e9ebf4",
	separatorColorAlt: "#d9dfe7",
	positiveColor: "#16920f",
	negativeColor: "#ca2828",
	// Presentation
	headerHeight: "60px",
	borderRadius: "3px",
	innerBorderRadius: "2px",
	buttonHeight: "34px",
	separatorSpacing: "15px",
	inputWidgetWidth: "18px",
	legendIconHeight: "14px",
	cardShadow: "3px 3px 10px rgb(45, 66, 115, 0.15)",
	textShadow: "rgba(17, 33, 68, 0.5) 1px 1px 5px",
	graphTextShadow: "#f7f8fc 1px 1px 3px",
	inputBorder: "1px solid #b0b9cc",
	// Media queries
	media: {
		tablet: "(max-aspect-ratio: 1/1) and (max-width: 1100px)",
		mobile: "(max-aspect-ratio: 1/1) and (max-width: 600px)"
	},
	// Graph theme
	graphs: {
		successive: [
			"#40b1f0",
			"#3f5ae5",
			"#457b9d",
			"#ff6384",
			"#e21111",
			"#33f110",
			"#038111"
		],
		palettes: {
			colors: [
				"#82c272",
				"#00a88f",
				"#0087ac",
				"#005faa",
				"#323b81",
				"#321f80",
				"#320a7f"
			],
			grayscale: ["#cdd8e7", "#aabad0", "#889db8"],
			get parties() {
				return getPartyColors();
			}
		},
		reach: {
			impressions: "#a8baea",
			completions: "#7995df",
			cpm: "#3f5ae5"
		},
		empty: "#e0e2ec",
		grid: {
			col1: "#d1d5e0",
			col2: "#d1d5e0"
		},
		axes: {
			lines: "#818ca5",
			col1: "#3e4f73",
			col2: "#3e4f73"
		}
	},
	// Copied from Bid Lab (FIXME: refactor, unify with rest of theme)
	backgroundAlt: "#d3e0ea",
	headerBackground: "white",
	footerBackground: "white",
	tableRowBackground: "#f2f6fa",
	popBackground: "#e1fffc",
	popBackgroundAlt: "#c8eaf7",
	overlayBackground: "rgba(77, 100, 125, 0.1)",
	modalBackground: "rgba(255, 255, 255, 0.6)",

	popColor: "#007bff",
	popColorDark: "#0569d4",
	popColorInfo: "#2db565",
	popColorAlert: "#d6244f",
	popColorAlertDark: "#ab0f38",
	altColor: "#3f86b6",
	tableColor: "#323e5a",
	naColor: "#5a5a5a",
	highlight: "#004dff",
	huedColor: "#3e5c75"
};

export default lightTheme;
