import { FormEvent, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { API_URL } from "../../data/constants";
import { default as DateInput } from "../../components/inputs/date";
import { getDateString, getRoundDate } from "../../utils";
import Select from "react-select";
import PivotTable, {
	ColumnPivot
} from "../../components/pivot-table/pivot-table";
import { downloadPdf, DOWNLOAD_PDF_DIV_ID } from "../../utils/download-pdf";
import { Button } from "../../components/inputs";
import StateSelect from "../../components/state-select";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const LoginWrapperForm = styled.form`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 1rem;
	margin: auto;
	max-width: 20rem;
	padding-top: 2rem;
`;

const TableWrapper = styled.div.attrs({ id: DOWNLOAD_PDF_DIV_ID })`
	overflow: scroll;
`;

const LoginLabel = styled.label`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	justify-content: space-between;
`;

const LoginForm = ({
	onLogin
}: {
	onLogin: (username: string, password: string) => void;
}) => {
	function handleLogin(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();
		const form = e.target as any;
		const username = form.username.value;
		const password = form.password.value;
		onLogin(username, password);
	}

	return (
		<LoginWrapperForm onSubmit={handleLogin}>
			<LoginLabel>
				Username
				<input name="username" type="text" />
			</LoginLabel>
			<LoginLabel>
				Password
				<input name="password" type="password" />
			</LoginLabel>
			<button type="submit">Log in</button>
		</LoginWrapperForm>
	);
};

const Header = styled.div`
	align-items: center;
	display: flex;
	gap: 2rem;
	justify-content: center;
	padding-bottom: 1rem;
	flex-wrap: wrap;
`;

const StyledSelect = styled(Select)`
	min-width: 16rem;
`;

// describes order in which pivots should appear
const pivotLevels: string[] = ["party", "candidate", "market"];

const teamPivotLevels: string[] = ["party", "team", "market"];

type RaceSelection = {
	value: number;
	label: string;
};

function ymdIso(ymd: string) {
	return `${ymd}T00:00:00.000Z`;
}

function formatDateYmd(date: Date) {
	const year = String(date.getFullYear());
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
}

function defaultStartTime(): string {
	return formatDateYmd(new Date());
}

function defaultEndTime(): string {
	const now = new Date();
	now.setDate(now.getDate() + 7);
	return formatDateYmd(now);
}

function addDays(origDate: Date, days: number) {
	var date = new Date(origDate.valueOf());
	date.setDate(date.getDate() + days);
	return date;
}

function getDates(start: number, stop: number): number[] {
	const startDate = new Date(start);
	const stopDate = new Date(stop);
	var dateArray = new Array();
	var currentDate = startDate;
	while (currentDate <= stopDate) {
		dateArray.push(new Date(currentDate).valueOf());
		currentDate = addDays(currentDate, 1);
	}
	return dateArray;
}

const Report = () => {
	const [data, setData] = useState([]);
	const [startTimeStr, setStartTimeStr] =
		useState<string>(defaultStartTime);
	const [endTimeStr, setEndTimeStr] = useState<string>(defaultEndTime);
	const [races, setRaces] = useState<RaceSelection[]>();
	const [raceId, setRaceId] = useState<number>();
	const [columnPivot, setColumnPivot] = useState<ColumnPivot>();
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingGraph, setLoadingGraph] = useState<boolean>(false);
	const [exporting, setExporting] = useState<boolean>(false);
	const [selectedStates, setSelectedStates] = useState<string[]>();
	const [error, setError] = useState<string>();
	const [expandMedia, setExpandMedia] = useState<boolean>(true);

	const showTeams = data.some((d: any) => d.hasOwnProperty("team"));

	const raceName = races?.find(race => race.value === raceId)?.label;

	function exportToPdf() {
		if (!raceName) return;
		setExporting(true);
		const filename = `${raceName} ${startTimeStr} - ${endTimeStr}`;
		downloadPdf(filename, true).then(() => setExporting(false));
	}

	const handleStartTimeChange = (evt: any) => {
		setStartTimeStr(evt.target.value);
	};

	const handleEndTimeChange = (evt: any) => {
		if (evt.target.value < startTimeStr) {
			setEndTimeStr(startTimeStr);
		} else {
			setEndTimeStr(evt.target.value);
		}
	};

	function dateToUtcDate(date: Date) {
		const yyyyMMdd = date.toISOString().split("T")[0];
		const [year, month, day] = yyyyMMdd.split("-");
		return `${parseInt(month).toString()}/${parseInt(
			day
		).toString()}/${year}`;
	}

	const refresh = () => {
		const url = new URL(`${API_URL}/public/weekly_spending`);
		url.searchParams.append("start_time", ymdIso(startTimeStr));
		url.searchParams.append("end_time", ymdIso(endTimeStr));
		if (selectedStates) {
			url.searchParams.append(
				"states",
				selectedStates.join(",")
			);
		}
		const dayDurationMillis = 24 * 3600 * 1000;
		const rangeStep = dayDurationMillis * 7;
		const ranges: number[] = [];
		const startTime = new Date(ymdIso(startTimeStr)).getTime();
		const endTime = new Date(ymdIso(endTimeStr)).getTime();
		let rangeStart = startTime;
		for (; rangeStart < endTime; rangeStart += rangeStep) {
			ranges.push(rangeStart);
		}
		setColumnPivot({
			dataIsInRange: (d: any, rangeIndex: number) =>
				d.weekStart >= ranges[rangeIndex] &&
				(!ranges[rangeIndex + 1] ||
					d.weekStart < ranges[rangeIndex + 1]),
			displayValue: (d: any) => dateToUtcDate(new Date(d)),
			property: "weekStart",
			rangeStarts: ranges
		});
		url.searchParams.append("race_id", raceId?.toString() || "");
		setLoadingGraph(true);
		fetch(url.toString(), { credentials: "include" })
			.then(resp => resp.json())
			.then(resp => {
				if (!resp?.data || !Array.isArray(resp.data)) {
					setError(
						"No data for selected options"
					);
					setData([]);
					return;
				}
				setError(undefined);
				setData(
					resp.data
						.filter(
							(d: any) =>
								d.grp > 0 ||
								d.spend > 0
						)
						.flatMap((d: any) => {
							const days = getDates(
								d.start_date,
								d.end_date
							).filter(
								d =>
									d >=
										startTime &&
									d <=
										endTime +
											12 *
												3600 *
												1000
							);
							return days.map(day => {
								const rangeIndexAfterDay =
									ranges.findIndex(
										range =>
											range >
											day
									);
								const rangeIndex =
									rangeIndexAfterDay ===
									0
										? rangeIndexAfterDay
										: rangeIndexAfterDay <
										  0
										? ranges.length -
										  1
										: rangeIndexAfterDay -
										  1;
								return {
									...d,
									weekStart: new Date(
										ranges[
											rangeIndex
										]
									)
								};
							});
						})
				);
			})
			.finally(() => setLoadingGraph(false));
	};

	useEffect(() => {
		setLoading(true);
		fetch(`${API_URL}/public/races`, { credentials: "include" })
			.then(resp => resp.json())
			.then(resp => {
				setRaces(
					resp.data.map((entry: any) => {
						return {
							value: entry.id,
							label: entry.race_name
						};
					})
				);
			})
			.catch(err => {})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const lastUpdated = useMemo(() => {
		if (data.length === 0) {
			return undefined;
		}
		return data.reduce((mostRecent: Date, nextFlight: any) => {
			const flightModifiedDate = new Date(
				nextFlight.modified
			);
			return flightModifiedDate > mostRecent
				? flightModifiedDate
				: mostRecent;
		}, new Date(0));
	}, [data]);

	function handleSelectedStatesChange(
		selected: string[] | string | undefined
	) {
		if (!selected) {
			setSelectedStates(undefined);
		} else if (Array.isArray(selected)) {
			setSelectedStates(selected);
		} else {
			setSelectedStates([selected]);
		}
	}

	return loading ? (
		<div>Loading...</div>
	) : (
		<div>
			<Header>
				<DateInput
					value={startTimeStr}
					onChange={handleStartTimeChange}
				/>
				<DateInput
					value={endTimeStr}
					min={startTimeStr}
					onChange={handleEndTimeChange}
				/>
				<StyledSelect
					options={races}
					placeholder="Select Race..."
					onChange={(e: any) =>
						setRaceId(e.value)
					}
				/>
				<StateSelect
					isMulti={true}
					onChange={handleSelectedStatesChange}
					placeholder="State (Optional)"
					value={selectedStates}
				/>
				{lastUpdated && (
					<div>{`Last Updated ${lastUpdated.toLocaleDateString()} @ ${lastUpdated.toLocaleTimeString()}`}</div>
				)}
				<Button
					onClick={() => setExpandMedia(m => !m)}
					style="subtle"
				>
					{expandMedia ? "Hide" : "Show"} Media
				</Button>
				<Button onClick={refresh} disabled={!raceId}>
					Refresh
				</Button>
				<Button
					onClick={exportToPdf}
					disabled={!raceName || exporting}
					style="light-accent"
				>
					Export
				</Button>
			</Header>
			{loadingGraph && <div>Loading...</div>}
			{!loadingGraph && !!error && (
				<div style={{ textAlign: "center" }}>
					{error}
				</div>
			)}
			{!loadingGraph && !error && columnPivot && (
				<TableWrapper>
					<PivotTable
						columnPivot={columnPivot}
						data={data}
						expandByMedium={expandMedia}
						rowPivots={pivotLevels}
					/>
					{showTeams && (
						<>
							<div
								style={{
									fontWeight: "bold",
									paddingTop: "3rem",
									textAlign: "center",
									width: "100vw"
								}}
							>
								Teams
							</div>
							<PivotTable
								columnPivot={
									columnPivot
								}
								data={data}
								expandByMedium={
									expandMedia
								}
								rowPivots={
									teamPivotLevels
								}
							/>
						</>
					)}
				</TableWrapper>
			)}
		</div>
	);
};

const PublicWeekly = () => {
	const [loggedIn, setLoggedIn] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	function handleLogin(username: string, password: string) {
		setLoading(true);
		const formData = new FormData();
		formData.append("username", username);
		formData.append("password", password);
		fetch(`${API_URL}/public/login`, {
			body: formData,
			method: "post",
			credentials: "include"
		})
			.then(resp => {
				if (resp.ok) {
					setLoggedIn(true);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		setLoading(true);
		fetch(`${API_URL}/public/races`, { credentials: "include" })
			.then(resp => {
				if (resp.ok) {
					setLoggedIn(true);
				}
			})
			.catch(err => {})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	function render() {
		if (loading) {
			return <div>Loading...</div>;
		}
		if (!loggedIn) {
			return <LoginForm onLogin={handleLogin} />;
		}
		return <Report />;
	}

	return <Wrapper>{render()}</Wrapper>;
};

export default PublicWeekly;
