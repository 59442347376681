import { Route, Switch } from "react-router-dom";

// ====== Routes ======
// Main
import Dashboard from "./dashboard";
import Races from "./races";
import Flights from "./flights";

// Settings
import Campaigns from "./settings/campaigns";
import Emails from "./settings/emails";
import Markets from "./settings/markets";
import Media from "./settings/media";
import Offices from "./settings/offices";
import Teams from "./settings/teams";
import TeamSplits from "./settings/teams_v2";
import Agencies from "./settings/agencies";
import Users from "./settings/users";
import Stations from "./settings/stations";
import TV from "./settings/stations/tv";
import Cable from "./settings/stations/cable";
import Radio from "./settings/stations/radio";
import Parties from "./settings/parties";

// Reports
import Spending from "./reports/spending";
import OTT from "./reports/ott";
import CMAG from "./reports/cmag";
import Snapshots from "./reports/snapshots";
import WeeklyGrp from "./reports/weekly-grp";
import PublicWeekly from "./reports/public-weekly";

// Temp
import Fallback from "./fallback";
import DailySnapshot from "./reports/daily-snapshot";

const Routes = () => (
	<Switch>
		{/* Main */}
		<Route exact path="/races">
			<Races />
		</Route>
		<Route exact path="/flights">
			<Flights />
		</Route>

		{/* Settings */}
		<Route exact path="/settings/campaigns">
			<Campaigns />
		</Route>
		<Route exact path="/settings/emails">
			<Emails />
		</Route>
		<Route exact path="/settings/markets">
			<Markets />
		</Route>
		<Route exact path="/settings/media">
			<Media />
		</Route>
		<Route exact path="/settings/offices">
			<Offices />
		</Route>
		<Route exact path="/settings/teams">
			<Teams />
		</Route>
		<Route exact path="/settings/team_splits">
			<TeamSplits />
		</Route>
		<Route exact path="/settings/agencies">
			<Agencies />
		</Route>
		<Route exact path="/settings/users">
			<Users />
		</Route>
		<Route exact path="/settings/stations">
			<Stations />
		</Route>
		<Route exact path="/settings/stations/tv">
			<TV />
		</Route>
		<Route exact path="/settings/stations/cable">
			<Cable />
		</Route>
		<Route exact path="/settings/stations/radio">
			<Radio />
		</Route>
		<Route exact path="/settings/parties">
			<Parties />
		</Route>

		{/* Reports */}
		<Route exact path="/reports/spending">
			<Spending />
		</Route>
		<Route exact path="/reports/ott">
			<OTT />
		</Route>
		<Route exact path="/reports/ottv2">
			<OTT version="2" />
		</Route>
		<Route exact path="/reports/cmag">
			<CMAG />
		</Route>
		<Route exact path="/reports/snapshots">
			<Snapshots />
		</Route>
		<Route exact path="/reports/daily-snapshot">
			<DailySnapshot />
		</Route>
		<Route exact path="/reports/weekly-grp">
			<WeeklyGrp />
		</Route>
		<Route exact path="/public/weekly">
			<PublicWeekly />
		</Route>

		<Route path="/">
			<Dashboard />
		</Route>

		{/* Temp */}
		<Route>
			<Fallback />
		</Route>
	</Switch>
);

export default Routes;
