import styled from "styled-components";

import { request } from "../../utils";

import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";
import { useAppSelector } from "../../state/hooks";

import {
	Text,
	Form,
	Avatar,
	Dropdown,
	InputBox,
	InputRow,
	InputColumn
} from "../../components/inputs";
import Base from "../base";
import SplitBox, { SplitProps } from "../../components/split-box";
import ContentCard from "../../components/content-card";

import { ColumnCell } from "../../types/data-table";

const COLUMNS: ColumnCell[] = [
	{
		name: "name",
		title: "Name",
		accessor: "full_name"
	},
	{
		name: "shortName",
		title: "Short Name",
		accessor: "short_name"
	},
	{
		name: "logo",
		type: "hidden",
		title: "Logo",
		accessor: "logo"
	},
	{
		name: "party_id",
		type: "hidden",
		title: "Party",
		accessor: "party_id"
	}
];

const ContentWrapper = styled.div`
	max-width: 600px;
`;

const ExpandingBox = styled(InputBox)`
	flex-grow: 1;
	flex-basis: 0;
`;

const DropdownBox = styled(InputBox)`
	min-width: 130px;
`;

const Content = (props: SplitProps) => {
	const parties = useAppSelector(state => state.data.parties);
	const partyOptions = [{ id: null, full_name: "<none>" }, ...parties];

	return (
		<ContentWrapper>
			<Form values={props.focusedRow} onChange={props.edit}>
				<InputRow>
					<InputBox
						name="logo"
						title="Agency Logo"
						input={Avatar}
						dimensions={{
							width: 1000,
							height: 1000
						}}
					/>
					<InputColumn>
						<InputRow>
							<ExpandingBox
								name="full_name"
								title="Name"
								input={Text}
							/>
						</InputRow>
						<InputRow>
							<ExpandingBox
								name="short_name"
								title="Short Name"
								input={Text}
							/>
						</InputRow>
						<DropdownBox
							name="party_id"
							title="Party"
							input={Dropdown}
							options={partyOptions}
							labelAccessor="full_name"
							valueAccessor="id"
						/>
					</InputColumn>
				</InputRow>
			</Form>
		</ContentWrapper>
	);
};

const Agencies = () => {
	const actions = useTableActions({
		addLabel: "Add Agency",
		add: () => ({
			full_name: "",
			short_name: "",
			logo: ""
		}),
		createEach: row => {
			return request({
				url: "/agencies/create",
				body: {
					...row,
					logo: row.logo ? row.logo : null,
					party_id:
						row.party_id > 0
							? row.party_id
							: null
				}
			});
		},
		modifyEach: row => {
			return request({
				url: `/agencies/${row.id}`,
				body: {
					...row,
					logo: row.logo ? row.logo : null,
					party_id:
						row.party_id > 0
							? row.party_id
							: null
				},
				method: "PUT"
			});
		},
		deleteEach: row => {
			return request({
				url: `/agencies/${row.id}`,
				method: "DELETE"
			});
		}
	});

	const props = useRemoteActionProps({
		request: "/agencies/list",
		process: row => ({
			...row,
			logo: row.logo || ""
		}),
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<SplitBox
					{...props}
					autoFocus
					title="Focused Agency"
					notice="No agency focused"
					content={Content}
				/>
			</ContentCard>
		</Base>
	);
};

export default Agencies;
