import { useMemo } from "react";
import styled from "styled-components";

import { request } from "../../utils";

import states from "../../data/states.json";

import useRequest from "../../hooks/use-request";
import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import {
	Text,
	Dropdown,
	Form,
	InputRow,
	InputBox
} from "../../components/inputs";
import Base from "../base";
import SplitBox, { SplitProps } from "../../components/split-box";
import ContentCard from "../../components/content-card";

import { ColumnCell } from "../../types/data-table";
import { PRESIDENTIAL } from "../../data/constants";

interface SplitPropsExtension {
	races: any[];
}

const ContentWrapper = styled.div`
	max-width: 600px;
`;

const DropdownBox = styled(InputBox)`
	min-width: 150px;
`;

const ExpandingBox = styled(InputBox)`
	flex-grow: 1;
`;

const Content = (props: SplitProps<SplitPropsExtension>) => {
	return (
		<ContentWrapper>
			<Form values={props.focusedRow} onChange={props.edit}>
				<InputRow>
					{!PRESIDENTIAL && <DropdownBox
						name="state"
						title="State"
						input={Dropdown}
						options={states}
						labelAccessor="state"
						valueAccessor="state"
					/>}
					<ExpandingBox
						name="full_name"
						title="Name"
						input={Text}
					/>
				</InputRow>
				<InputRow>
					<ExpandingBox
						name="keywords"
						title="Keywords"
						input={Text}
					/>
					<DropdownBox
						name="race_id"
						title="Race"
						input={Dropdown}
						options={props.races}
						labelAccessor="race_name"
						valueAccessor="id"
					/>
				</InputRow>
			</Form>
		</ContentWrapper>
	);
};

function sortRaces(a: any, b: any) {
	if (a.race_name === b.race_name) return 0;
	if (a.race_name < b.race_name) return -1;
	return 1;
}

const Teams = () => {
	const [rawRaces] = useRequest("/races/list", []);

	const races = useMemo(() => {
		rawRaces.sort(sortRaces);
		return [
			{
				race_name: "none",
				id: -1
			},
			...rawRaces
		].map(race => ({
			...race,
			id: String(race.id)
		}));
	}, [rawRaces]);

	const modeColumns: ColumnCell[] = PRESIDENTIAL ? [] :
	[
		{
			name: "state",
			type: "choice",
			title: "State",
			accessor: "state",
			index: "lexical",
			config: {
				options: states,
				labelAccessor: "state",
				valueAccessor: "state"
			}
		}
	]

	const columns: ColumnCell[] = [
		...modeColumns,
		{
			name: "name",
			title: "Name",
			accessor: "full_name"
		},
		{
			name: "keywords",
			title: "Keywords",
			accessor: "keywords"
		},
		{
			name: "race",
			type: "choice",
			title: "Race",
			accessor: "race_id",
			config: {
				options: races,
				labelAccessor: "race_name",
				valueAccessor: "id"
			}
		},
		{
			name: "logo",
			type: "hidden",
			title: "Logo",
			accessor: "logo"
		}
	];

	const actions = useTableActions({
		addLabel: "Add Team",
		add: () => ({
			state: "US",
			full_name: "",
			keywords: "",
			race_id: "-1"
		}),
		createEach: row => {
			return request({
				url: "/teams/create",
				body: {
					...row,
					keywords: row.keywords || null,
					race_id:
						Number(row.race_id) > 0
							? Number(row.race_id)
							: null
				}
			});
		},
		modifyEach: row => {
			return request({
				url: `/teams/${row.id}`,
				body: {
					...row,
					keywords: row.keywords || null,
					race_id:
						Number(row.race_id) > 0
							? Number(row.race_id)
							: null
				},
				method: "PUT"
			});
		},
		deleteEach: row => {
			return request({
				url: `/teams/${row.id}`,
				method: "DELETE"
			});
		}
	});

	const props = useRemoteActionProps({
		request: "/teams/list",
		process: row => ({
			...row,
			race_id:
				row.race_id === null
					? "-1"
					: String(row.race_id)
		}),
		columns,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<SplitBox
					{...props}
					autoFocus
					title="Focused Team"
					notice="No team focused"
					content={Content}
					splitProps={{ races }}
				/>
			</ContentCard>
		</Base>
	);
};

export default Teams;
