import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface DataState {
	error: string | null;
	loaded: boolean;
	progress: number;
	assetCount: number;
	parties: Party[];
	offices: Office[];
	teams: Team[];
	agencies: Agency[];
	races: Race[];
	markets: Market[];
	admoRaces: AdmoRace[];
	admoSponsors: AdmoSponsor[];
}

interface Party {
	id: number;
	full_name: string;
	short_name: string;
	color: string;
}

interface Office {
	id: number;
	full_name: string;
	short_name: string;
	sort_order: number;
	top_5: boolean;
	frequently_used: boolean;
}

interface Team {
	id: number;
	full_name: string;
	state: string;
	race_id: number;
}

interface Agency {
	id: number;
	full_name: string;
	short_name: string;
	logo: string;
	party_id: number;
}
interface Race {
	id: number;
	party_id: number;
	office_id: number;
	race_type: string;
	race_name: string;
	jurisdiction: string;
	district: string;
	using_station_defaults: boolean;
	office: Office;
	party: Party | null;
}

interface Market {
	id: number;
	name: string;
}

type AdmoRace = string;

interface AdmoSponsor {
	id: number;
	name: string;
}

const INITIAL_STATE = {
	error: null,
	loaded: false,
	progress: 0,
	assetCount: 8,
	parties: [],
	offices: [],
	teams: [],
	agencies: [],
	races: [],
	markets: [],
	admoRaces: [],
	admoSponsors: []
} as DataState;

export const slice = createSlice({
	name: "data",
	initialState: INITIAL_STATE,
	reducers: {
		supplyParties: (
			state: Draft<DataState>,
			action: PayloadAction<Party[]>
		) => {
			state.parties = action.payload;
			state.progress++;
		},
		supplyOffices: (
			state: Draft<DataState>,
			action: PayloadAction<Office[]>
		) => {
			state.offices = action.payload.sort((a, b) =>
				a.full_name.localeCompare(b.full_name)
			);
			state.progress++;
		},
		supplyTeams: (
			state: Draft<DataState>,
			action: PayloadAction<Team[]>
		) => {
			state.teams = action.payload.sort((a, b) =>
				a.full_name.localeCompare(b.full_name)
			);
			state.progress++;
		},
		supplyAgencies: (
			state: Draft<DataState>,
			action: PayloadAction<Agency[]>
		) => {
			state.agencies = action.payload.sort((a, b) =>
				a.full_name.localeCompare(b.full_name)
			);
			state.progress++;
		},
		supplyRaces: (
			state: Draft<DataState>,
			action: PayloadAction<Race[]>
		) => {
			state.races = action.payload;
			state.progress++;
		},
		supplyMarkets: (
			state: Draft<DataState>,
			action: PayloadAction<Market[]>
		) => {
			state.markets = action.payload
				.slice()
				.sort((a, b) => a.name.localeCompare(b.name));
			state.progress++;
		},
		supplyAdmoRaces: (
			state: Draft<DataState>,
			action: PayloadAction<AdmoRace[]>
		) => {
			state.admoRaces = action.payload.slice().sort();
			state.progress++;
		},
		supplyAdmoSponsors: (
			state: Draft<DataState>,
			action: PayloadAction<AdmoSponsor[]>
		) => {
			state.admoSponsors = action.payload
				.slice()
				.sort((a, b) => a.name.localeCompare(b.name));
			state.progress++;
		},
		setLoaded: (
			state: Draft<DataState>,
			action: PayloadAction<boolean>
		) => {
			state.loaded = action.payload;
		},
		setSupplyError: (
			state: Draft<DataState>,
			action: PayloadAction<string>
		) => {
			state.error = action.payload;
		}
	}
});

export default slice.reducer;
export const {
	supplyParties,
	supplyOffices,
	supplyTeams,
	supplyAgencies,
	supplyRaces,
	supplyMarkets,
	setLoaded,
	setSupplyError,
	supplyAdmoRaces,
	supplyAdmoSponsors
} = slice.actions;
