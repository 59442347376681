import { produce } from "immer";
import styled from "styled-components";
import { useEffect, useState } from "react";

const dollarFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	maximumFractionDigits: 0
});

const EntryCell = styled.td`
	text-align: right;
`;

const PivotCell = styled.td``;

const Expandible = styled.td`
	cursor: pointer;
`;

const Table = styled.table`
	border-collapse: collapse;
	empty-cells: hide;
	margin: auto;
	* {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	td {
		border-left: solid 1px #ddd;
		border-right: solid 1px #ddd;
		max-width: 10rem;
		min-width: 10rem;
		padding: 0 1rem;
	}
	th {
		border: solid 1px #ddd;
		max-width: 10rem;
		min-width: 10rem;
		text-transform: capitalize;
	}
`;

function nameToBgColor(name: string) {
	if (name === "Democrat") return "#add8e6";
	if (name === "Republican") return "#ffcccb";
	if (name === "Independent") return "#90ee90";
	return "white";
}

type TableStyleProps = {
	name: string;
};
const TableBody = styled.tbody<TableStyleProps>`
	border: solid 1px #ddd;
	tr:first-child {
		background-color: ${props => nameToBgColor(props.name)};
	}
	tr:nth-child(even) {
		background-color: #eeeeee;
		td:first-child {
			background-color: #eeeeee;
		}
		td:nth-child(2) {
			background-color: #eeeeee;
		}
		td:nth-child(3) {
			background-color: #eeeeee;
		}
	}
	tr:nth-child(odd) {
		td:first-child {
			background-color: #ffffff;
		}
		td:nth-child(2) {
			background-color: #ffffff;
		}
		td:nth-child(3) {
			background-color: #ffffff;
		}
	}
`;

const TableFooter = styled.tfoot`
	margin-top: 16rem;
`;

const TotalRow = styled.tr<TableStyleProps>`
	border: solid 1px #ddd;
	td:nth-child(n + 1):nth-child(-n + 3) {
		border: none;
		position: sticky;
	}
	td:first-child {
		left: 0;
		background-color: white;
	}
	td:nth-child(2) {
		left: 10rem;
		background-color: white;
	}
	td:nth-child(3) {
		left: 20rem;
		background-color: white;
	}
`;

const FooterSpace = styled.tr`
	height: 2rem;
	* {
		border: none;
	}
`;

const Row = styled.tr<TableStyleProps>`
	td:nth-child(n + 1):nth-child(-n + 3) {
		border: none;
		position: sticky;
	}
	td:first-child {
		left: 0;
	}
	td:nth-child(2) {
		left: 10rem;
	}
	td:nth-child(3) {
		left: 20rem;
	}
`;

const HeaderRow = styled.tr<TableStyleProps>`
	td:nth-child(n + 1):nth-child(-n + 3) {
		border: none;
		position: sticky;
	}
	td:first-child {
		background-color: ${props =>
			nameToBgColor(props.name)} !important;
		left: 0;
	}
	td:nth-child(2) {
		background-color: ${props =>
			nameToBgColor(props.name)} !important;
		left: 10rem;
	}
	td:nth-child(3) {
		background-color: ${props =>
			nameToBgColor(props.name)} !important;
		left: 20rem;
	}
`;

const TD = styled.td``;
const TH = styled.th``;
const TR = styled.tr``;
const THead = styled.thead<TableStyleProps>`
	border-top: solid 1px #ddd;
	th:nth-child(n + 1):nth-child(-n + 3) {
		border: none;
		position: sticky;
	}
	th:first-child {
		background-color: ${props => nameToBgColor(props.name)};
		left: 0;
	}
	th:nth-child(2) {
		background-color: ${props => nameToBgColor(props.name)};
		left: 10rem;
	}
	th:nth-child(3) {
		background-color: ${props => nameToBgColor(props.name)};
		left: 20rem;
	}
`;

type Pivot = string;

type PivotState = {
	children?: PivotState[];
	expanded: boolean;
	level: number;
	name: string;
	path: string;
	totals: any[];
};

export type ColumnPivot = {
	dataIsInRange: (d: any, rangeIndex: number) => boolean;
	displayValue: (d: any) => string;
	property: string;
	rangeStarts: any[];
};

type TableProps = {
	columnPivot: ColumnPivot;
	data: any[];
	rowPivots: Pivot[];
	expandByMedium: boolean;
};

type TableState = {
	children: PivotState[];
	totals: any[];
};

function addSpend(acc: any, d: any): any {
	let { grp, spend, tv, cable, radio, addr } = acc;
	if (d.medium) {
		if (d.medium === "tv") {
			tv += d.spend;
			grp += d.grp;
		} else if (d.medium === "cable") {
			cable += d.spend;
		} else if (d.medium === "radio") {
			radio += d.spend;
		} else {
			addr += d.spend;
		}
	} else {
		tv += d.tv || 0;
		grp += d.grp || 0;
		cable += d.cable || 0;
		radio += d.radio || 0;
		addr += d.addr || 0;
	}

	spend += d.spend;
	return {
		grp,
		spend,
		tv,
		cable,
		radio,
		addr
	};
}

function buildState(
	data: any[],
	level: number,
	path: string,
	columnPivot: ColumnPivot,
	rowPivots: Pivot[]
): PivotState[] {
	const uniquePivots = Array.from(
		new Set(data.map(d => d[rowPivots[level]]))
	);
	return uniquePivots.map(pivot => {
		const state: PivotState = {
			expanded: false,
			level,
			name: pivot,
			totals: [],
			path: `${path}/${pivot}`
		};
		const applicableData = data.filter(
			d => d[rowPivots[level]] === pivot
		);
		state.totals = columnPivot.rangeStarts.map(
			(rangeStart, rangeIndex) => {
				const columnData = applicableData.filter(d =>
					columnPivot.dataIsInRange(d, rangeIndex)
				);
				return columnData.reduce(addSpend, {
					grp: 0,
					spend: 0,
					tv: 0,
					cable: 0,
					radio: 0,
					addr: 0
				});
			}
		);
		state.totals.push(
			state.totals.reduce(addSpend, {
				grp: 0,
				spend: 0,
				tv: 0,
				cable: 0,
				radio: 0,
				addr: 0
			})
		);
		const nextLevel = level + 1;
		if (nextLevel < rowPivots.length) {
			state.children = buildState(
				applicableData,
				nextLevel,
				state.path,
				columnPivot,
				rowPivots
			);
		}
		return state;
	});
}

function renderRow(
	totalLevels: number,
	state: PivotState,
	handleToggleExpand: (state: PivotState) => void,
	partyName: string,
	expandByMedium: boolean,
	headerRow: boolean = false
): JSX.Element {
	const rowCells = [];
	for (let level = 0; level < totalLevels; level++) {
		const key = `${state.name}-${level}`;
		if (state.level === level) {
			if (state.children) {
				const marker = state.expanded ? "-" : "+";
				rowCells.push(
					<Expandible
						key={key}
						onClick={() =>
							handleToggleExpand(
								state
							)
						}
					>{`${marker} ${state.name}`}</Expandible>
				);
			} else {
				rowCells.push(
					<PivotCell key={key}>
						{state.name}
					</PivotCell>
				);
			}
		} else {
			rowCells.push(<PivotCell key={key}></PivotCell>);
		}
	}
	rowCells.push(renderTotals(state.name, state.totals, expandByMedium));
	const RowTag = headerRow ? HeaderRow : Row;
	return (
		<RowTag key={`${state.path}-${state.name}`} name={partyName}>
			{rowCells}
		</RowTag>
	);
}

function renderRowsForPivot(
	totalLevels: number,
	state: PivotState,
	handleToggleExpand: (state: PivotState) => void,
	partyName: string,
	expandByMedium: boolean,
	headerRow: boolean = false
): JSX.Element[] {
	const firstRow = renderRow(
		totalLevels,
		state,
		handleToggleExpand,
		partyName,
		expandByMedium,
		headerRow
	);
	if (state.expanded && state.children) {
		const childRows = state.children
			.filter(child => !!child.children || !!child.name)
			.sort((c1, c2) => c1.name.localeCompare(c2.name))
			.flatMap(child =>
				renderRowsForPivot(
					totalLevels,
					child,
					handleToggleExpand,
					partyName,
					expandByMedium
				)
			);
		return [firstRow, ...childRows];
	} else {
		return [firstRow];
	}
}

function renderTotals(
	pivot: string,
	totals: any[],
	expandByMedium: boolean
): JSX.Element[] {
	return totals.map((total, index) => (
		<>
			{!expandByMedium && (
				<EntryCell key={`${pivot}-${index}-spend`}>
					{`${dollarFormatter.format(
						total.spend
					)}`}
				</EntryCell>
			)}
			{expandByMedium && (
				<EntryCell key={`${pivot}-${index}-tv`}>
					{`${dollarFormatter.format(total.tv)}`}
				</EntryCell>
			)}
			<EntryCell key={`${pivot}-${index}-grp`}>
				{Math.round(total.grp)}
			</EntryCell>
			{expandByMedium && (
				<EntryCell key={`${pivot}-${index}-cable`}>
					{`${dollarFormatter.format(
						total.cable
					)}`}
				</EntryCell>
			)}
			{expandByMedium && (
				<EntryCell key={`${pivot}-${index}-radio`}>
					{`${dollarFormatter.format(
						total.radio
					)}`}
				</EntryCell>
			)}
			{expandByMedium && (
				<EntryCell key={`${pivot}-${index}-addr`}>
					{`${dollarFormatter.format(
						total.addr
					)}`}
				</EntryCell>
			)}
			{expandByMedium && (
				<EntryCell key={`${pivot}-${index}-spend`}>
					{`${dollarFormatter.format(
						total.spend
					)}`}
				</EntryCell>
			)}
		</>
	));
}

const PivotTable = (props: TableProps) => {
	const [tableState, setTableState] = useState<TableState>();

	function toggleExpanded(state: PivotState) {
		setTableState(prevState => {
			return produce(prevState, draft => {
				const pathParts = state.path
					.split("/")
					.filter(p => !!p);
				let current:
					| PivotState
					| TableState
					| undefined = draft;
				for (
					let i = 0;
					i < pathParts.length && pathParts[i];
					i++
				) {
					const children:
						| PivotState[]
						| undefined = current?.children;
					current = children?.find(
						c => c.name === pathParts[i]
					);
				}
				if (current?.hasOwnProperty("expanded")) {
					const target = current as PivotState;
					target.expanded = !target.expanded;
				}
			});
		});
	}

	useEffect(() => {
		const children: PivotState[] = buildState(
			props.data,
			0,
			"",
			props.columnPivot,
			props.rowPivots
		);
		const totals = children.reduce((acc, child) => {
			if (!acc.length) {
				return child.totals.map(v => ({ ...v }));
			} else {
				return acc.map((entry, idx) => {
					Object.keys(entry).forEach(key => {
						entry[key] =
							entry[key] +
							child.totals[idx][key];
					});
					return entry;
				});
			}
		}, [] as any[]);
		setTableState({
			children,
			totals
		});
	}, [props.data]);

	return (
		<Table key="table">
			<THead name="" key="thead">
				<TR key="total-header">
					<TH key="0-header"></TH>
					<TH key="1-header"></TH>
					<TH key="2-header"></TH>
					{props.columnPivot.rangeStarts.map(
						rangeStart => (
							<TH
								colSpan={
									props.expandByMedium
										? 6
										: 2
								}
								key={`${rangeStart}-header`}
							>
								{props.columnPivot.displayValue(
									rangeStart
								)}
							</TH>
						)
					)}
					<TH
						colSpan={
							props.expandByMedium
								? 6
								: 2
						}
						key="total-header"
					>
						Total
					</TH>
				</TR>
				<TR key="header">
					{props.rowPivots.map(pivot => (
						<>
							<TH
								key={`${pivot}-header`}
							>
								{pivot}
							</TH>
						</>
					))}
					{props.columnPivot.rangeStarts.map(
						rangeStart => (
							<>
								{!props.expandByMedium && (
									<TH
										key={`${rangeStart}-spend-header`}
									>
										Spend
									</TH>
								)}
								{props.expandByMedium && (
									<TH
										key={`${rangeStart}-tv-header`}
									>
										TV
									</TH>
								)}
								<TH
									key={`${rangeStart}-grp-header`}
								>
									GRP
								</TH>
								{props.expandByMedium && (
									<TH
										key={`${rangeStart}-cable-header`}
									>
										Cable
									</TH>
								)}
								{props.expandByMedium && (
									<TH
										key={`${rangeStart}-radio-header`}
									>
										Radio
									</TH>
								)}
								{props.expandByMedium && (
									<TH
										key={`${rangeStart}-addr-header`}
									>
										Addr
									</TH>
								)}
								{props.expandByMedium && (
									<TH
										key={`${rangeStart}-total-header`}
									>
										Total
									</TH>
								)}
							</>
						)
					)}
					{!props.expandByMedium && (
						<TH key="spend-header">
							Spend
						</TH>
					)}
					{props.expandByMedium && (
						<TH key="tv-header">TV</TH>
					)}
					<TH key="grps-header">GRP</TH>
					{props.expandByMedium && (
						<TH key="cable-header">
							Cable
						</TH>
					)}
					{props.expandByMedium && (
						<TH key="radio-header">
							Radio
						</TH>
					)}
					{props.expandByMedium && (
						<TH key="addr-header">Addr</TH>
					)}
					{props.expandByMedium && (
						<TH key="total-header">
							Total
						</TH>
					)}
				</TR>
			</THead>

			{!!tableState &&
				tableState.children.flatMap(child => {
					return (
						<TableBody
							key={`tbody-${child.name}`}
							name={child.name}
						>
							{renderRowsForPivot(
								props.rowPivots
									.length,
								child,
								toggleExpanded,
								child.name,
								props.expandByMedium,
								true
							)}
						</TableBody>
					);
				})}
			<TableFooter key="tfoot">
				<FooterSpace key="footer-spacing"></FooterSpace>
				<TotalRow name="" key="grand-totals">
					<TD key="total-grand-totals">Total</TD>
					<TD key="1-grand-totals"></TD>
					<TD key="2-grand-totals"></TD>
					{!!tableState &&
						renderTotals(
							"total",
							tableState.totals,
							props.expandByMedium
						)}
				</TotalRow>
			</TableFooter>
		</Table>
	);
};

export default PivotTable;
