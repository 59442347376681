import { useState } from "react";
import styled from "styled-components";

import { resolveOfficeKey } from "../utils";

import { useAppSelector } from "../state/hooks";

import Base from "./base";
import Hierarchical from "../components/hierarchical";
import Race from "./dashboard-views/race-dashboard/race";
import Races from "./dashboard-views/races";
import Campaign from "./dashboard-views/campaign";
import Flights from "./dashboard-views/flights";

import {
	Node,
	ViewRuntime
} from "../types/hierarchical";
import { State } from "../types/common";
import { PRESIDENTIAL } from "../data/constants";

const StateHighlight = styled.span`
	color: ${p => p.theme.accent};
	margin-right: 0.6em;
`;



const RaceLabel = (props: any) => (
	<div>
		<StateHighlight>{props.state.race.state}</StateHighlight>
		{props.state.offices.find((office: any) => office.id === props.state.race.office)?.full_name}
	</div>
);

const isInvalidCampaign = (r: ViewRuntime) => {
	const c = r.state.campaign;

	const nullText = (text: any) => {
		if (typeof text != "string")
			return true;

		return !text.trim();
	};

	return (
		nullText(c.name) ||
		nullText(c.shortName)
	);
};

const FLIGHT_VIEWS: Node[] = [{
	name: "flights-add",
	urlComponent: "flights/add",
	label: "Add Flights",
	view: Flights,
	preMountState: {
		flights: {
			upload: false
		}
	},

	controls: [{
		type: "navigation",
		target: "back",
		style: "alert",
		label: "Discard",
		confirm: {
			prompt: "Discard Changes?"
		}
	}, {
		type: "navigation",
		target: "back",
		label: "Save",
		hook: "save"
	}]
}, {
	name: "flights-review",
	urlComponent: "flights/review",
	label: "Review Upload",
	view: Flights,
	preMountState: {
		flights: {
			upload: true
		}
	},
	controls: [{
		type: "navigation",
		target: "back",
		style: "alert",
		label: "Discard",
		confirm: {
			prompt: "Discard Changes?"
		}
	}, {
		type: "navigation",
		target: PRESIDENTIAL ? undefined : "back",
		label: "Save",
		hook: "save"
	}]
}];

const HIERARCHY: Node = {
	name: "races",
	urlComponent: "",
	label: "Home",
	view: Races,
	actions: [{
		type: "navigation",
		target: "race",
		icon: "chevron-right",
		hook: (runtime: ViewRuntime) => {
			runtime.setState("race", {
				stateKey: "us",
				officeKey: resolveOfficeKey(runtime.state.offices[0].short_name),
				district: "",
				name: "",
				partyId: -1,
				cycle: new Date().getFullYear(),
				id: -1
			});
		}
	}],
	children: [{
		name: "race",
		urlComponent: "race/:race.stateKey/:race.officeKey",
		label: ({ state }) => <RaceLabel state={state} />,
		view: Race,
		preMountState: (runtime: ViewRuntime) => {
			const raceState = runtime.state.race,
				stateKey = raceState.stateKey,
				newState = stateKey.toUpperCase(),
				officeKey = raceState.officeKey,
				matchedOffice = runtime.state.offices
					.find((office: any) => {
						return resolveOfficeKey(office.short_name) === officeKey;
					}) || runtime.state.offices[0];

			return {
				...runtime.state,
				race: {
					...runtime.state.race,
					state: newState,
					office: matchedOffice.id
				}
			};
		},
		actions: [{
			type: "navigation",
			target: "new-campaign",
			icon: "plus"
		}],
		controls: [{
			type: "navigation",
			target: "races",
			style: "alert",
			label: "Cancel",
			confirm: {
				prompt: "Discard Changes?"
			}
		}, {
			type: "save",
			target: "races",
			label: "Save",
			hook: "save"
		}],
		children: [{
			name: "new-campaign",
			urlComponent: "campaign/new",
			label: ({ state }) => state.campaign.name || "New Candidate",
			view: Campaign,
			preMountState: {
				campaign: {
					id: -1,
					name: "",
					shortName: "",
					type: "",
					party: "",
					team: "",
					agency: "",
					cmagId: "",
					ottId: "",
					googleAdsId: "",
					facebookId: ""
				},
				flights: {
					files: []
				}
			},
			actions: [{
				type: "reload",
				icon: "plus",
				confirm: {
					prompt: "Discard Changes?"
				}
			}],
			controls: [{
				type: "navigation",
				target: "race",
				style: "alert",
				label: "Cancel",
				confirm: {
					prompt: "Discard Changes?"
				}
			}, {
				type: "navigation",
				target: "race",
				label: "Save",
				disabled: isInvalidCampaign,
				hook: "save"
			}],
			children: FLIGHT_VIEWS
		}, {
			name: "campaign",
			urlComponent: "campaign/:campaign.id",
			label: ({ state }) => state.campaign.name || "New Candidate",
			view: Campaign,
			preMountState: {
				flights: {
					files: []
				}
			},
			actions: [{
				type: "navigation",
				target: "new-campaign",
				icon: "plus"
			}],
			controls: [{
				type: "navigation",
				target: "race",
				style: "alert",
				label: "Cancel",
				confirm: {
					prompt: "Discard Changes?"
				}
			}, {
				type: "navigation",
				target: "race",
				label: "Save",
				disabled: isInvalidCampaign,
				hook: "save"
			}],
			children: FLIGHT_VIEWS
		}]
	}]
};

const Dashboard = () => {
	const offices = useAppSelector(state => state.data.offices);

	const [hNode, setHNode] = useState("races");
	const [hierarchyState, setHierarchyState] = useState({
		races: {
			searchHash: 0
		},
		race: {
			id: -1,
			state: "",
			type: "",
			stateKey: "",
			office: -1,
			officeKey: "",
			district: "",
			partyId: -1,
			name: "",
			cycle: new Date().getFullYear(),
			searchHash: 0,
			email_lists: []
		},
		campaign: {
			id: -1,
			name: "",
			shortName: "",
			type: "",
			party: "",
			team: "",
			agency: "",
			cmagId: "",
			ottId: "",
			googleAdsId: "",
			facebookId: "",
			searchHash: 0
		},
		flights: {
			files: [],
			upload: false
		},
		offices
	} as State);

	return (
		<Base limited>
			<Hierarchical
				hierarchy={HIERARCHY}
				node={hNode}
				state={hierarchyState}
				onStateChange={setHierarchyState}
				onNavigate={node => setHNode(node.name)}
			/>
		</Base>
	);
};

export default Dashboard;
