import { useEffect, useState } from "react";
import { transparentize } from "polished";
import styled, { withTheme } from "styled-components";

import { format, request } from "../../utils";

import useGraphConfigCollection, {
	GraphConfigCollection
} from "../../hooks/use-graph-config-collection";
import useGraphBox, { OptionalGraphBox } from "../../hooks/use-graph-box";

import {
	Pie,
	Radar,
	Share,
	Table,
	LineBar,
	GraphBox,
	GraphRow,
	BareGraphBox,
	HorizontalBar
} from "../../components/viz";

import { DataPoint } from "../../components/viz/apply-categorization";
import { CellRuntime } from "../../components/viz/graphs/configs/table";
import { LayoutConfig } from "../../components/viz/apply-layout";
import { AugmentedRequestConfig } from "../../types/utils";
import { useAppDispatch } from "../../state/hooks";
import { setSupplyError, supplyParties } from "../../state/features/data";
import {
	propsAndQueryParamsProxy,
	toArray,
	toNumber
} from "../../utils/standalone-utils";
import { DOWNLOAD_PDF_DIV_ID } from "../../utils/download-pdf";

const toneColors = [
	"#ff0000a0",
	"#00ff00a0",
	"#0000ffa0",
	"#800080a0",
	"#ffa500a0",
	"#000000a0",
	"#ffff00a0"
];

const guidRegex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;

interface CmagData {
	candidate: string;
	party: string;
	spots: number;
	spotCost: number;
	runs: CmagRun[];
	creatives: CmagCreative[];
	dayParts: CmagDayPart[];
}

interface CmagRun {
	spots: number;
	price: number;
}

interface CmagCreative {
	name: string;
	runs: number;
	percentage: number;
}

interface CmagDayPart {
	name: string;
	spots: number;
	percentage: number;
}

interface CmagGraphProps {
	data: CmagData[];
	collection: GraphConfigCollection;
	theme: any;
}

interface CmagGraphWrapperProps {
	columns: string;
}

interface CmagHeaderProps {
	data: CmagData;
	color: string;
	index: number;
	collection: GraphConfigCollection;
}

interface CmagHeaderWrapperProps {
	color: string;
	index: number;
}

interface MetricProps {
	title: string;
	change?: number;
	children: any;
}

interface MetricChangeProps {
	change: number;
}

interface BarGraphProps {
	data: CmagCreative | CmagDayPart;
	valueKey: string;
	color: string;
	max: number;
}

interface BarGraphCellProps {
	color: string;
	children: any;
}

interface BarGraphBarCellProps {
	color: string;
	value: number;
	max: number;
}

interface BarGraphPercentageCellProps {
	data: CmagCreative | CmagDayPart;
	color: string;
}

interface BarInfo {
	field: string;
	order: string[];
}

const DEFAULT_DAYPART_SORT_ORDER = [
	"Early Fringe",
	"Early Morning",
	"Early News",
	"Daytime",
	"Prime Access",
	"Prime",
	"Late Fringe",
	"Late News",
	"Overnight"
];

const CmagHeaderWrapper = styled.div<CmagHeaderWrapperProps>`
	display: flex;
	flex-direction: column;
	grid-column: ${p => p.index * 3 + 2} / ${p => p.index * 3 + 5};
	position: relative;
	background: ${p => transparentize(0.92, p.color)};
	padding: 40px 20px 20px 20px;
`;

const CmagTitle = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	padding: 6px 8px 6px 10px;
	background: ${p => p.theme.darkBackground};
	color: ${p => p.theme.subtle};
	font-size: 90%;
	line-height: 1;
	font-weight: 600;
	z-index: 1;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 30px;
		height: 100%;
		background: inherit;
		transform-origin: 100% 100%;
		transform: skewX(-30deg);
		z-index: -1;
	}
`;

const Metrics = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 20px 20px 20px;
`;

const MetricWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${p => p.theme.darkBackground};
`;

const MetricTitle = styled.span`
	font-size: 90%;
	line-height: 1;
	opacity: 0.6;
`;

const MetricValue = styled.p`
	margin: 0.1em 0;
	font-size: 180%;
	font-weight: bold;
`;

const MetricChangeWrapper = styled.div<MetricChangeProps>`
	display: flex;
	align-items: center;
	font-size: 90%;
	line-height: 1;
	color: ${p =>
		p.change < 0 ? p.theme.negativeColor : p.theme.positiveColor};

	&:before {
		content: "";
		display: inline-block;
		border: 3px solid transparent;
		border-top: ${p =>
			p.change < 0
				? `3px solid ${p.theme.negativeColor}`
				: "none"};
		border-bottom: ${p =>
			p.change < 0
				? "none"
				: `3px solid ${p.theme.positiveColor}`};
		margin-right: 0.2em;
	}
`;

const MetricChange = (props: MetricChangeProps) => {
	return (
		<MetricChangeWrapper {...props}>
			{format.number(props.change * 100)}%
		</MetricChangeWrapper>
	);
};

const Metric = (props: MetricProps) => {
	return (
		<MetricWrapper>
			<MetricTitle>{props.title}</MetricTitle>
			<MetricValue>{props.children}</MetricValue>
			{props.change && <MetricChange change={props.change} />}
		</MetricWrapper>
	);
};

const CmagGraphBox = styled(BareGraphBox)`
	min-height: 150px;
	margin: 0 5px;
`;

const CmagHeader = (props: CmagHeaderProps) => {
	const layout = {
		legends: {
			legends: []
		},
		valueMap: {
			price: "line",
			spots: "bar",
			dateLabel: "barLabel"
		},
		colors: {
			price: props.color,
			spots: transparentize(0.6, props.color)
		}
	} as LayoutConfig;

	const tooltip = (d: DataPoint) => {
		const value =
			d.label === "price"
				? format.dollar(d.value as number)
				: format.number(d.value as number);

		return `${d.label}: ${value}`;
	};

	const lineBar = useGraphBox({
		graph: LineBar,
		title: "",
		request: () => props.data?.runs || [],
		box: CmagGraphBox,
		layout,
		tooltips: tooltip,
		bare: true,
		track: props.collection.track(`cmag-header-${props.index}`),
		graphKey: `${props.data.candidate}-${props.data.runs}-${props.data.spots}`
	});

	return (
		<CmagHeaderWrapper color={props.color} index={props.index}>
			<CmagTitle>{props.data.candidate}</CmagTitle>
			<Metrics>
				<Metric title="Total Spots">
					{format.number(props.data.spots || 0)}
				</Metric>
				<Metric title="Cost Per Spot">
					{format.dollar(
						props.data.spotCost || 0
					)}
				</Metric>
			</Metrics>
			{lineBar}
		</CmagHeaderWrapper>
	);
};

const CmagGraphWrapper = styled.figure<CmagGraphWrapperProps>`
	display: grid;
	grid-template-columns: ${p => p.columns};
	margin: 0;
	min-width: 100%;
	overflow: hidden;
`;

const TitleCell = styled.div`
	background: ${p => p.theme.darkBackground};
	color: ${p => p.theme.subtle};
	font-size: 90%;
	font-weight: 600;
	line-height: 1;
	padding: 8px 10px;
`;

const FlushTitleCell = styled(TitleCell)`
	padding-left: 0;
	padding-right: 0;
`;

const RowTitleCell = styled.div`
	font-size: 90%;
	padding: 6px 10px;
`;

const BarGraphCell = styled.div<BarGraphCellProps>`
	position: relative;
	background: ${p => transparentize(0.92, p.color)};
	font-size: 90%;
	font-weight: 600;
`;

const BarGraphValueCell = styled(BarGraphCell)`
	text-align: right;
	padding: 6px 10px;
`;

const BarGraphBarSpacer = styled.div`
	position: relative;
	height: 100%;
	margin-right: 10px;
`;

const BarGraphBar = styled.div<BarGraphBarCellProps>`
	position: absolute;
	top: 6px;
	bottom: 6px;
	left: 0;
	width: ${p => `${(p.value / p.max) * 100}%`};
	background: ${p => p.color};
`;

const BarGraphBarCell = (props: BarGraphBarCellProps) => {
	return (
		<BarGraphCell color={props.color}>
			<BarGraphBarSpacer>
				<BarGraphBar {...props} />
			</BarGraphBarSpacer>
		</BarGraphCell>
	);
};

const BarGraphPercentageCellWrapper = styled.div<BarGraphPercentageCellProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	background: ${p => transparentize(1 - p.data.percentage, p.color)};
`;

const BarGraphPercentageCell = (props: BarGraphPercentageCellProps) => {
	return (
		<BarGraphCell color={props.color}>
			<BarGraphPercentageCellWrapper {...props}>
				{format.number(props.data.percentage * 100)}%
			</BarGraphPercentageCellWrapper>
		</BarGraphCell>
	);
};

const BarGraph = (props: BarGraphProps) => {
	let key = 0;

	if (!props.data) {
		const getKey = () =>
			`${Math.floor(Math.random() * 1000000)}-${key++}`;
		// if there is no data, return empty cells so as not to offset
		return (
			<>
				<BarGraphValueCell
					key={getKey()}
					color={props.color}
				>
					—
				</BarGraphValueCell>
				<BarGraphValueCell
					key={getKey()}
					color={props.color}
				>
					—
				</BarGraphValueCell>
				<BarGraphValueCell
					key={getKey()}
					color={props.color}
				>
					—
				</BarGraphValueCell>
			</>
		);
	}

	const getKey = () => {
		return `${props.data.name}-${key++}`;
	};

	return (
		<>
			<BarGraphValueCell key={getKey()} color={props.color}>
				{(props.data as any)
					? (props.data as any)[props.valueKey]
					: null}
			</BarGraphValueCell>
			<BarGraphBarCell
				key={getKey()}
				color={props.color}
				value={(props.data as any)[props.valueKey]}
				max={props.max}
			/>
			<BarGraphPercentageCell
				key={getKey()}
				data={props.data}
				color={props.color}
			/>
		</>
	);
};

const CmagGraph = withTheme((props: CmagGraphProps) => {
	const cells = [] as JSX.Element[];
	let columns = "max-content";
	if (!props.data || !props.data.length)
		return <CmagGraphWrapper columns={columns}></CmagGraphWrapper>;

	// Header graphs
	props.data.forEach((d, i) => {
		const color =
			d.party in props.theme.graphs.palettes.parties
				? props.theme.graphs.palettes.parties[d.party]
				: props.theme.graphs.palettes.parties["other"];

		columns += " max-content 1fr minmax(50px, auto)";

		cells.push(
			<CmagHeader
				key={`cmag-header-${d.candidate}-${d.spots}`}
				data={d}
				color={color}
				index={i}
				collection={props.collection}
			/>
		);
	});

	// Creatives
	cells.push(
		getTitles("Creative", props.data.length, [
			"Run Count",
			"Rotation %"
		])
	);

	cells.push(getBars(props, "creatives", "runs"));

	// Day parts
	cells.push(
		getTitles("Day Part", props.data.length, [
			"Spot Count",
			"% of Total"
		])
	);

	const dayPartSortOrder = DEFAULT_DAYPART_SORT_ORDER.filter(dayPart => {
		return props.data.some(d =>
			d["dayParts"].some(
				p =>
					p.name.toUpperCase() ===
					dayPart.toUpperCase()
			)
		);
	});

	cells.push(
		getBars(props, "dayParts", "spots", {
			field: "name",
			order: dayPartSortOrder
		})
	);

	const key = props.data.map(d => `${d.candidate}-${d.spots}`).join("-");
	return (
		<CmagGraphWrapper columns={columns} key={key}>
			{cells}
		</CmagGraphWrapper>
	);
});

const getBars = (
	props: CmagGraphProps,
	dataKey: string,
	valueKey: string,
	barOrders?: BarInfo
): JSX.Element => {
	if (props.data.length == 0) {
		return (
			<div
				key={`key-${Math.floor(
					Math.random() * 1000000
				)}-${dataKey}`}
			></div>
		);
	} else {
		// console.log(props.data);
	}

	const longestDataIndex = props.data.reduce(
		(acc: any, d: any, index: number) => {
			const data = d[dataKey] as (
				| CmagCreative
				| CmagDayPart
			)[];
			if (data.length > acc.longestDataLength) {
				return {
					longestDataLength: data.length,
					longestDataIndex: index
				};
			}
			return acc;
		},
		{ longestDataLength: 0, longestDataIndex: 0 }
	).longestDataIndex;

	const initData = (props.data[longestDataIndex] as any)[dataKey] as (
			| CmagCreative
			| CmagDayPart
		)[],
		dataCount = barOrders
			? barOrders.order.length
			: initData.length,
		cells = [] as JSX.Element[];
	let key = 0;

	const getKey = () => {
		return `cell-key-${key++}/${dataKey}`;
	};

	const maxes = props.data.map(d => {
		const entries = (d as any)[dataKey] as (
			| CmagCreative
			| CmagDayPart
		)[];

		return entries.reduce((acc, entry) => {
			return Math.max(
				acc,
				(entry as any)[valueKey] as number
			);
		}, 0);
	});

	const getRowLabel = (i: number) => {
		if (dataKey === "creatives") return `Spot ${i + 1}`;
		if (barOrders) return barOrders.order[i];
		return initData[i].name;
	};

	for (let i = 0; i < dataCount; i++) {
		const rowLabel = getRowLabel(i);
		cells.push(
			<RowTitleCell key={getKey()}>{rowLabel}</RowTitleCell>
		);

		props.data.forEach((d, idx) => {
			const color =
				props.theme.graphs.palettes.parties[d.party];
			const dataIndex = barOrders
				? (d as any)[dataKey].findIndex(
						(data: any) =>
							data[
								barOrders.field
							].toUpperCase() ===
							barOrders.order[
								i
							].toUpperCase()
				  )
				: i;
			const data = (d as any)[dataKey][dataIndex];
			if (data || true) {
				cells.push(
					<BarGraph
						key={getKey()}
						data={data}
						valueKey={valueKey}
						color={color}
						max={maxes[idx]}
					/>
				);
			}
		});
	}

	return <>{cells}</>;
};

const getTitles = (
	mainTitle: string,
	repeat: number,
	titles: [string, string]
): JSX.Element => {
	const out = [];
	let key = 0;

	const getKey = () => {
		return `title-${mainTitle}-${key++}`;
	};

	out.push(<TitleCell key={getKey()}>{mainTitle}</TitleCell>);

	for (let i = 0; i < repeat; i++) {
		out.push(<TitleCell key={getKey()} />);
		out.push(
			<FlushTitleCell key={getKey()}>
				{titles[0]}
			</FlushTitleCell>
		);
		out.push(<TitleCell key={getKey()}>{titles[1]}</TitleCell>);
	}

	return <>{out}</>;
};

const FullHeightGraphBox = styled(GraphBox)`
	height: 350px;
`;

const Graphs = withTheme((props: any) => {
	const params = propsAndQueryParamsProxy(props);

	const [teamDetails, setTeamDetails] = useState<any[]>();

	const collection = props.collection;

	const selectedTeams = toArray(params.teams);
	const selectedCampaigns = toArray(params.campaigns);
	const selectedMarkets = toArray(params.markets);
	const selectedStates = params.states
		? toArray(params.states)
		: undefined;
	const dateRange = [params.start_date, params.end_date];
	const omittedGraphs = toArray(params.omittedGraphs) || [];

	useEffect(() => {
		if (!params.teams?.length) {
			setTeamDetails(undefined);
		} else {
			request({
				url: "/teams/list",
				query: { race_id: params.race_id }
			}).then(res => {
				if (res.success) {
					const data = res.data as any[];
					setTeamDetails(data);
				}
			});
		}
	}, [params.race_id, params.teams]);

	const colors = props.theme.graphs.palettes.colors;
	const partyColors = props.theme.graphs.palettes.parties;

	const groupColors = teamDetails?.length
		? teamDetails.reduce(
				(obj, team, i) => ({
					...obj,
					[team.full_name]: colors[i]
				}),
				{}
		  )
		: partyColors;

	const query = {
		race_id: params.race_id,
		start_date: params.start_date,
		end_date: params.end_date,
		teams: selectedTeams,
		campaigns: selectedCampaigns,
		markets: selectedMarkets?.join("|"),
		states: selectedStates
	};

	const mkRequest = (
		url: string,
		process?: (data: any) => any
	): AugmentedRequestConfig => {
		return {
			url: `/graphs/cmag/${url}`,
			query,
			cacheHash: props.hash || 0,
			process
		};
	};

	const displaySpotLength = (rt: CellRuntime) => `:${rt.value}`;

	return (
		<>
			<GraphRow>
				{!omittedGraphs.includes("digest") && (
					<GraphBox title="CMAG Digest">
						<CmagGraph
							data={props.cmagData}
							key={dateRange
								.map(d =>
									d.toString()
								)
								.join("-")}
							collection={collection}
						/>
					</GraphBox>
				)}
			</GraphRow>
			<GraphRow>
				{!omittedGraphs.includes("dayPartShare") && (
					<OptionalGraphBox
						graph={Share}
						title="Day Part Share"
						request={mkRequest(
							"day_part_share"
						)}
						box={FullHeightGraphBox}
						tooltips={d =>
							`${d.label}: ${d.value}`
						}
						groupLabel={(d: DataPoint[]) =>
							d
								.reduce(
									(
										acc,
										data
									) =>
										acc +
										(data.value as number),
									0
								)
								.toString()
						}
						labelAllPoints={true}
						layout={{ colors: groupColors }}
						track={collection.track(
							"day-part-share"
						)}
					/>
				)}
			</GraphRow>
			<GraphRow>
				{!omittedGraphs.includes("mediumUsdSpend") && (
					<OptionalGraphBox
						graph={Radar}
						title="Tone"
						request={mkRequest("tone")}
						box={FullHeightGraphBox}
						tooltips={d => d.trace[0].label}
						labels={[
							"Contrast",
							"Positive",
							"Negative"
						]}
						layout={{
							colors: toneColors,
							legends: {
								level: 0
							},
							groupLevel: -1
						}}
						track={collection.track(
							"medium-usd-spend"
						)}
					/>
				)}
				{!omittedGraphs.includes("airtime") && (
					<OptionalGraphBox
						graph={Pie}
						title="Share of Voice"
						request={mkRequest(
							"share_of_voice"
						)}
						box={FullHeightGraphBox}
						tooltips={d =>
							`${
								d.trace[1].label
							}: ${format.timeDuration(
								d.value as number
							)}`
						}
						layout={{
							labelKey: "candidate_name",
							valueKey: "airtime",
							colors: groupColors
						}}
						track={collection.track(
							"airtime"
						)}
					/>
				)}
			</GraphRow>
			<GraphRow>
				{!omittedGraphs.includes("swingRatings") && (
					<OptionalGraphBox
						graph={HorizontalBar}
						title="Swing Ratings"
						request={mkRequest(
							"ratings_swing"
						)}
						box={GraphBox}
						tooltips={d => {
							return `${
								d.label
							}: ${Math.round(
								d.value as number
							)}`;
						}}
						groupLabel={(
							d: DataPoint[]
						) => {
							return Math.round(
								d.reduce(
									(
										acc,
										data
									) =>
										acc +
										(data.value as number),
									0
								)
							).toString();
						}}
						labelAllPoints={true}
						layout={{
							labelKey: "campaign",
							valueKey: "rating",
							colors: groupColors,
							colorRule: {
								track: [1, 2]
							}
						}}
						track={collection.track(
							"swing-ratings"
						)}
						getHeight={d =>
							Object.keys(d).length *
							25
						}
					/>
				)}
			</GraphRow>
			<GraphRow>
				{!omittedGraphs.includes("gopRatings") && (
					<OptionalGraphBox
						graph={HorizontalBar}
						title="GOP Primary Ratings"
						request={mkRequest(
							"ratings_gop"
						)}
						box={GraphBox}
						tooltips={d => {
							return `${
								d.label
							}: ${Math.round(
								d.value as number
							)}`;
						}}
						groupLabel={(
							d: DataPoint[]
						) => {
							return Math.round(
								d.reduce(
									(
										acc,
										data
									) =>
										acc +
										(data.value as number),
									0
								)
							).toString();
						}}
						labelAllPoints={true}
						layout={{
							labelKey: "campaign",
							valueKey: "rating",
							colors: groupColors,
							colorRule: {
								track: [1, 2]
							}
						}}
						track={collection.track(
							"gop-ratings"
						)}
						getHeight={d =>
							Object.keys(d).length *
							25
						}
					/>
				)}
			</GraphRow>
			<GraphRow>
				{!omittedGraphs.includes("rawData") && (
					<OptionalGraphBox
						graph={Table}
						title="Raw Data"
						request={mkRequest(
							"raw_data",
							data => {
								return data.map(
									(
										d: any
									) => {
										const id =
											guidRegex.exec(
												d.link
											)?.[0];
										const link =
											id
												? `https://s3.amazonaws.com/pdfweb/videos/${id}.mp4`
												: d.link;
										return {
											...d,
											link
										};
									}
								);
							}
						)}
						box={GraphBox}
						layout={{
							legends: null
						}}
						store={{
							columns: [
								{
									title: "Campaign",
									accessor: "campaign"
								},
								{
									title: "Creative",
									accessor: "creative"
								},
								{
									title: "Spot Length",
									accessor: "spot_length",
									display: displaySpotLength
								},
								{
									title: "Runs",
									accessor: "runs"
								},
								{
									title: "Link",
									accessor: "link",
									hrefAccessor:
										"link"
								}
							]
						}}
						getHeight={d =>
							(d.length + 1) * 25
						}
						track={collection.track(
							"raw-data"
						)}
					/>
				)}
			</GraphRow>
		</>
	);
});

function adjustCmagData(data: any[]): any {
	// filter on candidates that have spending data (runs)
	const candidatesWithData = data.filter(
		candidate => candidate?.runs?.length > 0
	);

	const runDateRange = candidatesWithData?.reduce((range, candidate) => {
		if (
			!Array.isArray(candidate?.runs) ||
			candidate.runs.length === 0
		)
			return range;
		const candidateStart = candidate.runs[0]?.date;
		const candidateEnd =
			candidate.runs[candidate.runs.length - 1]?.date;
		if (!candidateStart || !candidateEnd) return range;
		const candidateStartDate = new Date(candidateStart).getTime();
		const candidateEndDate = new Date(candidateEnd).getTime();
		return {
			startDate: range.startDate
				? Math.min(range.startDate, candidateStartDate)
				: candidateStartDate,
			endDate: range.endDate
				? Math.max(range.endDate, candidateEndDate)
				: candidateEndDate
		};
	}, {});

	if (!runDateRange.startDate || !runDateRange.endDate)
		return candidatesWithData;

	const startDate = new Date(runDateRange.startDate);
	const endDate = new Date(runDateRange.endDate);

	const withAdjustedDates = candidatesWithData?.map(candidate => {
		let runs = [];
		const runsByDate = candidate.runs?.reduce(
			(byDate: any, run: any) => {
				return { ...byDate, [run.date]: run };
			},
			{}
		);
		for (
			const date = new Date(startDate.getTime());
			date <= endDate;
			date.setUTCDate(date.getUTCDate() + 1)
		) {
			const dateStr = date.toISOString().slice(0, 10);
			const dateLabel = dateStr
				.substring(5)
				.replace("-", "/");
			const run = runsByDate[dateStr];
			if (run) {
				runs.push({ ...run, dateLabel });
			} else {
				runs.push({
					date: dateStr,
					price: 0,
					spots: 0,
					dateLabel
				});
			}
		}

		if (runs.length > 8) {
			// collapse into weeks
			let byWeek = [];
			for (let i = 0; i < runs.length; i += 7) {
				byWeek.push(runs[i]);
				for (
					let j = i + 1;
					j < i + 7 && j < runs.length;
					j++
				) {
					byWeek[byWeek.length - 1].price +=
						runs[j].price;
					byWeek[byWeek.length - 1].spots +=
						runs[j].spots;
				}
			}
			runs = byWeek;
		}
		return { ...candidate, runs };
	});

	return withAdjustedDates;
}

const CmagStandalone = (props: any) => {
	const fallbackCollection = useGraphConfigCollection();
	const collection = props.collection || fallbackCollection;

	const [cmagData, setCmagData] = useState([] as CmagData[]);

	const params = propsAndQueryParamsProxy(props);

	const campaigns = params.campaigns;
	const race_id = toNumber(params.race_id);
	const start_date = toNumber(params.start_date);
	const end_date = toNumber(params.end_date);
	const markets = toArray(params.markets);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const req = async (
			url: string,
			dispatcher: (action: any) => any
		) => {
			const response = await request(url);
			if (response.success)
				dispatch(dispatcher(response.data));
			else if (localStorage.getItem("debugging") === "true")
				dispatch(dispatcher([]));
			else dispatch(setSupplyError(response.errorMessage!));
		};

		req("/parties/list", supplyParties); // required for party color themes
	}, []);

	useEffect(() => {
		const teams = toArray(params.teams);
		if (
			typeof race_id !== "number" ||
			(!campaigns?.length && !teams?.length)
		)
			return;
		request({
			url: "/graphs/cmag/digest",
			method: "POST",
			body: {
				qs: {
					selectionPayload: {
						selections: {
							race: [
								{
									data: {
										race_id
									}
								}
							]
						},
						valid: true
					}
				},
				newDateRange: [start_date, end_date]
			},
			query: { campaigns, markets: markets?.join("|"), teams }
		}).then(resp => {
			if (resp.success) {
				setCmagData(adjustCmagData(resp.data));
			}
		});
	}, [race_id, campaigns, params.teams, start_date, end_date]);

	return (
		<div id={DOWNLOAD_PDF_DIV_ID}>
			<Graphs
				{...props}
				collection={collection}
				cmagData={cmagData}
			/>
		</div>
	);
};

export default CmagStandalone;
