import Select from "react-select";

export type SelectOption = {
	label: string;
	value: string;
};

type SelectProps = {
	isMulti?: boolean;
	onChange: (value: string[] | string | undefined) => void;
	options: SelectOption[];
	placeholder?: string;
	value: string[] | string | undefined;
	isDisabled?: boolean;
};

function Select2(props: SelectProps) {
	const { onChange, options, value, ...rest } = props;

	function handleChange(
		newValue: readonly SelectOption[] | SelectOption | null
	) {
		if (Array.isArray(newValue)) {
			onChange(newValue.map(v => v.value));
		} else if (newValue) {
			onChange((newValue as SelectOption).value);
		} else {
			onChange(undefined);
		}
	}

	return (
		<Select
			{...rest}
			options={options}
			onChange={handleChange}
			styles={{
				menu: base => ({ ...base, zIndex: 9999 })
			}}
			value={options.find(opt => opt.value === value)}
		/>
	);
}

export default Select2;
