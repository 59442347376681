import { useState } from "react";

import {
	equals,
} from "@qtxr/utils";

import {
	request
} from "../../utils";

import { API_URL, CANDIDATE_TYPES } from "../../data/constants";

import useGraphConfigCollection from "../../hooks/use-graph-config-collection";
import useQuerySettings from "../../hooks/use-query-settings";

import Base from "./base";

import {
	ControlUnion,
	DateRangeRuntime
} from "../../types/control-bar";
import { WaterfallEntries } from "../../components/waterfall";
import OttStandalone from "./ott-standalone";
import { downloadPdf } from "../../utils/download-pdf";

interface RaceEntry {
	race_id: number;
	race_name: string;
	year: number;
	campaign_count: number;
	race_jurisdiction: string;
}

const INITIAL_STATE = {
	raceId: -1,
	activeRaceId: -1
};

interface PdfProps {
	dateRange: number[];
	selections: any;
}

const WATERFALL = [
	{
		name: "race",
		title: "Race",
		mode: "radio",
		fetch: async ({ query }) => {
			const res = await request({
				url: "/races/search",
				query: {
					query
				}
			});

			if (!res.success)
				return "Failed to find races";

			return (res.data as RaceEntry[]).map(entry => ({
				title: entry.race_name,
				details: [
					`${entry.campaign_count} ${entry.campaign_count === 1 ? "campaign" : "campaigns"}`,
					entry.year
				],
				key: entry.race_id,
				data: entry
			}));
		},
		onSelectionChange: (sel, { setState }) => {
			setState("raceId", sel[0].data.race_id);
		},
		validate: ({ entry }) => entry.selection.length ? null : "Select a race",
		compact: true
	},
	{
		name: "campaign",
		title: "Campaign",
		mode: "radio",
		shouldFetch: ({ state, searching}) => {
			return state.raceId !== -1 && (searching || state.raceId !== state.activeRaceId);
		},
		shouldTrash: ({ state}) => {
			return state.raceId !== -1 && state.raceId !== state.activeRaceId;
		},
		fetch: async ({ query, state, setState }) => {
			setState("activeRaceId", state.raceId);
			const res = await request({
				url: "/campaigns/search",
				query: {
					query,
					race_id: state.raceId
				}
			});

			if (!res.success)
				return "Failed to find campaigns";

			return (res.data as any[]).map(entry => ({
				title: entry.candidate.full_name,
				details: [
					entry.party,
					CANDIDATE_TYPES.find(c => c.value === entry.candidate.candidate_type)?.label || ""
				],
				key: entry.id,
				data: entry
			}));
		},
		placeholder: "Select a race before selecting campaigns",
		validate: ({ entry }) => entry.selection.length ? null : "Select a campaign",
		onSelectionChange: (sel, { setState }) => {
			const campaignId = sel[0]?.data?.id;
			if (campaignId) {
				setState("campaignId", sel[0].data.id);
			} else {
				setState("campaignId", -1);
			}
		},
		compact: true
	},
	{
		name: "channels",
		title: "Channels",
		mode: "multi",
		fetch: async ({ query }) => {
			const res = await request({
				url: "@next-gen/channels/list",
				query: {
					query
				}
			});

			if (!res.success)
				return "Failed to find channels";

			return (res.data as any[]).map(entry => ({
				title: entry.name,
				details: [
					entry.type
				],
				key: entry.name,
				data: entry
			}));
		},
		validate: ({ entry }) => entry.selection.length ? null : "Select at least one channel",
		compact: true
	}
] as WaterfallEntries;

const OTT = ({ version }: { version?: "2" }) => {
	const {
		querySettings,
		setSelectionPayload,
		setDateRange,
		refresh
	} = useQuerySettings();

	const [pendingDateRange, setPendingDateRange] = useState(querySettings.dateRange);

	const [showGeo, setShowGeo] = useState<boolean>(false);

	const collection = useGraphConfigCollection();

	const rangeEquals = equals(querySettings.dateRange, pendingDateRange);

	const selections = querySettings.selectionPayload?.selections;

	const campaigns = selections?.campaign.map(c => c.key);
	const channels = selections?.channels?.map(c => c.data.name);
	const race = selections?.race?.[0]?.data;
	const raceName = race?.race_name;

	const controls = [
		{
			label: "Show Geo Data",
			mode: "checkbox",
			value: showGeo,
			onChange: () => {
				setShowGeo(show => !show);
			}
		},
		{
			mode: "date-range",
			value: pendingDateRange,
			onChange: (rt: DateRangeRuntime) => {
				if (!collection.initialized)
					setDateRange(rt.range);

				setPendingDateRange(rt.range);
			}
		},
		{
			label: "Refresh",
			style: "subtle",
			disabled: rangeEquals,
			onClick: () => {
				if (pendingDateRange)
					setDateRange(pendingDateRange);

				refresh();
			}
		},
		{
			label: "Export",
			disabled: !collection.initialized || !collection.settled || !rangeEquals,
			onClick: () => {
				const filename = raceName + " OTT Report";
				downloadPdf(filename);
			}
		}
	] as ControlUnion[];

	return (
		<Base
			flush
			limited
			waterfall={WATERFALL}
			initialState={INITIAL_STATE}
			onSelectionChange={setSelectionPayload}
			controls={controls}
		>
			<OttStandalone
				campaigns={campaigns}
				channels={channels}
				collection={collection}
				hash={querySettings.hash}
				start_date={querySettings.normalizedDateRange[0]}
				end_date={querySettings.normalizedDateRange[1]}
				race={race}
				showGeo={showGeo}
				version={version}
			/>
		</Base>
	);
};

export default OTT;
