import states from "../data/states.json";

import useTableActions from "../hooks/use-table-actions";
import useRemoteActionProps from "../hooks/use-remote-action-props";

import Base from "./base";
import ActionTable from "../components/action-table";
import ContentCard from "../components/content-card";

import { ColumnCell } from "../types/data-table";
import { request } from "../utils";
import { useAppSelector } from "../state/hooks";
import { useMemo } from "react";
import { PRESIDENTIAL } from "../data/constants";

type RaceMarket = {
	candidate_cpp_30: number;
	candidate_cpp_60: number;
	candidate_dra_30: number;
	candidate_dra_60: number;
	issue_cpp_30: number;
	issue_cpp_60: number;
	issue_dra_30: number;
	issue_dra_60: number;
	market_id: number;
	market_name: string;
	race_id: number;
	race_name: string;
};

const Flights = () => {
	const markets = useAppSelector(state => state.data.markets);

	const COLUMNS: ColumnCell[] = useMemo(
		() => [
			{
				name: "race",
				title: "Race",
				accessor: "campaign.race.race_name"
			},
			{
				name: "state",
				type: "choice",
				title: "State",
				accessor: "state",
				index: "lexical",
				fit: "shrink",
				config: {
					options: states,
					labelAccessor: "state",
					valueAccessor: "state"
				}
			},
			{
				name: "name",
				title: "Campaign Name",
				accessor: "campaign.candidate.full_name",
				index: "lexical"
			},
			{
				name: "medium",
				title: "Medium",
				accessor: "station.station_type",
				editable: true
			},
			{
				name: "callLetters",
				title: "Call Letters",
				accessor: "station.call_letters",
				editable: true
			},
			{
				name: "market",
				type: "choice",
				title: "Market",
				accessor: "station.market_id",
				fit: "shrink",
				config: {
					options: markets,
					labelAccessor: "name",
					valueAccessor: "id"
				},
				editable: true
			},
			{
				name: "amount",
				type: "currency",
				title: "Amount",
				accessor: "amount",
				editable: true
			},
			{
				name: "start",
				type: "date",
				title: "Start Date",
				accessor: "start_date",
				editable: true
			},
			{
				name: "end",
				type: "date",
				title: "End Date",
				accessor: "end_date",
				editable: true
			},
			{
				name: "spot_length",
				type: "number",
				title: "Spot Length",
				accessor: "spot_length",
				editable: true
			},
			{
				name: "cpp",
				type: "currency-value",
				title: "CPP",
				accessor: "candidate_cpp",
				editable: true
			},
			{
				name: "race_id",
				type: "hidden",
				accessor: "race_id",
				title: "Race ID"
			},
			{
				name: "is_dra",
				type: "hidden",
				accessor: "is_dra",
				title: "DRA"
			}
		],
		[markets]
	);

	const actions = useTableActions({
		save: rows => {
			return request({
				url: "/flights/update_all",
				body: { flights: rows },
				method: "PUT"
			});
		},
		delete: async rows => {
			for (let i = 0; i < rows.length; i++) {
				const id = rows[i].id;
				if (typeof id === "number") {
					await request({
						url: `/flights/${id}`,
						method: "DELETE"
					});
				}
			}
		},
		syncCpp: async rows => {
			const raceMarketIds = Array.from(
				new Set(
					rows.map(r => {
						return `${r.race_id}_${r.station.market_id}`;
					})
				)
			);

			const raceIds = Array.from(
				new Set(
					raceMarketIds
						.map(rm =>
							parseInt(
								rm.split("_")[0]
							)
						)
						.filter(id => id > 0)
				)
			);
			const marketIds = Array.from(
				new Set(
					raceMarketIds
						.map(rm =>
							parseInt(
								rm.split("_")[1]
							)
						)
						.filter(id => id > 0)
				)
			);
			if (raceIds.length > 1) {
				return {
					data: undefined,
					isResponse: true,
					success: false,
					errorMessage:
						"Flights must be from a single race"
				};
			}
			if (raceIds.length < 1) {
				return {
					data: undefined,
					isResponse: true,
					success: false,
					errorMessage:
						"No valid races attached to flights"
				};
			}

			if (PRESIDENTIAL) {
				if (marketIds.length > 1) {
					return {
						data: undefined,
						isResponse: true,
						success: false,
						errorMessage:
							"Flights must be from a single market"
					};
				}
			}

			const marketQuery =
				marketIds.length === 1
					? `&market_id=${marketIds[0]}`
					: "";

			const response = await request(
				`/race_markets/list?race_id=${raceIds[0]}${marketQuery}`
			);

			const raceMarkets = response.data;

			const data = rows.map((row, index) => {
				const raceMarket = raceMarkets.find(
					(rm: RaceMarket) =>
						rm.market_id ===
							row.station.market_id &&
						rm.race_id === row.race_id
				);
				if (!raceMarket) {
					return;
				}
				const candidateType = row.campaign?.candidate?.candidate_type;
				const cppSource = candidateType === "pac" ? "issue" : "candidate";
				const cppModifier = row.is_dra ? "dra" : "cpp";
				const property = `${cppSource}_${cppModifier}_${row.spot_length}`;

				const syncValue = raceMarket[property];
				const updated = {
					...row,
					candidate_cpp: syncValue
				};
				return updated;
			});

			return {
				data,
				success: true,
				errorMessage: null,
				isResponse: true
			};
		},
		syncCppLabel: "Sync CPP"
	});

	const props = useRemoteActionProps({
		request: "/flights/list",
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} listenForChangeMessages={true} />
			</ContentCard>
		</Base>
	);
};

export default Flights;
