import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { transparentize } from "polished";

import { request } from "../../utils";

import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import {
	Text,
	Dropdown,
	Form,
	InputRow,
	InputBox,
	Button
} from "../../components/inputs";
import Base from "../base";
import SplitBox, { SplitProps } from "../../components/split-box";
import ContentCard from "../../components/content-card";

import { ColumnCell, Row } from "../../types/data-table";
import Select2, { SelectOption } from "../../components/select";
import { useAppSelector } from "../../state/hooks";
import { Field, FieldArray } from "formik";
import Icon from "../../components/icon";

interface SplitPropsExtension {
	raceId: string | undefined;
}

const ContentWrapper = styled.div`
	max-width: 600px;
`;

const DropdownBox = styled(InputBox)`
	min-width: 400px;
`;

const ExpandingBox = styled(InputBox)`
	flex-grow: 1;
`;

const DeleteButton = styled.button`
	display: flex;
	min-width: 26px;
	width: 26px;
	height: 26px;
	background: transparent;
	border: none;
	color: ${p => p.theme.alert};
	padding: 7px;
	outline: none;
	border-radius: inherit;
	cursor: pointer;
	margin-top: auto;
	margin-bottom: 4px;
	&:focus {
		background: ${p => transparentize(0.8, p.theme.accentColor)};
	}
`;

const StyledSelect = styled(Select2)`
	min-width: 15rem;
`;

type RaceMarket = {
	candidate_cpp_30: number;
	candidate_cpp_60: number;
	candidate_dra_30: number;
	candidate_dra_60: number;
	issue_cpp_30: number;
	issue_cpp_60: number;
	issue_dra_30: number;
	issue_dra_60: number;
	market_id: number;
	market_name: string;
	race_id: number;
	race_name: string;
};

const Content = (props: SplitProps<SplitPropsExtension>) => {
	const teams = useAppSelector(state => state.data.teams);
	const teamsOptions = teams
		.filter(
			team =>
				!!team.race_id?.toString() &&
				team.race_id.toString() === props.raceId
		)
		.map(team => ({
			label: team.full_name,
			value: team.id
		}));
	return (
		<ContentWrapper>
			<Form onChange={props.edit} values={props.focusedRow}>
				<FieldArray name="team_weights">
					{({ push, remove, form }) => (
						<>
							<div
								style={{
									marginBottom:
										"1rem"
								}}
							>
								{form.values
									.team_weights
									?.length >
									0 &&
									form.values.team_weights.map(
										(
											team: any,
											index: number
										) => (
											<InputRow
												key={`${team.team_id}-${index}`}
											>
												<DropdownBox
													name={`team_weights.${index}.team_id`}
													title="Team"
													input={
														Dropdown
													}
													options={
														teamsOptions
													}
												/>
												<ExpandingBox
													name={`team_weights.${index}.weight`}
													title="Weight"
													input={
														Text
													}
												/>
												<Field
													type="hidden"
													name={`team_weights.${index}.id`}
												/>
												<DeleteButton
													type="button"
													onClick={() =>
														remove(
															index
														)
													}
												>
													<Icon name="thin-cross" />
												</DeleteButton>
											</InputRow>
										)
									)}
							</div>
							<Button
								onClick={() =>
									push({
										team_id: undefined,
										weight: 0,
										id: undefined
									})
								}
							>
								Add
							</Button>
						</>
					)}
				</FieldArray>
				<Field type="hidden" name="campaign_id" />
				<Field type="hidden" name="market" />
				<Field type="hidden" name="start_date" />
			</Form>
		</ContentWrapper>
	);
};

const Teams = () => {
	const races = useAppSelector(state => state.data.races);
	const [campaignOptions, setCampaignOptions] = useState<SelectOption[]>(
		[]
	);
	const [raceId, setRaceId] = useState<string>();
	const [candidateId, setCandidateId] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [raceMarkets, setRaceMarkets] = useState<RaceMarket[]>([]);

	const markets = useMemo(
		() =>
			raceMarkets.map(r => ({
				name: r.market_name,
				id: r.market_id
			})),
		[raceMarkets]
	);

	const raceOptions = useMemo(
		() =>
			races
				.map(race => ({
					label: race.race_name,
					value: race.id.toString()
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[races]
	);

	const raceSelect = useMemo(
		() => (
			<StyledSelect
				options={raceOptions}
				onChange={race => {
					if (
						Array.isArray(race) &&
						race.length > 0
					) {
						setRaceId(race[0]);
					} else if (typeof race === "string") {
						setRaceId(race);
					}
				}}
				value={raceId?.toString()}
				placeholder="Race..."
			/>
		),
		[raceOptions, raceId]
	);

	useEffect(() => {
		if (raceId === undefined) {
			return;
		}
		setCandidateId(undefined);
		setLoading(true);
		async function fetchDependencies() {
			const campaignsPromise = request({
				url: "/campaigns/list",
				query: {
					race_id: raceId
				}
			}).then(campaignsResponse => {
				if (!campaignsResponse.success) {
					setCampaignOptions([]);
					return;
				}
				const campaigns: SelectOption[] =
					campaignsResponse.data.campaigns.map(
						(c: any) => ({
							label: c.candidate
								.full_name,
							value: c.id.toString()
						})
					);
				campaigns.sort((a, b) =>
					a.label.localeCompare(b.label)
				);
				setCampaignOptions(campaigns);
				setCandidateId(undefined);
			});
			const raceMarketsPromise = request(
				`/race_markets/list?race_id=${raceId}`
			).then(response => {
				setRaceMarkets(response.data);
			});

			await Promise.all([
				campaignsPromise,
				raceMarketsPromise
			]);
		}
		fetchDependencies().finally(() => setLoading(false));
	}, [raceId]);

	const candidatesSelect = useMemo(
		() => (
			<StyledSelect
				isDisabled={raceId === undefined || loading}
				options={campaignOptions}
				onChange={campaign => {
					if (
						Array.isArray(campaign) &&
						campaign.length > 0
					) {
						setCandidateId(campaign[0]);
					} else if (
						typeof campaign === "string"
					) {
						setCandidateId(campaign);
					}
				}}
				value={candidateId?.toString()}
				placeholder="Campaign..."
			/>
		),
		[raceId, loading, campaignOptions, candidateId]
	);

	const columns: ColumnCell[] = useMemo(
		() => [
			{
				name: "market",
				type: "choice",
				title: "Market",
				accessor: "market",
				config: {
					options: markets,
					labelAccessor: "name",
					valueAccessor: "id"
				},
				editable: true
			},
			{
				name: "start_date",
				title: "Start Date",
				accessor: "start_date",
				type: "date",
				editable: true,
				content: cell => (
					<>
						{new Date(
							cell.value
						).toLocaleDateString(
							undefined,
							{ timeZone: "utc" }
						)}
					</>
				)
			},
			{
				fit: "shrink",
				name: "teams_count",
				title: "# Teams",
				accessor: "team_weights",
				content: cell => <>{cell.value?.length || 0}</>
			}
		],
		[markets]
	);

	const actions = useTableActions({
		addLabel: "Add Split",
		add: () => ({
			market: "",
			start_date: new Date().toLocaleDateString(),
			teams_count: 0
		}),
		createEach: row => {
			return request({
				url: "/teams/splits",
				body: {
					...row,
					race_id: raceId,
					campaign_id: candidateId
				},
				method: "POST"
			});
		},
		modifyEach: row => {
			return request({
				url: `/teams/splits`,
				body: {
					...row,
					race_id: raceId,
					campaign_id: candidateId
				},
				method: "PUT"
			});
		},
		deleteEach: row => {
			return request({
				url: `/teams/splits`,
				method: "DELETE",
				body: {
					...row,
					race_id: raceId,
					campaign_id: candidateId
				}
			});
		}
	});

	const headerInputs = useMemo(
		() => [raceSelect, candidatesSelect],
		[raceSelect, candidatesSelect]
	);

	const props = useRemoteActionProps({
		request: {
			url: "/teams/splits",
			query: {
				race_id: raceId,
				campaign_id: candidateId
			}
		},
		columns,
		pageSize: 50,
		actions,
		expand: true,
		headerInputs
	});

	return (
		<Base limited>
			<ContentCard>
				<SplitBox
					{...props}
					autoFocus
					title="Focused Split"
					notice="No split focused"
					content={Content}
					splitProps={{ raceId }}
				/>
			</ContentCard>
		</Base>
	);
};

export default Teams;
