import { useAppSelector } from "../../state/hooks";
import useTableActions from "../../hooks/use-table-actions";
import useRemoteActionProps from "../../hooks/use-remote-action-props";

import Base from "../base";

import ActionTable from "../../components/action-table";
import ContentCard from "../../components/content-card";

import { ColumnCell } from "../../types/data-table";
import { request } from "../../utils";

interface MarketsProps {
	raceId?: string;
}

const Markets = (marketsProps: MarketsProps) => {
	const overrideRaceId = marketsProps.raceId;

	let COLUMNS: ColumnCell[] = [
		{
			name: "market",
			type:"default",
			title: "Market",
			accessor: "name",
			fit: "none",
			editable: true
		}
	];

	const actions = useTableActions({
		addLabel: "Add Market",
		add: () => ({
			name: ""
		}),
		createEach: row => {
			console.log(row)
			return request({
				url: "/markets/create",
				body: row,
				method: "POST"
			})
		},
		modifyEach: row => {
			return request({
				url: "/markets/update",
				body: ({
					id: row.id,
					name: row.name
				}),
				method: "PUT"
			})
		},
		deleteEach: row => {
			return request({
				url: `/markets/${row.id}`,
				body: ({
					id: row.id
				}),
				method: "DELETE"
			})
		}
	});

	const props = useRemoteActionProps({
		request: `/markets/list`,
		columns: COLUMNS,
		pageSize: 50,
		actions,
		expand: true
	});

	console.log(props)
	return overrideRaceId ? (
		<ActionTable {...props} />
	) : (
		<Base limited>
			<ContentCard>
				<ActionTable {...props} />
			</ContentCard>
		</Base>
	);
};

export default Markets;
