import { useMemo } from "react";
import styled from "styled-components";

import { request } from "../../../utils";

import states from "../../../data/states.json";

import useRequest from "../../../hooks/use-request";
import useTableActions from "../../../hooks/use-table-actions";
import useRemoteActionProps from "../../../hooks/use-remote-action-props";

import {
	Text,
	Dropdown,
	Form,
	InputRow,
	InputBox
} from "../../../components/inputs";
import Base from "../../base";
import SplitBox, { SplitProps } from "../../../components/split-box";
import ContentCard from "../../../components/content-card";

import { ColumnCell } from "../../../types/data-table";
import { PRESIDENTIAL } from "../../../data/constants";

interface SplitPropsExtension {
	races: any[];
}

const ContentWrapper = styled.div`
	max-width: 600px;
`;

const DropdownBox = styled(InputBox)`
	min-width: 150px;
`;

const ExpandingBox = styled(InputBox)`
	flex-grow: 1;
`;

const Content = (props: SplitProps<SplitPropsExtension>) => {
	return (
		<ContentWrapper>
			<Form values={props.focusedRow} onChange={props.edit}>
				<InputRow>
					{!PRESIDENTIAL &&<DropdownBox
						name="state"
						title="State"
						input={Dropdown}
						options={states}
						labelAccessor="state"
						valueAccessor="state"
					/>}
					<ExpandingBox
						name="full_name"
						title="Name"
						input={Text}
					/>
				</InputRow>
				<InputRow>
					<ExpandingBox
						name="keywords"
						title="Keywords"
						input={Text}
					/>
				</InputRow>
			</Form>
		</ContentWrapper>
	);
};

function sortRaces(a: any, b: any) {
	if (a.race_name === b.race_name) return 0;
	if (a.race_name < b.race_name) return -1;
	return 1;
}

interface TeamProps {
	raceId?: string;
}

const RaceTeams = (teamProps: TeamProps) => {
	const raceId = teamProps.raceId;

	const modeColumns: ColumnCell[] = PRESIDENTIAL ? [] :
	[
		{
			name: "state",
			type: "choice",
			title: "State",
			accessor: "state",
			index: "lexical",
			config: {
				options: states,
				labelAccessor: "state",
				valueAccessor: "state"
			}
		}
	]

	const columns: ColumnCell[] = [
		...modeColumns,
		{
			name: "name",
			title: "Name",
			accessor: "full_name"
		},
		{
			name: "keywords",
			title: "Keywords",
			accessor: "keywords"
		},
		{
			name: "logo",
			type: "hidden",
			title: "Logo",
			accessor: "logo"
		}
	];

	const actions = useTableActions({
		addLabel: "Add Team",
		add: () => ({
			state: "US",
			full_name: "",
			keywords: "",
			race_id: raceId,
			team_id: -1
		}),
		createEach: row => {
			return request({
				url: "/teams/create",
				body: {
					...row,
					keywords: row.keywords || null,
				}
			});
			
		},
		modifyEach: row => {
			console.log(
				request({
					url: `/teams/${row.team_id}`,
					body: {
						...row,
						keywords: row.keywords || null,
					},
					method: "PUT"
				}));
		},
		deleteEach: row => {
			return request({
				url: `/teams/${row.team_id}`,
				method: "DELETE"
			});
		}
	});

	const requestQuery = raceId ? `?race_id=${raceId}` : "";

	const props = useRemoteActionProps({
		request: `/teams/list${requestQuery}`,
		process: row => ({
			...row,
			race_id:
				row.race_id === null
					? "-1"
					: String(row.race_id),
			team_id: row.id === null ? "-1" : String(row.id)
		}),
		columns,
		pageSize: 50,
		actions,
		expand: true
	});

	return (
			<ContentCard>
				<SplitBox
					{...props}
					autoFocus
					title="Focused Team"
					notice="No team focused"
					content={Content}
				/>
			</ContentCard>
	);
};

export default RaceTeams;
