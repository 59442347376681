import { useEffect, useState } from "react";
import styled, { css, withTheme } from "styled-components";

import { AugmentedRequestConfig } from "../../types/utils";
import { QuerySettings } from "../../types/reports";
import { format, getRoundDate, request } from "../../utils";
import useGraphConfigCollection, {
	GraphConfigCollection
} from "../../hooks/use-graph-config-collection";
import {
	Bar,
	ForkedBar,
	GraphBox,
	GraphRow,
	HorizontalBar,
	Pie,
	Table
} from "../../components/viz";
import { CellRuntime, Column } from "../../components/viz/graphs/configs/table";
import useGraphBox from "../../hooks/use-graph-box";
import { DataPoint } from "../../components/viz/apply-categorization";
import { propsAndQueryParamsProxy } from "../../utils/standalone-utils";
import { MEDIA_TYPES } from "../../data/constants";
import { DOWNLOAD_PDF_DIV_ID } from "../../utils/download-pdf";

interface GraphsProps {
	querySettings: QuerySettings;
	collection: GraphConfigCollection;
	theme: any;
	raceName?: string;
	onSpendByPartyChange: (spend: any) => void;
}

const media = ["radio", "tv", "cable", "addressable"];

const monthNames = [
	"Jan",
	"Feb",
	"March",
	"April",
	"May",
	"June",
	"July",
	"Aug",
	"Sept",
	"Oct",
	"Nov",
	"Dec"
];

const DateTitle = styled.div`
	margin-top: 1em;
`;

const FullHeightGraphBox = styled(GraphBox)`
	min-height: 330px;
`;

const RaceTitle = styled.div`
	font-size: 2em;
	font-weight: bold;
`;

const SnapshotTitle = styled.div`
	color: dimgray;
	font-size: 1em;
	font-weight: bold;
	text-transform: uppercase;
`;

const MissingInfo = styled.div`
	font-size: 1.5em;
	font-weight: bold;
	text-align: center;
`;

const TableGraphBox = styled(GraphBox)``;

const Title = styled.div`
	margin: 2em 0;
	position: relative;
	text-align: center;
	width: 100%;
`;

const titleStyle = css`
	font-size: 14px;
	color: #186a9f;
	background-color: white;
	text-transform: uppercase;
	font-weight: normal;
`;

const Graphs = (props: GraphsProps) => {
	const { selections } = props.querySettings.selectionPayload!,
		collection = props.collection,
		colors = props.theme.graphs.palettes.colors,
		partyColors = props.theme.graphs.palettes.parties;
	const raceId = selections["raceId"];

	const [spendByParty, setSpendByParty] = useState();

	useEffect(() => {
		if (spendByParty) {
			props.onSpendByPartyChange(spendByParty);
		}
	}, [spendByParty]);

	const statesQuery = selections.state
		? { states: [selections.state] }
		: {};

	const query = {
		media,
		range: props.querySettings.normalizedDateRange
			.map(ts => new Date(ts).toISOString())
			.join(),
		...statesQuery
	};

	const mkRequest = (
		url: string,
		mode: "teams" | "campaigns",
		process?: (data: any) => any
	): AugmentedRequestConfig => {
		const updatedQuery = { ...query } as any;
		if (mode === "teams" && selections.teams) {
			updatedQuery.teams = selections.teams.map(
				(t: any) => t.id
			);
		}
		if (selections.campaigns) {
			updatedQuery.campaigns = selections.campaigns;
		}
		return {
			url: `/races/${raceId}/graphs${url}`,
			query: updatedQuery,
			cacheHash: props.querySettings.hash,
			process
		};
	};

	const processSOVDataByMarket = (sov: any) => {
		const markets = [];

		for (const market in sov) {
			if (!sov.hasOwnProperty(market)) continue;

			const campaigns = processSOVData(sov[market]);
			markets.push({ market, campaigns });
		}

		return markets;
	};

	const processAddressableData = (sov: any) => {
		const addressable = [];
		for (const name in sov) {
			if (!sov.hasOwnProperty(name)) continue;
			const campaigns = sov[name];
			addressable.push({
				name:
					MEDIA_TYPES.find(
						medium => medium.value === name
					)?.label || name,
				campaigns
			});
		}
		return addressable;
	};

	const processSOVData = (sov: any) => {
		const campaigns = [];

		for (const k in sov) {
			if (!sov.hasOwnProperty(k)) continue;

			const candidates = sov[k];

			for (const candidate of candidates) {
				const entry = {
					name: candidate.candidate_name,
					tv: 0,
					radio: 0,
					cable: 0,
					addressable: 0,
					grp: 0,
					total: 0
				} as any;

				for (const medium of media) {
					const d = candidate[medium] || {
						grp: 0,
						usd: 0
					};

					entry[medium] = d.usd;
					entry.total += d.usd;
					entry.grp += d.grp;
				}

				if (entry.total <= 0) continue;

				campaigns.push(entry);
			}
		}

		return campaigns;
	};

	const filterColumns = (columns: Column[]): Column[] => {
		const mediaMap = media.reduce((map, entry) => {
			map[entry.toLowerCase()] = true;
			return map;
		}, {} as any);

		return columns.filter(column => {
			return !(
				(column.accessor === "tv" ||
					column.accessor === "cable" ||
					column.accessor === "radio" ||
					column.accessor === "addressable") &&
				!mediaMap.hasOwnProperty(column.accessor)
			);
		});
	};

	const displayUsd = (rt: CellRuntime) => format.wholeDollar(rt.value);
	const displayNumber = (rt: CellRuntime) => format.number(rt.value);
	const displayHeader = (rt: CellRuntime) => {
		if (rt.index === 0) {
			return rt.print(rt.section.group!.label).weight(700);
		}

		if (rt.index < rt.columns.length - 1) return rt.print(rt.value);

		const total = rt.section.rows.reduce((tot, row) => {
			return tot + row.cells[rt.index].value;
		}, 0);

		return rt.display(total).weight(700);
	};

	const teamsMediaSpend = useGraphBox({
		graph: Bar,
		title: "Spend by Team",
		request: mkRequest("/spending/medium", "teams"),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.label}: ${format.dollar(d.value as number)}`,
		groupLabel: (d: DataPoint[]) =>
			format.dollar(
				d.reduce(
					(acc, data) =>
						acc + (data.value as number),
					0
				)
			),
		layout: {
			colors,
			colorIndices: ["cable", "radio", "tv", "addressable"]
		},
		track: collection.track("team-spending-medium")
	});

	const teamMediaTypeSpend = useGraphBox({
		graph: ForkedBar,
		title: "Media Type Spend by Team",
		request: mkRequest("/spending/party", "teams", data => {
			Object.keys(data).forEach(party => {
				data[party].forEach((media: any, i: number) => {
					if (
						media.hasOwnProperty(
							"addressable"
						)
					) {
						// "addressable" is too long in table - change to "addr"
						media["addr"] =
							media["addressable"];
						delete media["addressable"];
					}
				});
			});
			return data;
		}),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.trace[d.trace.length - 1].label} (${
				d.trace[1].label
			}): ${format.dollar(d.value as number)}`,
		groupLabel: (d: DataPoint[]) =>
			format.dollar(
				d.reduce(
					(acc, data) =>
						acc + (data.value as number),
					0
				)
			),
		layout: {
			labelKey: "candidate_name",
			order: [2, 0, 1],
			colors: partyColors
		},
		track: collection.track("team-spending-party")
	});

	const teamUsdMarket = useGraphBox({
		graph: HorizontalBar,
		title: "USD by Market",
		request: mkRequest("/spending/sov/filtered", "teams"),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.label}: ${format.dollar(d.value as number)}`,
		groupLabel: (d: DataPoint[]) =>
			format.dollar(
				d.reduce(
					(acc, data) =>
						acc + (data.value as number),
					0
				)
			),
		labelAllPoints: true,
		layout: {
			labelKey: "candidate",
			valueKey: "usd",
			colors: partyColors,
			colorRule: {
				track: [1, 2]
			}
		},
		track: collection.track("team-usd-market")
	});

	const teamsSov = useGraphBox({
		title: "Share of Voice by Team",
		request: mkRequest("/spending/sov", "teams"),
		box: FullHeightGraphBox,
		graphs: [
			{
				component: Pie,
				subheading: "GRPs",
				tooltips: d =>
					`${d.trace[1].label} (${
						d.trace[0].label
					}): ${format.number(
						d.value as number
					)}`,
				layout: {
					labelKey: "candidate_name",
					valueKey: "grp",
					colors: partyColors,
					uniquePoints: true
				},
				track: collection.track("team-sov-grp")
			},
			{
				component: Pie,
				subheading: "USD",
				tooltips: d =>
					`${d.trace[1].label}, ${d.label} (${
						d.trace[0].label
					}): ${format.dollar(
						d.value as number
					)}`,
				layout: {
					legends: {
						level: 1
					},
					labelKey: "candidate_name",
					level: 1,
					shortValueKey: "usd",
					colors: partyColors,
					uniquePoints: true
				},
				track: collection.track("team-sov-usd")
			}
		]
	});

	const mediaSpend = useGraphBox({
		graph: Bar,
		title: "Spend by Campaign",
		request: mkRequest("/spending/medium", "campaigns"),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.label}: ${format.dollar(d.value as number)}`,
		groupLabel: (d: DataPoint[]) =>
			format.dollar(
				d.reduce(
					(acc, data) =>
						acc + (data.value as number),
					0
				)
			),
		layout: {
			colors,
			colorIndices: ["cable", "radio", "tv", "addressable"]
		},
		track: collection.track("spending-medium")
	});

	const partySpend = useGraphBox({
		graph: ForkedBar,
		title: "Media Type Spend by Campaign",
		request: mkRequest("/spending/party", "campaigns", data => {
			const aggByParty: any = {};
			Object.keys(data).forEach(party => {
				aggByParty[party] = 0;
				data[party].forEach((media: any, i: number) => {
					if (
						media.hasOwnProperty(
							"addressable"
						)
					) {
						// "addressable" is too long in table - change to "addr"
						media["addr"] =
							media["addressable"];
						delete media["addressable"];
					}
					Object.values(media).forEach(
						(v: any) => {
							if (!isNaN(v)) {
								aggByParty[
									party
								] += v;
							}
						}
					);
				});
			});
			setSpendByParty(aggByParty);
			return data;
		}),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.trace[d.trace.length - 1].label} (${
				d.trace[1].label
			}): ${format.dollar(d.value as number)}`,
		groupLabel: (d: DataPoint[]) =>
			format.dollar(
				d.reduce(
					(acc, data) =>
						acc + (data.value as number),
					0
				)
			),
		layout: {
			labelKey: "candidate_name",
			order: [2, 0, 1],
			colors: partyColors
		},
		track: collection.track("spending-party")
	});

	const usdMarket = useGraphBox({
		graph: HorizontalBar,
		title: "USD by Market",
		request: mkRequest("/spending/sov/filtered", "campaigns"),
		box: FullHeightGraphBox,
		tooltips: d =>
			`${d.label}: ${format.dollar(d.value as number)}`,
		groupLabel: (d: DataPoint[]) =>
			format.dollar(
				d.reduce(
					(acc, data) =>
						acc + (data.value as number),
					0
				)
			),
		labelAllPoints: true,
		layout: {
			labelKey: "candidate",
			valueKey: "usd",
			colors: partyColors,
			colorRule: {
				track: [1, 2]
			}
		},
		track: collection.track("usd-market")
	});

	const campaignsSov = useGraphBox({
		title: "Share of Voice by Campaign",
		request: mkRequest("/spending/sov", "campaigns"),
		box: FullHeightGraphBox,
		graphs: [
			{
				component: Pie,
				subheading: "GRPs",
				tooltips: d =>
					`${d.trace[1].label} (${
						d.trace[0].label
					}): ${format.number(
						d.value as number
					)}`,
				layout: {
					labelKey: "candidate_name",
					valueKey: "grp",
					colors: partyColors,
					uniquePoints: true
				},
				track: collection.track("sov-grp")
			},
			{
				component: Pie,
				subheading: "USD",
				tooltips: d =>
					`${d.trace[1].label}, ${d.label} (${
						d.trace[0].label
					}): ${format.dollar(
						d.value as number
					)}`,
				layout: {
					legends: {
						level: 1
					},
					labelKey: "candidate_name",
					level: 1,
					shortValueKey: "usd",
					colors: partyColors,
					uniquePoints: true
				},
				track: collection.track("sov-usd")
			}
		]
	});

	const dateRange = props.querySettings.dateRange
		.map(d =>
			new Date(d).toLocaleDateString("en-US", {
				timeZone: "UTC"
			})
		)
		.join(" - ");

	const overview = useGraphBox({
		graph: Table,
		title: `Snapshot\u00a0\u00a0(${props.raceName})\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0${dateRange}`,
		request: mkRequest("/spending/sov/media", "campaigns", d => {
			return processSOVData(d);
		}),
		// request: getTableData,
		bare: true,
		box: TableGraphBox,
		layout: {
			legends: null
		},
		titleStyle: titleStyle,
		store: {
			display: displayUsd,
			columns: filterColumns([
				{ title: "", accessor: "name", display: null },
				{ title: "TV", accessor: "tv", align: "end" },
				{
					title: "GRP",
					accessor: "grp",
					align: "end",
					display: displayNumber
				},
				{
					title: "Cable",
					accessor: "cable",
					align: "end"
				},
				{
					title: "Radio",
					accessor: "radio",
					align: "end"
				},
				{
					title: "Addr.",
					accessor: "addressable",
					align: "end"
				},
				{
					title: "Total",
					accessor: "total",
					align: "end"
				}
			]),
			fontSize: 14,
			rowBorders: true,
			themeColor: "#80a4c6"
		},
		getHeight: d => (d.length + 1) * 25,
		track: collection.track("top-spenders")
	});

	const marketSpend = useGraphBox({
		graph: Table,
		title: "",
		request: mkRequest("/spending/sov/market", "campaigns", d => {
			return processSOVDataByMarket(d);
		}),
		bare: true,
		box: TableGraphBox,
		layout: {
			legends: null,
			labelKey: "market"
		},
		store: {
			header: displayHeader,
			display: displayUsd,
			columns: filterColumns([
				{
					title: "Campaign",
					accessor: "name",
					display: null
				},
				{ title: "TV", accessor: "tv", align: "end" },
				{
					title: "GRP",
					accessor: "grp",
					align: "end",
					display: displayNumber
				},
				{
					title: "Cable",
					accessor: "cable",
					align: "end"
				},
				{
					title: "Radio",
					accessor: "radio",
					align: "end"
				},
				{
					title: "Total",
					accessor: "total",
					align: "end"
				}
			]),
			themeColor: props.theme.graphBackground
		},
		getHeight: d => {
			const rows = d.reduce((tot: number, dt: any) => {
				return tot + 1 + dt.campaigns.length;
			}, 0);

			return rows * 25;
		},
		track: collection.track("market-spend")
	});

	const addressableSpend = useGraphBox({
		graph: Table,
		title: "",
		request: mkRequest(
			"/spending/sov/addressable",
			"campaigns",
			d => {
				return processAddressableData(d);
			}
		),
		bare: true,
		box: TableGraphBox,
		layout: {
			legends: null,
			labelKey: "name"
		},
		store: {
			header: displayHeader,
			display: displayUsd,
			columns: filterColumns([
				{
					title: "Campaign",
					accessor: "candidate_name",
					display: null
				},
				{
					title: "Total",
					accessor: "total",
					align: "start"
				}
			]),
			themeColor: props.theme.graphBackground
		},
		getHeight: d => {
			const rows = d.reduce((tot: number, dt: any) => {
				return tot + 1 + dt.campaigns.length;
			}, 0);

			return rows * 25;
		},
		track: collection.track("addressable-spend")
	});

	return (
		<>
			{selections.teams.length > 0 && (
				<>
					<GraphRow>{teamsMediaSpend}</GraphRow>
					<GraphRow>
						{teamMediaTypeSpend}
					</GraphRow>
					<GraphRow>{teamUsdMarket}</GraphRow>
					<GraphRow>{teamsSov}</GraphRow>
				</>
			)}
			<GraphRow>{mediaSpend}</GraphRow>
			<GraphRow>{partySpend}</GraphRow>
			<GraphRow>{usdMarket}</GraphRow>
			<GraphRow>{campaignsSov}</GraphRow>
			<GraphRow breakBefore="page">{overview}</GraphRow>
			<GraphRow breakBefore="page">{marketSpend}</GraphRow>
			<GraphRow>{addressableSpend}</GraphRow>
		</>
	);
};

function parseDate(dateString: string | null) {
	if (dateString) {
		const millis = parseInt(dateString);
		if (!isNaN(millis) && isFinite(millis)) {
			return new Date(millis);
		}
	}
	return null;
}

export function getDateRange(params: any) {
	const startDateFromQuery: Date | null = parseDate(params.start_date);
	const endDateFromQuery: Date | null = parseDate(params.end_date);

	if (startDateFromQuery && endDateFromQuery) {
		return [
			startDateFromQuery.getTime(),
			endDateFromQuery.getTime()
		] as [number, number];
	}
	const startDate = getRoundDate(Date.now());
	const endDate = getRoundDate(startDate, 7);
	return [startDate.getTime(), endDate.getTime()] as [number, number];
}

const AgencyLogo = styled.img`
	object-fit: contain;
	position: absolute;
	height: 77.5px;
	top: 0;
	left: 0;
`;

const Content = withTheme((props: any) => {
	const params = propsAndQueryParamsProxy(props);
	const [raceName, setRaceName] = useState<string>("");
	const [raceShortName, setRaceShortName] = useState<string>("");
	const [agencyLogo, setAgencyLogo] = useState<string>("");

	const dateRange = getDateRange(params);
	const raceId: any = params.race_id;
	const state: any = params.state;
	const agencyId: any = params.agency_id;

	const [teams, setTeams] = useState([]);
	const [spendByParty, setSpendByParty] = useState();

	useEffect(() => {
		if (raceId) {
			const raceRequest = request({
				url: `/races/${raceId}`
			});
			const teamsRequest =
				params.campaigns?.length > 0
					? Promise.resolve({
							data: [],
							success: true,
							errorMessage: null,
							isResponse: true
					  } as any)
					: request({
							url: "/teams/list",
							query: {
								race_id: raceId
							}
					  });
			Promise.all([raceRequest, teamsRequest]).then(
				([raceResponse, teamsResponse]) => {
					if (!raceResponse.success) return;
					setRaceName(
						`${raceResponse.data.race_name} ${raceResponse.data.year}`
					);
					setRaceShortName(
						raceResponse.data.race_name
					);
					setTeams(teamsResponse.data);
				}
			);
		}
	}, [params.campaigns, raceId]);

	useEffect(() => {
		if (!agencyId) {
			setAgencyLogo("");
			return;
		}
		request({
			url: `/agencies/${agencyId}`
		}).then(response => {
			if (!response.success) return;
			if (response.data.logo) {
				setAgencyLogo(response.data.logo);
			}
		});
	}, [agencyId]);

	const collection = useGraphConfigCollection();

	const startDate = new Date(dateRange[0]);
	const endDate = new Date(dateRange[1]);

	const renderMissingInfo = () => {
		if (!raceId) {
			return <MissingInfo>No race ID set</MissingInfo>;
		}
	};

	const stateString = state ? ` (${state})` : "";

	function renderAgencyLogo() {
		if (!agencyLogo) return undefined;
		return <AgencyLogo src={agencyLogo} />;
	}

	function renderSpendByParty() {
		if (!spendByParty || !Object.keys(spendByParty).length) {
			return undefined;
		}

		const partyColors = props.theme.graphs.palettes.parties;

		const total = Object.keys(spendByParty).reduce(
			(totalSpend, nextParty) =>
				totalSpend + spendByParty[nextParty],
			0
		);
		return (
			<table
				style={{
					position: "absolute",
					right: "1rem",
					top: 0
				}}
			>
				<tbody>
					{Object.keys(spendByParty).map(
						party => {
							const color =
								partyColors[
									party
								] ||
								partyColors[
									"other"
								] ||
								"black";
							return (
								<tr>
									<th
										style={{
											color,
											textAlign: "right"
										}}
									>
										<strong>
											{
												party
											}
										</strong>
									</th>
									<td
										style={{
											textAlign: "right"
										}}
									>
										{format.wholeDollar(
											spendByParty[
												party
											]
										)}
									</td>
								</tr>
							);
						}
					)}
				</tbody>
				<tfoot>
					<tr>
						<th
							style={{
								textAlign: "right"
							}}
						>
							<strong>Total</strong>
						</th>
						<td
							style={{
								textAlign: "right"
							}}
						>
							{format.wholeDollar(
								total
							)}
						</td>
					</tr>
				</tfoot>
			</table>
		);
	}

	return (
		<div style={{ overflow: "auto" }} id={DOWNLOAD_PDF_DIV_ID}>
			<Title>
				<SnapshotTitle>Daily Snapshot</SnapshotTitle>
				<RaceTitle>
					{raceName}
					{stateString}
				</RaceTitle>
				<DateTitle>
					{`${
						monthNames[
							startDate.getUTCMonth()
						]
					} ${startDate.getUTCDate()} - ${
						monthNames[
							endDate.getUTCMonth()
						]
					} ${endDate.getUTCDate()} ${endDate.getUTCFullYear()}`}
				</DateTitle>
				{renderSpendByParty()}
				{renderAgencyLogo()}
			</Title>

			{raceId && (
				<Graphs
					querySettings={{
						dateRange,
						hash: 0,
						normalizedDateRange: dateRange,
						selectionPayload: {
							errorMessage: "",
							errors: [],
							selection: [],
							selections: {
								campaigns: params.campaigns,
								raceId,
								teams,
								state
							},
							valid: true
						}
					}}
					collection={collection}
					raceName={raceShortName}
					onSpendByPartyChange={setSpendByParty}
					theme={props.theme}
				/>
			)}
			{!raceId && renderMissingInfo()}
		</div>
	);
});

const DailySnapshot = (props: any) => {
	return <Content {...props} />;
};

export default DailySnapshot;
