import { transparentize } from "polished";
import styled, { css } from "styled-components";
import { useHistory } from "react-router";

import Icon from "../components/icon";
import Logo from "../components/logo";
import Link from "../components/link";
import Button from "../components/inputs/button";
import Context, { ContextItem } from "../components/context";
import { PRESIDENTIAL } from "../data/constants";

interface BaseProps {
	children: any;
	flush?: boolean;
	limited?: boolean;
	className?: string;
}

interface HeaderLinkProps {
	to?: string;
	children: any;
}

const REPORT_CENTER_ITEMS: ContextItem[] = [
	{
		label: "Spending",
		url: "/reports/spending"
	},
	{
		label: "OTT",
		url: "/reports/ott"
	},
	{
		label: "OTTv2",
		url: "/reports/ottv2"
	},
	{
		label: "CMAG",
		url: "/reports/cmag"
	},
	{
		label: "Daily Snapshots",
		url: "/reports/snapshots"
	},
	{
		label: "Weekly Breakdown",
		url: "/reports/weekly-grp"
	}
];

const SETTINGS_ITEMS: ContextItem[] = [
	{
		label: "Campaigns",
		url: "/settings/campaigns"
	},
	{
		label: "Teams",
		url: "/settings/teams"
	},
	{
		label: "Team Splits",
		url: "/settings/team_splits"
	},
	{
		label: "Agencies",
		url: "/settings/agencies"
	},
	{
		label: "Markets",
		url: "/settings/markets"
	},
	{
		label: "Offices",
		url: "/settings/offices"
	},
	{
		label: "Media",
		url: "/settings/media"
	},
	{
		label: "Stations",
		url: "/settings/stations"
		/*children: [
			{
				label: "TV",
				url: "/settings/stations/tv"
			},
			{
				label: "Cable",
				url: "/settings/stations/cable"
			},
			{
				label: "Radio",
				url: "/settings/stations/radio"
			}
		]*/
	},
	{
		label: "Emails",
		url: "/settings/emails"
	},
	{
		label: "Parties",
		url: "/settings/parties"
	},
	{
		label: "Public Users",
		url: "/settings/users"
	}
];

const Wrapper = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	color: ${p => p.theme.color};
	background: ${p => p.theme.background};
`;

const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	position: sticky;
	top: 0;
	width: 100%;
	padding: 10px 20px 0;
	height: ${p => p.theme.headerHeight};
	background: ${p => p.theme.background};
	z-index: 1000;

	&:before {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 10px;
		background: linear-gradient(
			to bottom,
			${p => p.theme.background} 20%,
			${p => transparentize(1, p.theme.background)}
		);
	}
`;

const HeaderLeft = styled.div`
	display: flex;
	align-items: center;
	margin-right: 20px;
`;

const LogoLink = styled(Link)`
	width: 90px;
`;

const HeaderContent = styled.div`
	display: flex;
	flex-grow: 1;
	height: 100%;
	padding: 10px 0;
`;

const Nav = styled.nav`
	display: flex;
	align-items: center;
`;

const HEADER_NAV_STYLE = css`
	position: relative;
	padding: 10px;
	text-transform: uppercase;
	font-weight: bold;
	user-select: none;
`;

const HeaderNavLink = styled(Link)`
	${HEADER_NAV_STYLE};
	color: inherit;

	&.active:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: -3px;
		right: -3px;
		border-radius: ${p => p.theme.borderRadius};
		background: ${p => p.theme.activeLinkBackground};
	}
`;

const HeaderNavWrapper = styled.div`
	${HEADER_NAV_STYLE};
`;

const HeaderNavContent = styled.div`
	position: relative;
`;

const HeaderNav = (props: HeaderLinkProps) => {
	if (props.to) {
		return (
			<HeaderNavLink to={props.to}>
				<HeaderNavContent>
					{props.children}
				</HeaderNavContent>
			</HeaderNavLink>
		);
	}

	return (
		<HeaderNavWrapper>
			<HeaderNavContent>{props.children}</HeaderNavContent>
		</HeaderNavWrapper>
	);
};

const ReportCenterLabelWrapper = styled.div`
	svg {
		height: 1em;
		margin: 0 0.4em;
		opacity: 0.5;
	}
`;

const ReportCenterLabel = () => {
	const h = useHistory();

	const item =
		REPORT_CENTER_ITEMS.find(itm => {
			return itm.url === h.location.pathname;
		}) || null;

	if (item) {
		return (
			<ReportCenterLabelWrapper>
				Report Center
				<Icon name="thick-chevron-right" />
				{item.label}
			</ReportCenterLabelWrapper>
		);
	}

	return <>Report Center</>;
};

const HeaderRight = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const Main = styled.main<BaseProps>`
	display: flex;
	flex-direction: column;
	padding: ${p => (p.flush ? "10px 20px 0" : "10px 20px 20px")};
	flex-grow: 1;

	${p =>
		p.limited
			? {
					maxHeight: `calc(100vh - ${p.theme.headerHeight})`,
					overflow: "hidden"
			  }
			: null};
`;

function localPresidentialToggle() {
	const swap: [string, string] = ["local", "presidential"]; // change from local to presidential
	if (PRESIDENTIAL) {
		swap.reverse(); // change from presidential to local
	}
	window.location.href = `${
		window.location.protocol
	}//${window.location.host.replace(...swap)}`;
}

const Base = (props: BaseProps) => (
	<Wrapper className={props.className}>
		<Header>
			<HeaderLeft>
				<LogoLink to="/">
					<Logo />
				</LogoLink>
			</HeaderLeft>
			<HeaderContent>
				<Nav>
					<HeaderNav to="/races">Races</HeaderNav>
					<HeaderNav to="/flights">
						Flights
					</HeaderNav>
					<Context
						label={ReportCenterLabel}
						frame={[0, 5]}
						items={REPORT_CENTER_ITEMS}
					>
						<HeaderNav>
							<ReportCenterLabel />
						</HeaderNav>
					</Context>
				</Nav>
			</HeaderContent>
			<HeaderRight>
				<Button onClick={localPresidentialToggle}>
					{PRESIDENTIAL
						? "local"
						: "presidential"}
				</Button>
				<Context
					anchor="right"
					label="Settings"
					items={SETTINGS_ITEMS}
				>
					<Button style="subtle">Settings</Button>
				</Context>
			</HeaderRight>
		</Header>
		<Main {...props}>{props.children}</Main>
	</Wrapper>
);

export default Base;
