import {
	casing,
	isObject
} from "@qtxr/utils";

import { API_URL } from "../data/constants";

import { MOCKED_REQUESTS } from "../data/mock";

import {
	Response,
	BoxFrame,
	RequestInfo,
	BoxFrameArgs,
	BoxFrameKeys,
	LoadingState,
	RequestConfig,
	LoadingStateType
} from "../types/utils";

const BOX_FRAME_ORDER: BoxFrameKeys[] = [
	"top",
	"right",
	"bottom",
	"left"
];

const POSTFIXES = [
	{ order: 1e12, postfix: "T" },
	{ order: 1e9, postfix: "B" },
	{ order: 1e6, postfix: "M" },
	{ order: 1e3, postfix: "k" }
];

const cleanRegex = (str: string): string => {
	return str.replace(/[$^()[\]/\\{}.*+?|]/g, "\\$&");
};

const getUTCDate = (dateValue: string | number) => {
	if (!dateValue)
		return new Date();

	if (typeof dateValue == "number")
		return new Date(dateValue * 1000);

	let utcString = dateValue
		.replace(/([+-]\d{1,2}:\d{1,2})$/, "Z");

	if (utcString[utcString.length - 1] !== "Z")
		utcString += " UTC";

	return new Date(utcString);
};

const getRoundDate = (dateValue: number | string | Date, dayOffset: number = 0) => {
	const d = dateValue instanceof Date ?
		dateValue :
		new Date(dateValue);

	return new Date(
		d.getFullYear(),
		d.getMonth(),
		d.getDate() + dayOffset
	);
};

const getDateString = (dateValue: number | string | Date): string => {
	const d = dateValue instanceof Date ?
		dateValue :
		new Date(dateValue);

	return `${
		d.getFullYear()
	}-${
		String(d.getMonth() + 1).padStart(2, "0")
	}-${
		String(d.getDate()).padStart(2, "0")
	}`;
};

const mkLoadingState = (
	type: LoadingStateType,
	message?: string
): LoadingState => {
	const state = {
		idle: false,
		loading: false,
		success: false,
		placeholder: "",
		errorMessage: ""
	} as LoadingState;

	switch (type) {
		case "idle":
			state.idle = true;
			state.placeholder = message || "";
			return state;

		case "loading":
			state.loading = true;
			return state;

		case "success":
			state.success = true;
			return state;

		case "error":
			state.errorMessage = message || "Unspecified error";
			return state;
	}
};

const resolveRequest = (info: RequestInfo): Request => {
	if (typeof info == "string")
		return new Request(wrapUrl(info), {credentials: "include", redirect: "manual"});

	const processedInfo = assign({}, info);
	processedInfo.url = wrapUrl(processedInfo.url);

	if (isObject(processedInfo.body))
		processedInfo.body = JSON.stringify(processedInfo.body);

	if (processedInfo.query) {
		let q = processedInfo.query,
			u = new URL(processedInfo.url)

		for (const k in q) {
			if (q.hasOwnProperty(k))
				u.searchParams.set(k, q[k]);
		}

		processedInfo.url = u.href;
		delete processedInfo.query;
	}

	if (!processedInfo.method) {
		processedInfo.method = processedInfo.body ?
			"POST" :
			"GET";
	}

	processedInfo.credentials = "include"

	const req = new Request(
		processedInfo.url,
		processedInfo as RequestConfig
	);

	if (processedInfo.body) {
		const contentType = req.headers.get("content-type");

		if (!contentType || contentType.indexOf("text/plain") !== -1)
			req.headers.set("content-type", "application/json");
	}

	return req;
};

const wrapUrl = (url: string): string => {
	let u = url.trim();

	if (isAbsoluteUrl(u))
		return u;

	const prefixEx = /^@([\w-]+)(.+)/.exec(u),
		prefix = prefixEx ?
			prefixEx[1] :
			null;

	if (prefixEx)
		u = prefixEx[2];

	// Add more prefixed url handlers here if necessary
	switch (prefix) {
		default:
			u = mergeUrl(API_URL, u);
	}

	if (isAbsoluteUrl(u))
		return u;

	return mergeUrl(window.location.origin, u);
};

const isAbsoluteUrl = (url: string): boolean => {
	return /^https?:\/\//.test(url);
};

const mergeUrl = (...components: string[]): string => {
	let out = "";

	for (const component of components) {
		const comp = component.trim();

		if (out && out[out.length - 1] !== "/" && comp[0] !== "/")
			out += "/";

		out += comp;
	}

	return out;
};

const resolveRequestConfig = (info: RequestInfo): RequestConfig => {
	if (typeof info == "string") {
		return {
			url: info
		};
	}

	return { ...info };
};

const request = async (info: RequestInfo): Promise<Response> => {
	let response,
		json;

	try {
		const conf = resolveRequestConfig(info);

		if (MOCKED_REQUESTS.hasOwnProperty(conf.url)) {
			return new Promise(resolve => {
				setTimeout(() => {
					const payload = (MOCKED_REQUESTS as any)[conf.url](conf);

					resolve({
						data: payload.data,
						success: true,
						errorMessage: payload.message,
						isResponse: true
					});
				}, 1000);
			});
		}

		const req = resolveRequest(info);
		response = await fetch(req).then((resp) => {
			if (resp.status === 401 || resp.status === 403 || resp.status === 0) {
				window.location.href = `${window.location.protocol}//${window.location.host}/api/v1/auth/login`;
			}
			return resp;
		});
	} catch {
		return {
			data: null,
			success: false,
			errorMessage: "Network Error",
			isResponse: true
		};
	}

	try {
		json = await response.json();
	} catch {
		return {
			data: null,
			success: false,
			errorMessage: `Error ${response.status}`,
			isResponse: true
		};
	}

	if (!json) {
		return {
			data: null,
			success: false,
			errorMessage: "Unknown Error",
			isResponse: true
		};
	}

	if (json.success) {
		return {
			data: json.data,
			success: true,
			errorMessage: null,
			isResponse: true
		};
	}

	return {
		data: null,
		success: false,
		errorMessage: json.message,
		isResponse: true
	};
};

const getBoxFrame = (args?: BoxFrameArgs): BoxFrame => {
	let a = args;

	if (!a)
		a = [0];
	else if (typeof a == "number")
		a = [a];

	if (a.length === 3)
		a.push(a[1]);

	const out = {} as BoxFrame;

	for (let i = 0, l = BOX_FRAME_ORDER.length; i < l; i++)
		out[BOX_FRAME_ORDER[i]] = a[i % a.length];

	return out;
};

interface Descriptors {
	[key: string]: PropertyDescriptor;
}

// Performs an assigment, but preserves descriptors
function assign<Result>(
	target: Partial<Result>,
	...sources: Partial<Result>[]
): Result {
	const descriptors = {} as Descriptors;
	let hasDescriptors = false;

	for (const source of sources) {
		const names = Object.getOwnPropertyNames(source);

		for (const name of names) {
			const descriptor = Object.getOwnPropertyDescriptor(source, name)!;

			if (
				!descriptors.hasOwnProperty(name) &&
				descriptor.writable &&
				descriptor.enumerable &&
				descriptor.configurable
			)
				(target as any)[name] = (source as any)[name];
			else {
				descriptors[name] = descriptor;
				hasDescriptors = true;
			}
		}
	}

	if (hasDescriptors)
		Object.defineProperties(target, descriptors);

	return target as Result;
}

const hasAncestor = (node: Node, ancestor: Node): boolean => {
	let n = node as Node | null;

	while (n && n !== document.body) {
		if (n === ancestor)
			return true;

		n = n.parentNode;
	}

	return false;
};

const format = {
	number: (value: number, precision: number = 2): string => {
		const abs = Math.abs(value),
			p = Math.pow(10, precision);
		let order = 1,
			postfix = "";

		for (const pf of POSTFIXES) {
			if (abs >= pf.order) {
				order = pf.order;
				postfix = pf.postfix;
				break;
			}
		}

		const rounded = Math.round((value / order) * p) / p;
		return rounded + postfix;
	},
	dollar: (value: number, precision: number = 2): string => {
		const formatted = format.number(value, precision);

		if (formatted[0] === "-")
			return `-$${formatted.substring(1)}`;

		return "$" + formatted
	},
	wholeDollar: (value: number): string => {
		const negative = value < 0;
		const rounded = Math.round(Math.abs(value)).toLocaleString();
		return `${negative ? "-" : ""}$${rounded}`;
	},
	percentage: (value: number, precision: number = 2): string => {
		return `${format.number(value * 100, precision)}%`;
	},
	wholeNumber: (value: number): string => {
		return Math.round(value).toLocaleString()
	},
	timeDuration: (totalSeconds: number): string => {
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
		let str = "";
		if (hours > 0) {
			str += `${hours} hour${hours > 1 ? "s" : ""}`;
			if (minutes > 0) {
				str += ", ";
			}
		}
		if (minutes > 0) {
			str += `${minutes} minute${minutes > 1 ? "s" : ""}`;
		}
		return str;
	}
};

const resolveOfficeKey = (name: string): string => {
	return casing(name.replace(/\W/g, "")).to.kebab;
};

export {
	cleanRegex,
	getUTCDate,
	getRoundDate,
	getDateString,
	mkLoadingState,
	resolveRequest,
	resolveRequestConfig,
	request,
	getBoxFrame,
	assign,
	hasAncestor,
	format,
	resolveOfficeKey
};
